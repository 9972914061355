<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import { Input } from '$lib/components/ui/input';
  import * as Pagination from '$lib/components/ui/pagination';
  import { ChevronLeft, ChevronRight } from 'lucide-svelte';
  import { mediaQuery } from 'svelte-legos';

  const dispatch = createEventDispatcher();
  const isDesktop = mediaQuery('(min-width: 768px)');

  export let from;
  export let count;

  $: perPage = $isDesktop ? 10 : 10;
  $: totalPages = Math.ceil(count / perPage);
  $: siblingCount = $isDesktop ? 1 : 1;
  $: page = Math.ceil(from / perPage) + 1;

  const gotoPage = page => {
    dispatch('goto', page);
  };

  const onNext = () => {
    dispatch('next');
  };
  const onPrevious = () => {
    dispatch('previous');
  };
</script>

<div class="pt-2 shadow-inner text-primary">
  <Pagination.Root {page} {count} {perPage} {siblingCount} let:currentPage>
    <Pagination.Content>
      <!-- workaround, fehler in der Pagination.root wenn letzte Seite nicht voll -->
      {#key page}
        <Pagination.Item>
          <Pagination.PrevButton on:click={onPrevious}>
            <ChevronLeft class="w-4 h-4" />
          </Pagination.PrevButton>
        </Pagination.Item>
      {/key}
      <div class="text-xs">Page</div>
      <Input
        value={page}
        class="w-12 mx-1"
        min={1}
        max={totalPages}
        on:change={e => gotoPage(e.target.value)}
      />
      <div class="text-xs text-nowrap">of {totalPages}</div>
      {#key page}
        <Pagination.Item>
          <Pagination.NextButton on:click={onNext}>
            <ChevronRight class="w-4 h-4" />
          </Pagination.NextButton>
        </Pagination.Item>
      {/key}
    </Pagination.Content>
  </Pagination.Root>
</div>
