<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';
  import { Building, Globe, Phone } from 'lucide-svelte';
  import Address from '$lib/components/hanta/address.svelte';

  import {
    type ActivityWithRefs,
    type AccountWithRefs,
  } from '../../../../drizzle/schema';
  import ActivityActionBar from '../activities/activity-action-bar.svelte';
  import LinkedinButton from '$lib/components/hanta/linkedin-button.svelte';
  import { appStore } from '$lib/app-store';

  export let crmAccount: AccountWithRefs;
  export let activity: ActivityWithRefs;
</script>

<div
  class="relative flex flex-col items-start w-full p-4 space-y-2 md:space-x-8 md:flex-row"
>
  {#if $appStore.isMobile}
    <div
      class="absolute left-0 z-[-1] w-full h-[calc(100%-65px)] bg-blue-300/10 dark:bg-slate-800 top-20 rounded-t-3xl"
    ></div>
  {/if}
  <Avatar.Root class="rounded-none size-48">
    <Avatar.Image
      src={crmAccount?.logo}
      alt="Company Logo"
      class="object-scale-down rounded-none"
    />

    <Avatar.Fallback
      class="w-full p-1 text-[8px] text-center text-gray-400 rounded-none bg-gradient-to-r from-gray-300 to-gray-100 "
    >
      {crmAccount?.name ?? ''}
    </Avatar.Fallback>
  </Avatar.Root>
  <div class="w-full">
    <div class="flex items-center justify-between space-x-4">
      <div class="flex items-center justify-between flex-1">
        <div class="flex items-center text-xl font-bold">
          {crmAccount?.name ?? ''}
          {#if crmAccount.linkedin}
            <div class="block">
              <LinkedinButton linkedin={crmAccount.linkedin} />
            </div>
          {/if}
        </div>
      </div>
    </div>
    <div class="mt-2 space-y-2 text-xs text-muted-foreground">
      {#if crmAccount?.address}
        <div class="flex space-x-2">
          <Address address={crmAccount?.address} />
        </div>
      {/if}

      {#if crmAccount?.websiteUrl}
        <div class="flex space-x-2">
          <Globe size="16" />
          <div>
            <a
              class="underline text-primary/80 hover:text-primary/80 visited:text-primary/80"
              href={crmAccount?.websiteUrl}
              target="_blank"
            >
              {crmAccount?.websiteUrl}
            </a>
          </div>
        </div>
      {/if}

      {#if crmAccount?.phone}
        <div class="flex space-x-2">
          <Phone size="16" />
          <div>
            {crmAccount?.phone}
          </div>
        </div>
      {/if}

      {#if crmAccount?.employeeCountRange || crmAccount?.foundedOn}
        <div class="flex space-x-2">
          <Building size="16" />
          {#if crmAccount?.employeeCountRange}
            <div>
              {crmAccount?.employeeCountRange} Employees
            </div>
          {/if}
          {#if crmAccount?.foundedOn}
            <div
              class={crmAccount?.employeeCountRange
                ? 'border-l border-primary-500 border-solid pl-2'
                : ''}
            >
              Founded in {crmAccount?.foundedOn}
            </div>
          {/if}
        </div>
      {/if}
    </div>
    <div class="my-4">
      <ActivityActionBar {activity} />
    </div>
  </div>
</div>
