<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import { Input, type Variant } from '$lib/components/ui/input/index';
  import { cn } from '$lib/utils';
  import { SquareArrowOutUpRight } from 'lucide-svelte';
  import { stringProxy } from 'sveltekit-superforms';

  export let form;
  export let name;
  export let variant: Variant = 'hanta';
  export let label = undefined;
  export let placeholder = undefined;
  export let description = '';
  export let readOnly = false;
  export let isLink = false;

  type $$Props = {
    form: any;
    name: string;
    label?: string;
    description?: string;
    placeholder?: string;
    class?: string;
    variant?: Variant;
    readOnly?: boolean;
    isLink?: boolean;
  };

  let className: $$Props['class'] = undefined;
  export { className as class };

  const { form: formData } = form;

  $: proxy = stringProxy(formData, name, { empty: 'undefined' });
</script>

<Form.Field class={cn('space-y-1', className)} {form} {name}>
  <Form.Control let:attrs>
    {#if label}<Form.Label>
        {label}
        {#if isLink && $proxy}
          <a href={$proxy} class="ml-2" target="_blank">
            <SquareArrowOutUpRight class="inline size-4" />
          </a>
        {/if}
      </Form.Label>{/if}
    {#if readOnly}
      <div
        class="inline-flex items-center w-full p-0 text-sm font-medium transition-colors bg-transparent border-none rounded-md outline-none items-left whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-7 ring-0 hover:bg-muted hover:outline-none hover:ring-0 active:ring-0 active:border-none focus:ring-0 hover:border-b focus:bg-secondary"
      >
        {$proxy}
      </div>
    {:else}
      <Input
        {...attrs}
        bind:value={$proxy}
        class="w-full"
        {variant}
        {placeholder}
      />
    {/if}
  </Form.Control>
  <Form.Description>{description ?? ''}</Form.Description>
  <Form.FieldErrors />
</Form.Field>
