<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';
  import * as Card from '$lib/components/ui/card';

  import DealPhase from '$lib/components/hanta/deals/deal-phase.svelte';

  import { findAssignments, getLov } from '$lib/api/queries';
  import { onMount } from 'svelte';
  import { Badge } from '$lib/components/ui/badge';
  import { Button } from '$lib/components/ui/button';
  import { appStore } from '$lib/app-store';

  export let contact;

  let phases = [];
  let deals = [];
  let dealsCount = 0;

  onMount(async () => {
    const phasesResult = await getLov('Phases');
    phases = phasesResult?.values?.map(el => el.name);

    const { count, data } = await findAssignments({
      id: contact?.id,
      module: 'contacts',
      page: 1,
      itemsPerPage: 10,
      search: '',
    });
    deals = data;
    dealsCount = count;
  });

  function openItem(module, id) {
    appStore.select({
      module: module,
      id: id,
    });
    appStore.openPopup(true);
  }
</script>

<Card.Root>
  <Card.Header>
    <Card.Title>Deals <Badge>{dealsCount}</Badge></Card.Title>
    <Card.Description>Last Deals</Card.Description>
  </Card.Header>
  <Card.Content class="gird-cols-1 md:grid-cols-2 gap-0.5 tn-grid">
    {#each deals ?? [] as assignment, i (assignment.id)}
      <div class="flex flex-col items-stretch p-4 border bg-primary-50/10">
        <div class="flex items-center space-x-4">
          <Avatar.Root class="rounded-none ">
            <Avatar.Image
              src={assignment.accountObj?.logo}
              class="object-scale-down size-12"
            />
            <Avatar.Fallback
              >{assignment.accountObj?.name ?? ''}</Avatar.Fallback
            >
          </Avatar.Root>

          <div>
            <Button
              variant="link"
              class="block"
              on:click={() => openItem('accounts', assignment.accountObj?.id)}
            >
              <h2 class="text-base">{assignment?.accountObj?.name}</h2>
            </Button>
            <Button
              variant="link"
              on:click={() => openItem('deals', assignment?.dealObj?.id)}
            >
              <h3 class="text-base text-muted-foreground">
                {assignment.dealObj?.name}
              </h3>
            </Button>
          </div>
        </div>

        <div class="mt-2">
          <DealPhase {phases} currentPhase={assignment.status} {assignment} />
        </div>
      </div>
    {/each}
  </Card.Content>
</Card.Root>
