<script lang="ts">
  import { ContactRoleType } from '../../../../drizzle/schema';

  import HantaSelect from '$lib/components/hanta/select.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { ChevronDown, CircleUser, User, UserSearch } from 'lucide-svelte';
  import { updateContactRole } from '$lib/api/mutations';

  export let crmContact;
  const contactRoles = [
    {
      label: 'Contact',
      value: ContactRoleType.enum.Contact,
      description: 'A person or organization your company interacts with.',
    },
    {
      label: 'Candidate',
      value: ContactRoleType.enum.Candidate,
      description: 'Potential employee for a specific deal.',
    },
    {
      label: 'Contact & Candidate',
      value: ContactRoleType.enum.ContactAndCandidate,
      description:
        'A person acting as a customer contact and potential employee.',
    },
    {
      label: 'Employee',
      value: ContactRoleType.enum.Employee,
      description: 'A person working for your company using this system.',
    },
  ];
</script>

<HantaSelect
  class="text-xs"
  onSelect={value => {
    updateContactRole(value, crmContact.id);
  }}
  options={contactRoles}
  searchable={false}
  value={crmContact.role}
>
  <svelte:fragment let:label let:value slot="trigger">
    <Badge class="text-nowrap py-0.5" variant="outline">
      {#if value === ContactRoleType.enum.Contact}
        <User class="w-4 h-4 mr-1" />
      {:else if value === ContactRoleType.enum.Candidate}
        <UserSearch class="w-4 h-4 mr-1" />
      {:else if value === ContactRoleType.enum.ContactAndCandidate}
        <UserSearch class="w-4 h-4 mr-1" />
      {:else if value === ContactRoleType.enum.Employee}
        <CircleUser class="w-4 h-4 mr-1" />
      {/if}
      {label}
      <ChevronDown class="w-4 h-4 ml-2 opacity-50 shrink-0" />
    </Badge>
  </svelte:fragment>
</HantaSelect>
