import {
  pgTable,
  pgEnum,
  timestamp,
  varchar,
  numeric,
  jsonb,
  date,
  boolean,
  integer,
  uuid,
  primaryKey,
  text,
  time,
  customType,
} from 'drizzle-orm/pg-core';
import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import { z } from 'zod';
import { sql } from 'drizzle-orm';

export const equalityOp = pgEnum('equality_op', <const>[
  'eq',
  'neq',
  'lt',
  'lte',
  'gt',
  'gte',
  'in',
]);
export const action = pgEnum('action', <const>[
  'INSERT',
  'UPDATE',
  'DELETE',
  'TRUNCATE',
  'ERROR',
]);

export const dealPriorityEnum = pgEnum('prioritydeal', <const>['A', 'B', 'C']);
export const dealPhaseEnum = pgEnum('dealPhase', <const>[
  'In Vorbereitung',
  'Kontaktiert',
  'Telefontermin',
  'Im Austausch',
  'Persönlicher Termin',
  'VideoCall',
  'Präsentation in Vorbereitung',
  'Präsentiert',
  'Telefoninterview Kunde',
  'Vorstellungstermin 1 Kunde',
  'Vorstellungstermin 2 Kunde',
  'Vertragsverhandlung',
  'Vertrag',
  'Absage',
]);

export const accountStatusEnum = pgEnum('accountstatus', <const>[
  'Uninteressant',
  'Akquisition',
  'Bestandskunde',
  'Neukunde',
  'Nicht interessiert',
]);

export const moduleNamesEnum = pgEnum('moduleNames', <const>[
  'accounts',
  'deals',
  'activities',
  'files',
  'contacts',
]);
export const filterTypesEnum = pgEnum('filterTypes', <const>[
  'private',
  'public',
  'shared',
  'system',
]);

export const activityType = pgEnum('activityType', <const>[
  'Note',
  'Todo',
  'Email',
  'LinkedIn',
  'Phone',
  'Assignment',
  'Letter',
  'Meeting',
]);

export const contactRolesEnum = pgEnum('contactRoles', <const>[
  'Contact',
  'Candidate',
  'ContactAndCandidate',
  'Employee',
]);

export const priorityEnum = pgEnum('priorityEnums', <const>[
  'Low',
  'Medium',
  'High',
]);

export const paymentCollectionEnum = pgEnum('paymentCollection', <const>[
  'Request payment',
  'Autocharge customer',
]);

export const ContactRoleType = z.enum(contactRolesEnum.enumValues);

export const tenantColumns = {
  createdAt: timestamp('createdAt', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  modifiedAt: timestamp('modifiedAt', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  refId: text('refId'),
  refIds: varchar('refIds').array(),
  orgId: varchar('orgId').default(sql`requesting_tenant_id()`),
  userId: varchar('userId').default(sql`requesting_user_id()`),
};

const differenceBetweenTimes = customType<{ data: number }>({
  dataType: () => {
    return "time generated always as (TIME '00:00:00' + ((endtime - starttime) - pause * INTERVAL ' 1 MINUTE')) stored";
  },
});

export const attachments = pgTable('attachments', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  key: varchar('key'),
  name: varchar('name'),
  content: varchar('content'),
  mimetype: varchar('mimetype'),
  size: numeric('size'),
});

export const timelogs = pgTable('timelogs', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  user: uuid('user').references(() => users.id),
  date: date('date').notNull(),
  starttime: time('starttime').notNull(),
  endtime: time('endtime').notNull(),
  pause: numeric('pause').notNull(),
  duration: differenceBetweenTimes('duration'),
  project: varchar('project'),
  description: varchar('description'),
  account: uuid('account').references(() => accounts.id),
  deal: uuid('deal').references(() => deals.id),
  billable: boolean('billable').default(true),
  task: varchar('task'),
});

export const search = pgTable('search', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name').notNull(),
  module: varchar('module').notNull(),
  suggest: varchar('suggest').notNull(),
});

export const sequences = pgTable('sequences', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name').notNull(),
  shortName: varchar('shortName'),
  value: numeric('value').notNull(),
  expression: varchar('expression'),
  initValue: numeric('initValue'),
  lastResetDate: timestamp('lastResetDate', {
    withTimezone: true,
    mode: 'string',
  }),
  nextResetDate: timestamp('nextResetDate', {
    withTimezone: true,
    mode: 'string',
  }),
  resetInterval: varchar('resetInterval'),
  description: varchar('description'),
});

export const lovs = pgTable('lovs', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name').notNull(),
  description: varchar('description'),
  values: jsonb('values'),
});

export const accounts = pgTable('accounts', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name'),
  description: varchar('description'),
  logo: varchar('logo'),
  linkedin: varchar('linkedin'),
  url: varchar('url'),
  phone: varchar('phone'),
  websiteUrl: varchar('websiteUrl'),
  accountStatus: varchar('accountStatus'),
  industry: varchar('industry'),
  employees: numeric('employees'),
  revenue: numeric('revenue'),
  address: jsonb('address'),
  communication: jsonb('communication'),
  companyProfile: varchar('companyProfile'),
  philosophy: varchar('philosophy'),
  universalName: varchar('universalName'),
  employeeCount: numeric('employeeCount'),
  employeeCountRange: varchar('employeeCountRange'),
  officialName: varchar('officialName'),
  tagline: varchar('tagline'),
  foundedOn: integer('foundedOn'),
  specialities: varchar('specialities').array(),
  crunchbaseFundingData: jsonb('crunchbaseFundingData'),
  company: jsonb('company'),
});

export const deals = pgTable('deals', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name').notNull(),
  position: varchar('position'),
  start: date('start'),
  end: date('end'),
  salaryNotes: varchar('salaryNotes'),
  customer: uuid('customer').references(() => accounts.id),
  lastActivity: timestamp('lastActivity', {
    // letzte Aktivität
    withTimezone: true,
    mode: 'string',
  }),
  role: varchar('role'), // stellung
  dealOwner: uuid('consultant').references(() => users.id),
  form: varchar('form'), // form
  alternativeForm: varchar('alternativeForm'), // alternative Form
  state: varchar('state'), // status
  dealPhase: varchar('dealPhase'), // mandatsstatus
  annualGrossSalary: numeric('annualGrossSalary'), // jahresbruttogehalt
  dailyRate: numeric('dailyEate'), // tagessatz
  hourlyRate: numeric('hourlyRate'), // stundesatz
  tasks: varchar('tasks'), // aufgaben
  requirements: varchar('requirements'), // anforderungen
  languageSkills: varchar('languageSkills').array(), // sprachkenntnisse
  industryKnowledge: varchar('industryKnowledge').array(), // branchenkenntnisse
  skills: varchar('skills').array(), // fähigkeiten
  comment: varchar('comment'), // kommentar
  fee: numeric('fee'), // honorar
  totalFee: numeric('totalFee'), // gesamthonorar
  feeInstallments: numeric('feeInstallments'), // honorarraten
  url: varchar('url'),
});

export const dealsToContacts = pgTable(
  'dealsToContacts',
  {
    ...tenantColumns,
    dealId: uuid('dealId')
      .notNull()
      .references(() => deals.id, {
        onDelete: 'cascade',
      }),
    contactId: uuid('contactId')
      .notNull()
      .references(() => contacts.id, {
        onDelete: 'cascade',
      }),
  },
  table => {
    return {
      pk: primaryKey({ columns: [table.dealId, table.contactId] }),
    };
  },
);

export const users = pgTable('users', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  externalId: varchar('externalId'),
  name: varchar('name'),
  photo: varchar('photo'),
  email: varchar('email').notNull(),
  username: varchar('username').notNull(),
  roles: varchar('roles').array(),
});

export const activities = pgTable('activities', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name'),
  type: activityType('type').default('Email'),
  description: varchar('description'),
  // You can use { mode: "bigint" } if numbers are exceeding js number limitations
  consultant: uuid('consultant').references(() => users.id),
  startDate: timestamp('startDate', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  dueDate: timestamp('dueDate', { withTimezone: true, mode: 'string' }),
  messageId: varchar('messageId'),
  date: date('date'),
  contactedVia: varchar('contactedVia'),
  status: varchar('status'),
  phase: varchar('phase'),
  flagged: boolean('flagged').default(false),
  priority: priorityEnum('priorityEnums'),
  done: boolean('done').default(false),

  candidate: uuid('candidate').references(() => contacts.id),
  account: uuid('account').references(() => accounts.id),
  deal: uuid('deal').references(() => deals.id),

  success: boolean('success'),
  rejectedBy: varchar('rejectedBy'),
  rejectedReason: varchar('rejectedReason'),
  history: jsonb('history'),

  accounts: jsonb('accounts'),
  candidates: jsonb('candidates'),
  contacts: jsonb('contacts'),
  deals: jsonb('deals'),

  incommingMessage: boolean('incommingMessage'),
  hasAttachment: boolean('hasAttachment'),
  attachments: jsonb('attachments'),

  refs: uuid('refs').array(),
});

export const files = pgTable('files', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  filename: varchar('filename'),
  mimetype: varchar('mimetype'),
  size: numeric('size'),
  fileRefId: varchar('fileRefId'),
});

export const contacts = pgTable('contacts', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  role: contactRolesEnum('role')
    .notNull()
    .default(ContactRoleType.enum.Contact),
  name: varchar('name').notNull(),
  lastname: varchar('lastname'), // nachname
  firstname: varchar('firstname'), // vorname
  customer: uuid('customer').references(() => accounts.id),
  linkedin: varchar('linkedin'), // linkedin
  consent: boolean('consent'), // einwilligung
  email: varchar('email'),
  phone: varchar('phone'),
  photo: varchar('photo'), // foto
  linkedInPhoto: varchar('linkedInPhoto'), // foto
  salutation: varchar('salutation'), // anrede
  title: varchar('title'), // titel
  isCandidate: boolean('isCandidate'), // istKandidat
  cluster: varchar('cluster').array(), // cluster
  blockingNotice: varchar('blockingNotice'), // sperrvermerk
  status: varchar('status').array(), // status
  birthday: timestamp('birthday', { withTimezone: true, mode: 'string' }), // geburtstag
  nationality: varchar('nationality'), // staatsangehörigkeit
  personalImpression: varchar('personalImpression'), // persönlicherEindruck
  professionalImpression: varchar('professionalImpression'), // fachlicherEindruck
  department: varchar('department'), // abteilung
  position: varchar('position'), // position
  candidateCompany: varchar('candidateCompany'), // kandidatenFirma
  communicationPrivate: jsonb('communicationPrivate'), // kommunikationPrivat
  communicationWork: jsonb('communicationWork'), // kommunikationArbeit
  urlPrivate: varchar('urlPrivate'), // urlPrivat
  url: varchar('url'), // url
  addressPrivate: jsonb('addressPrivate'), // adressePrivat
  addressWork: jsonb('addressWork'), // adresseArbeit
  skills: varchar('skills').array(), // fähigkeiten
  focus: varchar('focus'), // schwerpunkt
  impression: varchar('impression'), // eindruck
  availableFrom: date('availableFrom'), // verfügbarAb
  targetRegion: varchar('targetRegion').array(), // zielregion
  annualGrossTarget: numeric('annualGrossTarget'), // jahresbruttoziel
  hourlyRate: numeric('hourlyRate'), // stundensatz
  dailyRate: numeric('dailyRate'), // tagessatz
  industry: varchar('industry').array(), // branche
  salaryNotes: varchar('salaryNotes'), // notizenZumGehalt
  jobTitle: varchar('jobTitle'), // jobbezeichnung
  positionInCompany: varchar('positionInCompany'), // stellungImUnternehmen
  languageSkills: varchar('languageSkills').array(), // sprachkenntnisse
  industryKnowledge: varchar('industryKnowledge').array(), // branchenkenntnisse
  description: varchar('description'), // beschreibung
  employment: jsonb('employment'), // beschäftigung
  projects: jsonb('projects'), // projekte
  education: jsonb('education'), // bildung
  other: jsonb('other'), // sonstiges
  furtherEducation: jsonb('furtherEducation'), // weiterbildung
  vocationalTraining: jsonb('vocationalTraining'), // berufsausbildung
  schoolEducation: jsonb('schoolEducation'), // schulbildung
  itQualifications: jsonb('itQualifications'), // itQualifikationen
  militaryCivilService: jsonb('militaryCivilService'), // wehrZivildienst
  summary: varchar('summary'), // zusammenfassung
  consultant: uuid('consultant').references(() => users.id),
  internalNotes: varchar('internalNotes'), // interneNotizen
  resume: varchar('resume'), // lebenslauf
  resumeLinkedIn: jsonb('resumeLinkedIn'), // lebenslauf
  resumeDate: timestamp('resumeDate', { withTimezone: true, mode: 'string' }), // lebenslaufDatum
  references: varchar('references'), // referenzen
  noticePeriod: varchar('noticePeriod'), // kündigungsfrist
  contact: jsonb('contact'),
  contactInfos: jsonb('contactInfos'),
});

export const invoices = pgTable('invoices', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  // invoiceId: text('invoiceId').default(sql`get_seq_id('invoices')`),
  invoiceId: text('invoiceId').default('DRAFT'),

  currency: varchar('currency').default('EUR - Euro'),
  billingMethod: varchar('billingMethod').default(''),
  memo: varchar('memo'),
  language: varchar('language').default('de'),
  paymentCollection: paymentCollectionEnum('paymentCollection'),
  dueInDays: integer('dueInDays').default(30),
  customFields: jsonb('customFields'),
  taxId: varchar('taxId'),
  taxIdName: varchar('taxIdName'),
  template: varchar('template'),

  invoiceDate: timestamp('invoiceDate', { withTimezone: true, mode: 'string' }),
  status: varchar('status'),
  subject: varchar('subject'),
  delivery: varchar('delivery'),
  customer: uuid('customer')
    .notNull()
    .references(() => accounts.id),
  recipient: uuid('recipient').references(() => contacts.id),
  net: numeric('net'),
  vatInPercent: numeric('vatInPercent'),
  vat: numeric('vat'),
  paymentDeadline: timestamp('paymentDeadline', {
    withTimezone: true,
    mode: 'string',
  }),
  gross: numeric('gross'),
  debit: numeric('debit'),
  positions: jsonb('positions'),
  name: varchar('name'),
  introduction: varchar('introduction'),
  conclusion: varchar('conclusion'),
  withQrCode: boolean('withQrCode'),
  withVat: boolean('withVat'),
  invoiceCancellation: varchar('invoiceCancellation'),
  fixedOn: timestamp('fixedOn', { withTimezone: true, mode: 'string' }),
  payments: jsonb('payments'),
  tags: varchar('tags').array(),
});

export const pipelines = pgTable('pipelines', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name').notNull(),
  modifySource: boolean('modifySource').notNull().default(false),
  sourceColumn: varchar('sourceColumn'),
  module: moduleNamesEnum('moduleName').notNull(),
});

export const filters = pgTable('filters', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name').notNull(),
  module: varchar('module').notNull(),
  filter: jsonb('filter').notNull(),
  type: filterTypesEnum('type').notNull(),
  search: varchar('search'),
  fulltextsearch: boolean('fulltextsearch'),
  view: varchar('view'),
  columns: jsonb('columns'),
  dealId: uuid('dealId')
    .references(() => deals.id)
    .unique(),
});

export const pipelinePhases = pgTable('pipelinePhases', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  name: varchar('name').notNull(),
  phaseIndex: integer('phaseIndex').notNull(),
  pipelineId: uuid('pipelineId')
    .notNull()
    .references(() => pipelines.id),
  endPhase: boolean('endPhase').notNull().default(false),
  isPositive: boolean('isPositive'),
});

export const pipelineItems = pgTable('pipelineItems', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  phaseId: uuid('phaseId')
    .notNull()
    .references(() => pipelinePhases.id),
  accountId: uuid('accountId').references(() => accounts.id),
  contactId: uuid('contactId').references(() => contacts.id),
  dealId: uuid('dealId').references(() => deals.id),
  activityId: uuid('activityId').references(() => activities.id),
  filedId: uuid('fileId').references(() => files.id),
  data: jsonb('data'),
});

export const pipelineItemsHistory = pgTable('pipelineItemsHistory', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  ...tenantColumns,
  pipelineItemId: uuid('pipelineItemId')
    .notNull()
    .references(() => pipelineItems.id),
  fromPhaseId: uuid('fromPhaseId')
    .notNull()
    .references(() => pipelinePhases.id),
  toPhaseId: uuid('toPhaseId')
    .notNull()
    .references(() => pipelinePhases.id),
  data: jsonb('data'),
});

export const insertDealsSchema = createInsertSchema(deals, {
  feeInstallments: z.number().nullable().default(null),
  annualGrossSalary: z.number().nullable().default(null),
  totalFee: z.number().nullable().default(null),
  start: z.string().nullable().default(null),
  end: z.string().nullable().default(null),
  fee: z.number().nullable().default(null),
}).extend({
  customerObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
});

export type Deal = z.infer<typeof insertDealsSchema>;
const communication = z.object({
  email: z.string().nullable().default(null),
  phone: z.string().nullable().default(null),
  mobile: z.string().nullable().default(null),
  fax: z.string().nullable().default(null),
  linkedin: z.string().nullable().default(null),
  whatsapp: z.string().nullable().default(null),
  telegram: z.string().nullable().default(null),
  xing: z.string().nullable().default(null),
  twitter: z.string().nullable().default(null),
  facebook: z.string().nullable().default(null),
  skype: z.string().nullable().default(null),
  other: z.string().nullable().default(null),
});

const address = z.object({
  street: z.string().nullable().default(null),
  city: z.string().nullable().default(null),
  province: z.string().nullable().default(null),
  country: z.string().nullable().default(null),
  postalCode: z.string().nullable().default(null),
});

// Schema for inserting contactWithRefsSchema account - can be used to validate API requests
export const insertAccountsSchema = createInsertSchema(accounts, {
  revenue: z.number().nullable().default(null),
  employeeCount: z.number().nullable().default(null),
  employees: z.number().nullable().default(null),
  address,
});

export const insertTimelogSchema = createInsertSchema(timelogs, {
  date: z.string().nullable().default(null),
  pause: z.number().nullable().default(null),
});

export const insertContactsSchema = createInsertSchema(contacts, {
  id: z.string().uuid(),
  customer: z.object({
    id: z.string().uuid(),
    name: z.string().nullable(),
    logo: z.string().nullable().default(null),
  }),
  annualGrossTarget: z.number().nullable().default(null),
  hourlyRate: z.number().nullable().default(null),
  dailyRate: z.number().nullable().default(null),
  birthday: z.string().nullable().default(null),
  addressPrivate: address,
  addressWork: address,
});

export const insertActivitiesSchema = createInsertSchema(activities, {
  consultant: z.string().uuid().optional(),
  startDate: z.string().nullable().default(null),
  dueDate: z.string().nullable().default(null),
  date: z.string().nullable().default(null),
  success: z.boolean().nullable().default(null),
});

export const insertFilesSchema = createInsertSchema(files);
export const insertInvoiceSchema = createInsertSchema(invoices, {
  gross: z.number().nullable().default(null),
  debit: z.number().nullable().default(null),
  vat: z.number().nullable().default(null),
  net: z.number().nullable().default(null),
  vatInPercent: z.number().nullable().default(null),
  delivery: z.enum(<const>['E-Mail', 'Letter']).default('E-Mail'),
  paymentDeadline: z.string().nullable().default(null),
  invoiceDate: z.string().nullable().default(null),
  fixedOn: z.string().nullable().default(null),
  withQrCode: z.boolean().nullable().default(null),
  withVat: z.boolean().nullable().default(null),
  invoiceId: z.string().nullable().default(null),
  subject: z.string().nullable().default(undefined),
  status: z
    .enum(<const>[
      'Draft',
      'Open',
      'Overdue',
      'Paid',
      'Cancelled',
      'Cancellation invoice',
    ])
    .default('Draft'),
  customer: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string(),
      address,
    })
    .required(),
  recipient: z.object({
    id: z.string().uuid(),
    name: z.string(),
  }),
  positions: z
    .array(
      z.object({
        id: z.string().min(5),
        text: z.string().nullable().default(''),
        unit: z.enum(<const>['Stunden', 'pauschal', 'Stück']),
        quantity: z.number(),
        price: z.number(),
        tax: z.number(),
        sum: z.number(),
      }),
    )
    .default([]),
  payments: z
    .array(
      z.object({
        id: z.string().uuid(),
        paymentReceipt: z.string(),
        total: z.number(),
        date: z.string(),
        gross: z.number(),
        debit: z.number(),
        differenceReason: z.string(), // Replace with actual schema
      }),
    )
    .nullable()
    .default([]),
});
export const insertLovsSchema = createInsertSchema(lovs, {
  id: z.string().uuid().nullable().default(undefined),
  createdAt: z.string().nullable().default(undefined),
  modifiedAt: z.string().nullable().default(undefined),
  name: z.string(),
  description: z.string().nullable(),
  values: z
    .array(
      z.object({
        icon: z.string().nullable().default(null),
        name: z.string(),
        color: z.string().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  orgId: z.string().nullable().default(undefined),
  userId: z.string().nullable().default(undefined),
});

export type Timelog = z.infer<typeof insertTimelogSchema>;
export type Account = z.infer<typeof insertAccountsSchema>;
export type Contact = z.infer<typeof insertContactsSchema>;
export type Activity = z.infer<typeof insertActivitiesSchema>;
export type File = z.infer<typeof insertFilesSchema>;
export type Invoice = z.infer<typeof insertInvoiceSchema>;
export type Lovs = z.infer<typeof insertLovsSchema>;

// Zod schema type is also inferred from the table schema, so you have full type safety
export const selectAccountsSchema = createSelectSchema(accounts);

export const contactWithRefsSchema = insertContactsSchema.extend({
  deals: z.array(insertDealsSchema).nullable().default([]),
  accounts: z.array(insertAccountsSchema).nullable().default([]),
});

export type ContactWithRefs = z.infer<typeof contactWithRefsSchema>;
export type AccountWithRefs = Account & {
  deals?: Deal[];
  contacts?: Contact[];
};

export const activityWithRefsSchema = insertActivitiesSchema.extend({
  deals: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        customer: z.string().uuid().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  accounts: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
      }),
    )
    .nullable()
    .default([]),
  contacts: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        role: z.string().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  candidates: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        role: z.string().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  allContacts: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        role: z.string().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  accountObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string().nullable(),
    })
    .nullable()
    .optional()
    .default(undefined),
  dealObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .optional()
    .default(undefined),
  candidateObj: z
    .object({
      id: z.string(),
      name: z.string(),
      photo: z.string().nullable(),
    })
    .optional()
    .nullable()
    .default(undefined),
  consultantObj: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
      photo: z.string().optional(),
    })
    .optional(),
});

export const activityWithRefsInsertSchema = activityWithRefsSchema.extend({});

export type ActivityWithRefs = z.infer<typeof activityWithRefsSchema>;

export const ActivityType = z.enum(activityType.enumValues);
export const PriorityType = z.enum(priorityEnum.enumValues);
