<script lang="ts">
  import { primaryRoutes, secondaryRoutes } from './config';
  import SearchWindow from './search-window.svelte';
  import NewItemWindow from './new-item-window.svelte';
  import Nav from './nav.svelte';

  import * as Sheet from '$lib/components/ui/sheet';

  import { Button } from '$lib/components/ui/button';
  import { Settings } from 'lucide-svelte';
  import AuthUserButton from '$lib/auth/auth-user-button.svelte';
  import AuthOrgButton from '$lib/auth/auth-org-button.svelte';
  import ThemeSwitcher from '$lib/ui/theme-switcher.svelte';
  import { appStore } from '$lib/app-store';
  import { HamburgerMenu } from 'radix-icons-svelte';

  let isCollapsed = true;
</script>

{#if $appStore.isMobile}
  <Sheet.Root>
    <Sheet.Trigger asChild let:builder>
      <div
        class="w-full fixed bottom-0 flex space-x-2 bg-secondary z-[999] justify-around items-center"
      >
        <Button builders={[builder]} variant="ghost">
          <HamburgerMenu class="size-5" />
        </Button>
        <SearchWindow />
        <NewItemWindow />

        <AuthOrgButton />
      </div>
    </Sheet.Trigger>
    <Sheet.Content side="bottom" class="bg-secondary z-[999]">
      <Nav isCollapsed={false} routes={primaryRoutes} />
      <div class="flex justify-between w-full pt-4 mt-4 border-t border-solid">
        <AuthUserButton />
        <ThemeSwitcher />
      </div>
    </Sheet.Content>
  </Sheet.Root>
{:else}
  <div
    class="flex flex-col justify-between crmapp bg-background w-[90%] md:w-[58px]"
  >
    <div>
      <SearchWindow />

      <NewItemWindow />

      <div class="mt-3">
        <Nav isCollapsed={true} routes={primaryRoutes} />
      </div>
      <!--
  <Separator />
  <Nav {isCollapsed} routes={secondaryRoutes} />
-->
    </div>
    <div class="flex flex-col items-center px-2 py-2 mb-12 space-y-2">
      {#if import.meta.env.MODE !== 'plugin'}
        <ThemeSwitcher />
      {/if}
      <Button
        variant="ghost"
        size="icon"
        class={'hidden size-9 my-0.5 dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'}
      >
        <Settings class="size-4" />
      </Button>

      <AuthOrgButton />
      <AuthUserButton />
    </div>
  </div>
{/if}

<style>
  .crmapp {
    position: fixed;
    left: 0px;
    padding: 1px;
    padding-left: 5px;
    padding-right: 1px;
    top: 52px;
    z-index: 10000;
    height: calc(100svh - 52px);
  }
</style>
