<script lang="ts">
  import { updateStatus } from '$lib/api/mutations';
  import { Badge } from '$lib/components/ui/badge';
  import KanbanHint from './kanban-hint.svelte';
  import KanbanProgress from './kanban-progress.svelte';
  import List from './list.svelte';
  export let phases;
  export let items;

  function handleOnChanges(phase, oldItems, newItems) {
    newItems
      .filter(a => a.status !== phase)
      .forEach(async item => {
        console.log('CHANGES: ', { old: item.status, new: phase }, { item });
        await updateStatus(phase, item.id);
      });
  }
</script>

<div class="flex mt-1">
  {#each items || [] as groupOfItems, i}
    <div class="mr-1 bg-gray-100 dark:bg-slate-800">
      <div class="self-end h-16 p-2 mb-1">
        <KanbanProgress {phases} current={i}></KanbanProgress>
        <Badge
          variant={groupOfItems.length ? 'default' : 'outline'}
          class="text-xs ">{groupOfItems.length}</Badge
        >
        <span class="text-xs font-light">{phases[i]}</span>
      </div>
      <List
        bind:items={groupOfItems}
        on:changes={event => {
          handleOnChanges(phases[i], groupOfItems, event.detail.items);
        }}
      />
    </div>
  {/each}
</div>
<KanbanHint />
