<script lang="ts">
  import HantaInputMultiSelectLean from '../input/hanta-input-multi-select-lean.svelte';

  import * as Avatar from '$lib/components/ui/avatar';
  import { getItems } from '$lib/api/queries';

  export let value = undefined;
  const loadOptionsContacts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 25,
      search: keyword,
      select: 'id,name,photo',
    });

    return result.data;
  };
</script>

<HantaInputMultiSelectLean
  class="w-full"
  bind:value
  label="Contact"
  loadOptions={loadOptionsContacts}
  multiple={false}
  placeholder="Select contact..."
>
  <div class="flex items-center w-full h-full" let:index let:item slot="item">
    <Avatar.Root class="size-7">
      <Avatar.Image class="object-scale-down rounded-none" src={item?.photo} />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 text-sm truncate">{item?.name}</div>
  </div>
  <div
    class="flex items-center w-full h-full overflow-hidden"
    let:selection
    slot="selection"
  >
    <Avatar.Root class="size-7">
      <Avatar.Image
        class="object-scale-down rounded-none"
        src={selection?.photo}
      />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="w-full ml-2 text-sm truncate">
      {selection?.name}
    </div>
  </div>
</HantaInputMultiSelectLean>
