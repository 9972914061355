<script lang="ts">
  import { appStore } from '$lib/app-store';
  import { ActivityType } from '../../../../drizzle/schema';
  import ActivityTodoPopup from './activity-todo-popup.svelte';
  import ActivityNotePopup from './activity-note-popup.svelte';
  import ActivityPhonePopup from './activity-phone-popup.svelte';
  import ActivityMailPopup from './activity-mail-popup.svelte';
  import ActivityAssigmentPopup from './activity-assigment-popup.svelte';
  import ActivityMessagePopup from './activity-message-popup.svelte';

  $: type =
    $appStore.openActivity &&
    $appStore.currentActivity &&
    $appStore.currentActivity.type;

  const ActivityEnums = ActivityType.enum;

  $: activity = $appStore.currentActivity;

  const activityComponents = {
    [ActivityEnums.Email]: ActivityMailPopup,
    [ActivityEnums.Note]: ActivityNotePopup,
    [ActivityEnums.Todo]: ActivityTodoPopup,
    [ActivityEnums.Phone]: ActivityPhonePopup,
    [ActivityEnums.Assignment]: ActivityAssigmentPopup,
    [ActivityEnums.LinkedIn]: ActivityMessagePopup,
  };
</script>

{#key type}
  <svelte:component this={activityComponents[type]} {activity} />
{/key}
