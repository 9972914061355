<script context="module" lang="ts">
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  import type { FormPath, SuperForm } from 'sveltekit-superforms';

  type T = Record<string, unknown>;
  type U = unknown;
</script>

<script
  generics="T extends Record<string, unknown>, U extends FormPath<T>"
  lang="ts"
>
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import * as Form from '$lib/components/ui/form/index.js';
  import * as FormPrimitive from 'formsnap';
  import { intProxy, stringProxy } from 'sveltekit-superforms';

  type $$Props = FormPrimitive.FieldProps<T, U> & {
    label?: string;
    options?: any[];
    asInt?: boolean;
    searchable?: boolean;
    readOnly?: boolean;
  };

  export let form: SuperForm<T>;
  export let name: U;
  export let label = '';
  export let description = '';
  export let options = [];
  export let asInt = false;
  export let searchable = false;
  export let readOnly = false;

  const { form: formData } = form;

  $: proxy = asInt
    ? intProxy(formData, name, {
        empty: 'zero',
        initiallyEmptyIfZero: true,
      })
    : stringProxy(formData, name, { empty: 'undefined' });
</script>

<Form.Field class="space-y-1" {form} {name}>
  <Form.Control let:attrs>
    {#if label}
      <Form.Label>{label}</Form.Label>
    {/if}
    <HantaSelect {readOnly} bind:value={$proxy} {options} {searchable} />
  </Form.Control>
  {#if description}
    <Form.Description>{description ?? ''}</Form.Description>
  {/if}
  <Form.FieldErrors />
</Form.Field>
