<script lang="ts">
  import { onMount } from 'svelte';
  import Quill from 'quill';
  import { Editor } from '@tadashi/svelte-editor-quill';
  import * as Form from '$lib/components/ui/form/index.js';
  import Textarea from '$lib/components/ui/textarea/textarea.svelte';
  import type { Props } from '$lib/components/ui/textarea/index';
  import { cn } from '$lib/utils';
  import { stringProxy } from 'sveltekit-superforms';

  export let form;
  export let name;
  export let label: string = '';
  export let description = '';
  export let placeholder: string = '';
  export let resizable: boolean = true;
  export let readOnly: boolean = false;
  export let variant: $$Props['variant'] = 'hanta';
  export let initialRows: number = 10;
  export let toolbarPosition: 'top' | 'bottom' = 'top';

  const { form: formData } = form;

  type $$Props = Props & {
    form: any;
    name: string;
    label?: string;
    description?: string;
    placeholder?: string;
    class?: string;
    resizable?: boolean;
    readOnly?: boolean;
    initialRows?: number;
    toolbarPosition?: 'top' | 'bottom';
  };

  let className: $$Props['class'] = undefined;
  export { className as class };

  $: proxy = stringProxy(formData, name, { empty: 'undefined' });
  let quillTarget;
  let quill;

  $: options = {
    modules: {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    },
    placeholder: placeholder,
    theme: 'snow',
  };

  onMount(() => {
    if ($proxy && $proxy.startsWith('<html')) {
      const start = $proxy.indexOf('<body>') + 6;
      const end = $proxy.indexOf('</body>');
      $proxy = $proxy.substring(start, end);
    }
  });

  const onTextChange = event => {
    console.log('event:', event);
    const { html } = event?.detail ?? {};
    $proxy = html;
  };
</script>

<Form.Field class={cn('space-y-1 ', className)} {form} {name}>
  <Form.Control let:attrs>
    {#if label}
      <Form.Label>{label}</Form.Label>
    {/if}
    {#if !readOnly}
      <div class="px-6 py-2">
        <Editor
          {options}
          data={$proxy}
          on:text-change={onTextChange}
          style="height: {initialRows * 1.5}em;"
          class={toolbarPosition === 'bottom'
            ? 'display-flex flex-column-reverse'
            : ''}
        />
      </div>
    {:else}
      <span class="w-full text-sm text-foreground">
        {@html $proxy?.replace(/\n/g, '<br/>')}
      </span>
    {/if}
  </Form.Control>
  {#if description}
    <Form.Description>{description ?? ''}</Form.Description>
  {/if}
  <Form.FieldErrors />
</Form.Field>

<style>
  :global(.ql-toolbar) {
    @apply rounded-lg;

    border: 1px solid #ccc !important;
    border-color: rgb(204, 204, 204) !important;
    border-color: rgb(204 204 204 / 40%) !important;
  }

  :global(.ql-editor),
  :global(.ql-container.ql-snow) {
    border: 1px solid transparent !important;
  }

  :global(.display-flex) {
    display: flex !important;
  }
  :global(.flex-column-reverse) {
    flex-direction: column-reverse !important;
  }
</style>
