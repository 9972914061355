<script lang="ts">
  import { slide } from 'svelte/transition';

  import HantaSelect from '$lib/components/hanta/select.svelte';
  import * as Form from '$lib/components/ui/form';
  import {
    type Account,
    accountStatusEnum,
    insertAccountsSchema,
  } from '../../../../drizzle/schema';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveAccount } from '$lib/api/mutations';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { superForm } from 'sveltekit-superforms';
  import { Input } from '$lib/components/ui/input';
  import { Button } from '$lib/components/ui/button';
  import HantaInputAddress from '../input/hanta-input-address.svelte';
  import HantaInputText from '../input/hanta-input-text.svelte';
  import HantaInputNumber from '../input/hanta-input-number.svelte';
  import HantaInputTextarea from '../input/hanta-input-textarea.svelte';
  import HantaInputCommunication from '../input/hanta-input-communication.svelte';

  export let crmAccount;
  export let onUpdateCrmAccount = newAccount => {};

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<Account> =>
      Promise.resolve(saveAccount(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: ['accounts'] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: ['accounts', crmAccount.id] });
      const previousItems = client.getQueryData<Account[]>([
        'accounts',
        crmAccount.id,
      ]);

      client.setQueryData(['accounts', crmAccount.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData(['accounts'], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: ['accounts', crmAccount.id] });
    },
  });

  const formSchema = insertAccountsSchema.partial();
  let initAccount = {
    ...crmAccount,
    address: crmAccount.address ?? {},
    communication: crmAccount.communication ?? {},
  };
  const form = superForm<Account>(initAccount, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(formSchema),
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.log('Form data', $formData);

      let parse = insertAccountsSchema.parse($formData);
      $addMutation.mutate(parse, {
        onError(error) {
          console.error('Error', error);
        },
        onSuccess: account => {
          onUpdateCrmAccount(account);
        },
      });
    },
  });

  export let hasChanges: boolean = false;

  let { form: formData, enhance, errors } = form;

  $: hasChanges = $formData && form.isTainted();
  $: formErrors = $errors;

  let val = accountStatusEnum.enumValues.map(v => v);
</script>

<form class="w-full" method="POST" use:enhance>
  <div>
    <h2
      class="sticky top-0 px-6 py-1 font-semibold border-b border-solid border-b-primary-200/20 bg-primary-50"
    >
      Company
    </h2>

    <div class="flex flex-col px-4 m-2 rounded">
      <HantaInputText {form} name="refId" label="Id" readOnly={true} />
      <HantaInputText {form} name="name" label="Name" />

      <Form.Field class="space-y-1" {form} name="accountStatus">
        <Form.Control>
          <Form.Label>Status</Form.Label>
          <HantaSelect
            bind:value={$formData.accountStatus}
            options={val.map(v => ({ label: v, value: v }))}
          />
        </Form.Control>
        <Form.FieldErrors />
      </Form.Field>

      <HantaInputNumber {form} name="foundedOn" label="Founded Date" />

      <Form.Field class="space-y-1" {form} name="tagline">
        <Form.Control let:attrs>
          <Form.Label>Tagline</Form.Label>
          <Input
            {...attrs}
            bind:value={$formData.tagline}
            class="w-full"
            variant="hanta"
          />
        </Form.Control>
        <Form.FieldErrors />
      </Form.Field>

      <HantaInputTextarea {form} name="philosophy" label="Philosophy" />

      <HantaInputText {form} name="websiteUrl" label="Website" />
      <HantaInputText {form} name="officialName" label="Official name" />
      <HantaInputNumber {form} name="revenue" label="Revenue" />
      <HantaInputNumber {form} name="employeeCount" label="Employees Count" />
      <HantaInputNumber {form} name="employees" label="Employees" />

      <Form.Field class="space-y-1" {form} name="employeeCountRange">
        <Form.Control let:attrs>
          <Form.Label>Employees Range</Form.Label>
          <Input
            {...attrs}
            bind:value={$formData.employeeCountRange}
            class="w-full"
            variant="hanta"
          />
        </Form.Control>
        <Form.FieldErrors />
      </Form.Field>
      <Form.Field class="space-y-1" {form} name="industry">
        <Form.Control let:attrs>
          <Form.Label>Industry</Form.Label>
          <Input
            {...attrs}
            bind:value={$formData.industry}
            class="w-full"
            variant="hanta"
          />
        </Form.Control>
        <Form.FieldErrors />
      </Form.Field>

      <HantaInputTextarea {form} name="description" label="Description" />
      <HantaInputText {form} name="linkedin" label="LinkedIn" />

      <HantaInputText {form} name="url" label="URL" />
    </div>
  </div>

  <div>
    <h2
      class="sticky top-0 px-6 py-1 font-semibold border-b border-solid border-b-primary-200/20 bg-primary-50"
    >
      Contact
    </h2>
    <div class="flex flex-col px-4 m-2 rounded">
      <HantaInputCommunication
        {form}
        name="communication"
        label="Contact"
        types={['phone', 'email']}
      />
    </div>
  </div>

  <!-- Address -->
  <div class="mb-20">
    <h2
      class="sticky top-0 px-6 py-1 font-semibold border-b border-solid border-b-primary-200/20 bg-primary-50"
    >
      Address
    </h2>
    <div class="flex flex-col px-4 m-2 rounded">
      <HantaInputAddress {form} name="address" label="Address" />
    </div>
  </div>
  {#if hasChanges || (formErrors && Object.keys(formErrors).length > 0)}
    <div
      class="sticky bottom-0 pb-0 mb-0 border-t border-solid shadow border-t-primary-200/30 bg-muted"
      out:slide={{ duration: 200 }}
      in:slide={{ duration: 200 }}
    >
      <div class="flex flex-col gap-2 px-4">
        {#if formErrors}
          {#each Object.keys(formErrors) as error}
            <div class="text-sm text-red-500">
              {error}: {formErrors[error]}
            </div>
          {/each}
        {/if}
      </div>

      <div class="flex items-center justify-start w-full h-20 gap-2 px-4">
        <Form.Button variant="default">Save</Form.Button>
        <Button variant="outline" on:click={() => form.reset()}>Cancel</Button>
      </div>
    </div>
  {/if}
  <!-- Address end -->
</form>
