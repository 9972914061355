<script lang="ts">
  import { supabase } from '$lib/api/supabase';
  import * as Avatar from '$lib/components/ui/avatar';
  import { Button } from '$lib/components/ui/button';
</script>

<Button
  variant="ghost"
  size="icon"
  on:click={() => {
    supabase('').auth.signOut();
  }}
>
  {#await supabase('').auth.getUser() then result}
    <Avatar.Root>
      <Avatar.Image
        src={result.data.user?.user_metadata?.picture}
        alt="User Avatar"
      />
      <Avatar.Fallback
        >{result.data.user?.user_metadata?.name
          ?.split(' ')
          .map(n => n[0])
          .join('')}
      </Avatar.Fallback>
    </Avatar.Root>
  {:catch error}
    <p style="color: red">{error.message}</p>
  {/await}
</Button>
