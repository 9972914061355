export const beforeCallbacks = [];

export const beforeNavigate = (/* callback */) => {
  // TODO
  console.log('beforeNavigate');
};

export const goto = () => {
  // TODO
  console.log('goto');
};

export const invalidateAll = () => {
  // TODO
  console.log('invalidateAll');
};
