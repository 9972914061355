<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import Activities from '$lib/pages/activities/activities.svelte';
  import ContactsWidget from '$lib/widgets/contacts/contacts-widget.svelte';
  import PageWithTitle from '../../ui/page-with-title.svelte';

  import {
    type ActivityWithRefs,
    type AccountWithRefs,
  } from '../../../../drizzle/schema';
  import DealsWidget from '$lib/widgets/deals-widget.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import AccountForm from '$lib/widgets/accounts/account-form.svelte';
  import AccountCardShort from './account-card-short.svelte';
  import AccountCard from './account-card.svelte';
  import { appStore } from '$lib/app-store';

  type $$Props = { crmAccount: AccountWithRefs; isPluginMode?: boolean };

  export let crmAccount: AccountWithRefs;
  export let isPluginMode = false;

  let activity: ActivityWithRefs;
  $: activity = {
    name: '',
    description: '',
    accounts: [crmAccount],
    deals: [],
    candidates: [],
    contacts: [],
  };
</script>

<PageWithTitle>
  <div slot="short-card" class="flex space-x-2">
    <AccountCardShort {crmAccount} {activity} />
  </div>

  <div slot="card" class="flex items-center space-x-8">
    <AccountCard {crmAccount} {activity} />
  </div>

  <div slot="content">
    <Tabs.Root
      value={$appStore.isMobile ? 'about' : 'overview'}
      class="flex flex-col mt-4"
    >
      <Tabs.List class="flex justify-start w-fit">
        {#if $appStore.isMobile}
          <Tabs.Trigger value="about">About</Tabs.Trigger>
        {/if}
        <Tabs.Trigger value="overview">Overview</Tabs.Trigger>
        <Tabs.Trigger value="timeline">Timeline</Tabs.Trigger>
      </Tabs.List>
      {#if $appStore.isMobile}
        <Tabs.Content value="about" class="p-2 text-left">
          <AccountForm bind:crmAccount />
        </Tabs.Content>
      {/if}

      <Tabs.Content value="overview" class="w-full h-full p-2 overflow-scroll">
        {#key `${crmAccount?.id}-${crmAccount?.modifiedAt}`}
          <DealsWidget
            deals={crmAccount?.deals || []}
            customerId={crmAccount?.id}
          />
          <ContactsWidget
            contacts={crmAccount?.contacts || []}
            customerId={crmAccount?.id}
          />
          <div
            class="p-4 px-4 py-5 my-8 overflow-hidden rounded-lg shadow bg-primary-800 sm:p-6"
          >
            <Files folder={`accounts/${crmAccount?.id}`} compact={true} />
          </div>
        {/key}
      </Tabs.Content>

      <Tabs.Content value="timeline" class="w-full h-full p-2 overflow-scroll">
        <Activities id={crmAccount?.id} module={'accounts'} type="all" />
      </Tabs.Content>
      <Tabs.Content value="deals" class="w-full h-full p-2 overflow-scroll"
      ></Tabs.Content>
    </Tabs.Root>
  </div>

  <div slot="sidebar">
    {#if !$appStore.isMobile}
      <AccountForm bind:crmAccount />
    {/if}
  </div>
</PageWithTitle>
