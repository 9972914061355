<script lang="ts">
  import { filterFieldsDef } from './filter-fields-definitions';
  import { createEventDispatcher, onMount } from 'svelte';
  import { Button } from '$lib/components/ui/button';
  import { Input } from '$lib/components/ui/input';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';

  import { getItems, type FilterType } from '$lib/api/queries';
  import FiltersGroups from './filters-groups.svelte';
  import MagicFilter from '../filter/magic-filter/magic-filter.svelte';

  const dispatch = createEventDispatcher();

  export let collection: string = 'accounts';
  export let withGraphs: boolean = false;
  export let filters: FilterType[] = [];
  export let search: string = '';
  export let fulltextsearch = false;
  export let autoapply = false;

  let localFilters: FilterType[] = [];

  onMount(() => {
    localFilters = filters;
  });

  $: fields = collection && filterFieldsDef[collection];
  $: localFilters = filters;

  const localCount = tweened(0, {
    duration: 500,
    easing: cubicOut,
  });

  $: getItems({
    collection,
    search,
    fulltextsearch,
    filters: localFilters,
  }).then(result => {
    localCount.set(result.count);
  });

  function applyFilters() {
    dispatch('filters', localFilters);
  }

  function onCancel() {
    dispatch('cancel');
  }

  function onToggleFilter(fieldName, active) {
    localFilters = localFilters.filter(
      f => f.field !== fieldName || f.active === active,
    );
  }

  function onToggleFilterCriteria(field, bucket) {
    const filterByFieldFound = localFilters.find(f => f.field === field.value);
    const operator = field.isArray
      ? 'ov' //'cd' // ? 'cs'
      : 'in';

    // const filterValue = bucket.key;
    if (filterByFieldFound) {
      const valueFound =
        filterByFieldFound.value.filter(el => el.key === bucket.key)?.length >
        0;
      const newValue = valueFound
        ? filterByFieldFound.value.filter(v => v.key !== bucket.key)
        : [...filterByFieldFound.value, bucket];

      localFilters = [
        ...localFilters.filter(f => f.field !== field.value),
        {
          field: field.value,
          operator,
          value: newValue,
          active: true,
        },
      ];
      console.log('localFilters', localFilters);
    } else {
      localFilters = [
        ...localFilters,
        {
          field: field.value,
          operator,
          value: [bucket],
          active: true,
        },
      ];
      console.log('localFilters', localFilters);
    }

    if (autoapply) {
      // applyFilters();
    }
  }

  const operatorApplicableForField = field => {
    return field !== 'foundedOn';
  };
</script>

<div class="max-h-screen p-4 overflow-scroll">
  <div class="sticky z-50 w-full py-4 -top-8 -left-50">
    <div class="flex-1 w-full px-4 py-2 text-left bg-primary-50">
      <div class="font-bold tracking-tighter text-7xl">
        {Math.ceil($localCount)}
      </div>
      <div class="text-[1rem] uppercase text-muted-foreground">Items found</div>
    </div>
  </div>

  <div class="space-y-2">
    {#if false}
      <MagicFilter {fields} />
    {/if}
    <div class="">
      {#each fields || [] as field}
        <FiltersGroups
          {collection}
          fieldDefinition={field}
          field={field.value}
          fieldLabel={field.label}
          {withGraphs}
          {search}
          {fulltextsearch}
          filters={localFilters}
          on:toggleCheck={({ detail }) => onToggleFilter(field.value, detail)}
          on:toggleFilterCriteria={({ detail }) =>
            onToggleFilterCriteria(field, detail)}
        />
      {/each}
      {#if false}
        {#each localFilters || [] as filter}
          <div class="grid-cols-3 gap-2 px-2 my-2 tn-grid">
            <select bind:value={filter.field} class="text-sm">
              {#each fields || [] as field}
                <option value={field.value}>{field.label}</option>
              {/each}
            </select>
            <select bind:value={filter.operator} class="text-sm">
              {#each operators || [] as operator}
                <option value={operator.value}>{operator.label}</option>
              {/each}
            </select>

            {JSON.stringify(filter.value)}
            <Input
              type="text"
              variant="default"
              bind:value={filter.value.key}
              class="h-24! p-2 text-2xl"
            />
          </div>
        {/each}
      {/if}
    </div>
  </div>

  {#if false}
    <Button
      variant="outline"
      on:click={() =>
        (localFilters = [
          ...localFilters,
          {
            field: '',
            operator: 'eq',
            value: '',
            active: true,
          },
        ])}>Add field</Button
    >
  {/if}

  {#if true || !autoapply}
    <div class="flex-1 mt-4 space-x-2">
      <Button variant="outline" on:click={onCancel}>Cancel</Button>

      <Button variant="default" on:click={applyFilters}>Apply Filters</Button>
    </div>
  {/if}
</div>
