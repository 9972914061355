import {
  BrowserClient,
  defaultStackParser,
  getDefaultIntegrations,
  makeFetchTransport,
  Scope,
} from '@sentry/browser';

import * as Sentry from '@sentry/svelte';

const isPluginMode = import.meta.env.MODE === 'plugin';

export function initSentry() {
  try {
    if (isPluginMode) {
      // filter integrations that use the global variable
      const integrations = getDefaultIntegrations({}).filter(
        defaultIntegration => {
          return ![
            'BrowserApiErrors',
            'TryCatch',
            'Breadcrumbs',
            'GlobalHandlers',
          ].includes(defaultIntegration.name);
        },
      );

      const client = new BrowserClient({
        dsn: 'https://7419702c2f3df0dbd667fa6509c29990@o4507273013886976.ingest.de.sentry.io/4507273059696720',
        transport: makeFetchTransport,
        stackParser: defaultStackParser,
        integrations: integrations,
      });

      const scope = new Scope();
      scope.setClient(client);

      client.init();
    } else {
      Sentry.init({
        dsn: 'https://7419702c2f3df0dbd667fa6509c29990@o4507273013886976.ingest.de.sentry.io/4507273059696720',
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/.*\.supa_TODO_base\.co/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend(event, hint) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception && event.event_id) {
            // Sentry.showReportDialog({ eventId: event.event_id });
          }
          return event;
        },
      });
    }
  } catch (error) {
    console.error('Sentry initialization failed:', error);
  }
}
