<script lang="ts">
  import * as AlertDialog from '$lib/components/ui/alert-dialog';
  import { Button } from '$lib/components/ui/button';
  import HantaInputMultiSelectLean from './input/hanta-input-multi-select-lean.svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import { getItems } from '$lib/api/queries';
  import { addContactToDeal } from '$lib/api/mutations';

  export let customerId;
  export let dealId = undefined;

  export let value = undefined;
  const loadOptionsContacts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 20,
      search: keyword,
      select: 'id,name,photo',
      filters: [
        {
          field: 'customer',
          operator: 'eq',
          value: customerId,
          active: true,
        },
      ],
    });

    return result.data;
  };

  const onAddContactToDeal = async () => {
    if (value) {
      console.log('addContactToDeal', value);
      await addContactToDeal({
        dealId: dealId,
        contactId: value.id,
      });
    }
  };
</script>

<AlertDialog.Root>
  <AlertDialog.Trigger asChild let:builder>
    <Button builders={[builder]} variant="outline">Add existing contact</Button>
  </AlertDialog.Trigger>
  <AlertDialog.Content>
    <AlertDialog.Header>
      <AlertDialog.Title>Set up your deal contact</AlertDialog.Title>
      <AlertDialog.Description class="p-8">
        <HantaInputMultiSelectLean
          class="w-full"
          bind:value
          label="Contact"
          loadOptions={loadOptionsContacts}
          multiple={false}
          placeholder="Select contact..."
        >
          <div
            class="flex items-center w-full h-full"
            let:index
            let:item
            slot="item"
          >
            <Avatar.Root class="size-7">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={item?.photo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="ml-2 text-sm truncate">{item?.name}</div>
          </div>
          <div
            class="flex items-center w-full h-full overflow-hidden"
            let:selection
            slot="selection"
          >
            <Avatar.Root class="size-7">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={selection?.photo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="w-full ml-2 text-sm truncate">
              {selection?.name}
            </div>
          </div>
        </HantaInputMultiSelectLean>
      </AlertDialog.Description>
    </AlertDialog.Header>
    <AlertDialog.Footer>
      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
      <AlertDialog.Action on:click={onAddContactToDeal} disabled={!value}
        >Continue</AlertDialog.Action
      >
    </AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
