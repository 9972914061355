<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import LinkedinButton from '$lib/components/hanta/linkedin-button.svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import { Button } from '$lib/components/ui/button';
  import { cn } from '$lib/utils';
  import { Check, Circle, MapPinIcon } from 'lucide-svelte';
  import { Badge } from '$lib/components/ui/badge';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';

  export let item: any;
  export let module: string;
  export let withSelection: boolean;
  export let selected: any = [];
  export let disabledIds = [];

  const dispatch = createEventDispatcher();
</script>

{#if module === 'accounts'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="absolute top-0 right-0 p-2 border">
      <Avatar.Root class="rounded-none size-8">
        <Avatar.Image
          src={item.logo}
          alt="Company Logo"
          class="object-scale-down rounded-none"
        />

        <Avatar.Fallback
          class="w-full p-1 text-[8px] text-center text-gray-400 rounded-none bg-gradient-to-r from-gray-300 to-gray-100 "
        >
          {item.name ?? ''}
        </Avatar.Fallback>
      </Avatar.Root>
    </div>
    <div class="flex-1 space-y-1 mr-14">
      <div class="mb-4 font-semibold">
        {item.name ?? ''}
      </div>
      <div class="">
        <div class="flex items-center space-x-1">
          {#if item.address?.city}
            <MapPinIcon class="w-4 h-4" />
            <div>
              {item.address.city}
            </div>
          {/if}
        </div>

        {#if item.linkedin}
          <div class="block -ml-3">
            <LinkedinButton linkedin={item.linkedin} />
          </div>
        {/if}
      </div>
    </div>
  </div>
{:else if module === 'invoices'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="w-full overflow-hidden">
      <div class="font-light text-md">
        {item.invoiceId}
        <Badge
          variant="outline"
          class={cn(
            'mt-2',
            item.status === 'Overdue' && 'bg-yellow-600 text-white',
            item.status === 'Paid' && 'bg-green-600 text-white',
            item.status === 'Cancellation invoice' && 'bg-red-600 text-white',
            item.status === 'Cancelled' && 'bg-red-600 text-white',
          )}
        >
          {item.status ?? ''}</Badge
        >
      </div>
      <CrmAvatar
        size="size-8 my-2"
        module={'accounts'}
        id={item.customer?.id}
        name={item.customer?.name}
        withBorder={true}
        withHover={false}
      />

      <Badge variant="outline" class="rounded-none">
        <div>
          {new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
          }).format(item.gross)}
        </div>
      </Badge>
    </div>
  </div>
{:else if module === 'deals'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="w-full overflow-hidden">
      <CrmAvatar
        size="size-8"
        module={'accounts'}
        id={item.accounts?.id}
        name={item.accounts?.name}
        withBorder={true}
        withHover={false}
      />
      <div class="mt-4 font-light text-md">{item.name}</div>
    </div>
  </div>
{:else if module === 'contacts'}
  {@const isSelected = selected?.find(el => el?.id === item.id)}
  <div class="relative flex items-start w-full gap-2 group min-h-[80px]">
    <div class="">
      {#if withSelection}
        {@const disabled = disabledIds.includes(item.id)}
        <Avatar.Root
          class={cn(
            'size-12 ',
            isSelected && 'ring-4 ring-yellow-500 relative',
            disabled && 'grayscale',
          )}
        >
          <Button
            variant="outline"
            on:click={e => {
              if (disabled) return;
              dispatch('select', {
                module,
                id: item.id,
                name: item.name,
              });
              e.stopPropagation();
            }}
            class={cn(
              'p-0 rounded-full size-16',
              withSelection && !disabled && 'group-hover:opacity-40',
            )}
          >
            <Avatar.Image
              src={item.photo}
              alt={item.name}
              class={cn(
                'absolute inset-0 object-scale-down',
                withSelection && !disabled && 'group-hover:opacity-0',
              )}
            />
            <Avatar.Fallback
              class="absolute inset-0 w-full p-1 text-base text-center rounded-none text-primary-400 bg-gradient-to-r from-gray-300 to-gray-100 "
            >
              {item.firstname?.substring(0, 1) + item.lastname?.substring(0, 1)}
            </Avatar.Fallback>
            {#if !disabled}
              {#if isSelected}
                <div
                  class="absolute inset-0 w-full bg-yellow-900 rounded-full opacity-70"
                >
                  <Check class="mx-auto mt-3 text-white size-6" />
                </div>
              {:else}
                <div
                  class="absolute inset-0 hidden w-full bg-yellow-900 rounded-full group-hover:block opacity-70"
                >
                  <Circle class="mx-auto mt-3 text-white size-6" />
                </div>
              {/if}
            {/if}
          </Button>
        </Avatar.Root>
      {:else}
        <div
          class="absolute object-cover p-0.5 rounded-none -top-3 text-primary/10 bg-primary-foreground/80 -left-3 size-24 grayscale"
        >
          {#if item.photo}
            <img src={item.photo} alt={item.name} />
          {:else}
            <div class="w-full p-2 text-xl">
              {item.firstname?.substring(0, 1) + item.lastname?.substring(0, 1)}
            </div>
          {/if}
        </div>
      {/if}
    </div>
    <div class="w-full">
      <div class={cn('', withSelection ? 'ml-0' : 'ml-24 min-h-20')}>
        <div class="font-bold">{item.firstname ?? ''}</div>
        <div class="font-bold">
          {item.lastname ?? ''}
        </div>
        {#if !withSelection && item.linkedin}
          <div class="-ml-4">
            <LinkedinButton linkedin={item.linkedin} />
          </div>
        {/if}
      </div>
      <div class="pt-2 mt-1 text-muted-foreground line-clamp-2">
        {item.position ?? ''}
      </div>
    </div>
  </div>
{:else}
  <div>{item.name}</div>
{/if}
