<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import { Input } from '$lib/components/ui/input';

  export let form;
  export let name;
  export let label;
  export let description = '';

  const { form: formData } = form;
</script>

{#if $formData[name]}
  <Form.Field class="space-y-1" {form} name="address.street">
    <Form.Control let:attrs>
      <Form.Label>Street</Form.Label>
      <Input
        {...attrs}
        bind:value={$formData[name].street}
        class="w-full"
        variant="hanta"
      />
    </Form.Control>
    <Form.FieldErrors />
  </Form.Field>

  <Form.Field class="space-y-1" {form} name="address.city">
    <Form.Control let:attrs>
      <Form.Label>City</Form.Label>
      <Input
        {...attrs}
        bind:value={$formData[name].city}
        class="w-full"
        variant="hanta"
      />
    </Form.Control>
    <Form.FieldErrors />
  </Form.Field>

  <Form.Field class="space-y-1" {form} name="address.postalCode">
    <Form.Control let:attrs>
      <Form.Label>Postal Code</Form.Label>
      <Input
        {...attrs}
        bind:value={$formData[name].postalCode}
        class="w-full"
        variant="hanta"
      />
    </Form.Control>

    <Form.FieldErrors />
  </Form.Field>

  <Form.Field class="space-y-1" {form} name="address.country">
    <Form.Control let:attrs>
      <Form.Label>Country</Form.Label>
      <Input
        {...attrs}
        bind:value={$formData[name].country}
        class="w-full"
        variant="hanta"
      />
    </Form.Control>
    <Form.FieldErrors />
  </Form.Field>
{/if}
