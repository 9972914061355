<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';
  import { Target } from 'lucide-svelte';
  import * as Popover from '$lib/components/ui/popover/index.js';
  import { type ActivityWithRefs } from '../../../../drizzle/schema';
  import { getItems } from '$lib/api/queries';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import { type SuperForm } from 'sveltekit-superforms';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { primaryRoutes } from '$lib/app/config';
  import { cn } from '$lib/utils';

  export let activity: ActivityWithRefs;
  export let form: SuperForm<ActivityWithRefs>;
  export let onSelectChanged: (
    value: { id: string; name: string; role: string }[],
  ) => void = () => {};

  const createLoadOptions: (keyword: string) => any = (module: string) => {
    let select =
      'id,name' + (module === 'candidates' || module === 'contacts')
        ? ',role,photo'
        : ',logo';

    if (module === 'deals') {
      select = 'id,name,dealPhase,customer';
    }

    return async (keyword: string): Promise<any> => {
      const result = await getItems({
        collection: module === 'candidates' ? 'contacts' : module,
        from: 0,
        to: 10,
        search: keyword,
        filters:
          module === 'candidates' || module === 'contacts'
            ? [
                {
                  field: 'role',
                  operator: 'in',
                  value: (module === 'candidates'
                    ? ['Candidate']
                    : ['Contact']
                  ).concat('ContactAndCandidate'),
                  active: true,
                },
              ]
            : [],
        select: select,
      });

      return result.data;
    };
  };

  let selected = 'all';
  const { form: formData, enhance } = form;

  $: count = ['contacts', 'deals', 'accounts', 'candidates'].reduce(
    (acc, key) => {
      return acc + $formData[key]?.length || 0;
    },
    0,
  );

  $: all = ['contacts', 'deals', 'accounts', 'candidates'].reduce(
    (acc, key) => {
      const array = $formData[key] || [];
      return [...acc, ...array];
    },
    [],
  );

  $: console.log('FORM', $formData.deals);
</script>

<Popover.Root portal={null}>
  <Popover.Trigger asChild let:builder>
    <Button
      builders={[builder]}
      class="p-0 my-1 font-bold hover:bg-transparent group"
      on:click={() => {
        selected = 'all';
      }}
      variant="ghost"
    >
      <span
        class="transition-colors group-hover:text-foreground text-muted-foreground"
        >Associated with
        <Badge class="mx-2" variant="secondary">{all?.length ?? 0}</Badge>
        records
      </span>
    </Button>
  </Popover.Trigger>
  <Popover.Content class="w-full md:w-[650px] overflow-visible p-0">
    <div class="flex flex-row gap-4">
      <div
        class="flex flex-col items-start content-start py-1 md:w-48 bg-muted shrink-0"
      >
        <Button
          class="flex flex-row w-full capitalize border-b border-solid border-b-primary-200/30"
          on:click={() => (selected = 'all')}
          variant="ghost"
        >
          <div class="w-full" class:font-bold={'all' === selected}>
            <span class="hidden md:block">Overview</span>
            <Target class="size-4" />
          </div>
          <Badge
            class="ml-2"
            variant={'all' === selected ? 'default' : 'secondary'}
            >{count}</Badge
          >
        </Button>
        {#each ['contacts', 'deals', 'accounts', 'candidates'] as assignment (assignment)}
          <Button
            variant="ghost"
            class="flex flex-row w-full capitalize "
            on:click={() => (selected = assignment)}
          >
            <div class="w-full" class:font-bold={assignment === selected}>
              <span class="hidden md:block">
                {assignment}
              </span>
              <svelte:component
                this={primaryRoutes.find(
                  el =>
                    el.module ===
                    (assignment === 'candidates' ? 'contacts' : assignment),
                )?.icon}
                class={cn(
                  'block size-4 md:hidden',
                  assignment === 'candidates' ? 'text-destructive/50' : '',
                )}
                aria-hidden="true"
              />
            </div>
            <Badge
              variant={assignment === selected ? 'default' : 'secondary'}
              class="ml-2">{$formData[assignment]?.length ?? 0}</Badge
            >
          </Button>
        {/each}
      </div>

      <div class="flex flex-col w-2/3 md:w-full md:gap-1 md:p-4">
        {#key selected}
          {#if selected === 'all'}
            <div class="mt-3">
              {#each ['contacts', 'deals', 'accounts', 'candidates'] as assignment (assignment)}
                {#if $formData[assignment]?.length > 0}
                  <div class="flex flex-col gap-1">
                    <div class="text-sm font-bold capitalize">
                      {assignment}
                    </div>
                    <HantaInputMultiSelect
                      class="w-full"
                      {form}
                      loadOptions={createLoadOptions(assignment)}
                      name={assignment}
                      placeholder="Select {assignment}"
                      {onSelectChanged}
                    >
                      <div
                        class="flex items-center h-full"
                        let:index
                        let:item
                        slot="item"
                      >
                        <CrmAvatar
                          module={assignment === 'deals'
                            ? 'accounts'
                            : assignment}
                          id={assignment === 'deals'
                            ? item?.customer
                            : item?.id}
                          name={item?.name}
                        />
                      </div>
                      <div
                        class="flex items-center h-full"
                        let:selection
                        slot="selection"
                      >
                        <CrmAvatar
                          module={assignment === 'deals'
                            ? 'accounts'
                            : assignment}
                          id={assignment === 'deals'
                            ? selection?.customer
                            : selection?.id}
                          name={selection?.name}
                        />
                      </div>
                    </HantaInputMultiSelect>
                  </div>
                {/if}
              {/each}
            </div>
          {:else}
            <div class="text-sm font-bold capitalize">
              {selected}
            </div>
            <HantaInputMultiSelect
              class="w-full"
              {form}
              loadOptions={createLoadOptions(selected)}
              name={selected}
              placeholder="Select {selected}"
              {onSelectChanged}
            >
              <div
                class="flex items-center h-full"
                let:index
                let:item
                slot="item"
              >
                <CrmAvatar
                  module={selected === 'deals' ? 'accounts' : selected}
                  id={selected === 'deals' ? item?.customer : item?.id}
                  name={item?.name}
                />
              </div>
              <div
                class="flex items-center h-full"
                let:selection
                slot="selection"
              >
                <CrmAvatar
                  module={selected === 'deals' ? 'accounts' : selected}
                  id={selected === 'deals'
                    ? selection?.customer
                    : selection?.id}
                  name={selection?.name}
                />
              </div>
            </HantaInputMultiSelect>
          {/if}
        {/key}
      </div>
    </div>
  </Popover.Content>
</Popover.Root>
