<script lang="ts">
  import { ArrowBigLeft, ArrowLeft } from 'lucide-svelte';
  import Button from './../components/ui/button/button.svelte';
  import { appStore } from '$lib/app-store';
  import * as Sheet from '$lib/components/ui/sheet';
  import SheetHeader from '$lib/components/ui/sheet/sheet-header.svelte';
  import { createQuery } from '@tanstack/svelte-query';
  import { derived, writable } from 'svelte/store';
  import { getOrCreateItem } from '$lib/api/queries';
  import { cn } from '$lib/utils';
  import CrmObjectActions from '$lib/ui/crm-object-actions.svelte';

  export let open = false;
  export let module;
  export let id;

  const moduleStore = writable(module);
  const idStore = writable(id);

  $: moduleStore.set(module);
  $: idStore.set(id);

  const query = createQuery(
    derived([moduleStore, idStore], ([$module, $id]) => ({
      enabled: !!$module,
      queryKey: [$module, $id],
      queryFn: () => getOrCreateItem($module, $id),
    })),
  );

  function onBack() {
    appStore.select({
      module:
        $appStore.selectedStack[$appStore.selectedStack.length - 1].module,
      id: $appStore.selectedStack[$appStore.selectedStack.length - 1].id,
      tab: $appStore.selectedStack[$appStore.selectedStack.length - 1].tab,
    });
    appStore.setSelectedStack([]);
  }

  const onOpenChangeFn = () => {
    appStore.openPopup(!$appStore.openPopup);
  };
</script>

<Sheet.Root
  bind:open
  onOpenChange={onOpenChangeFn}
  closeOnOutsideClick={!$appStore.openActivity}
>
  <Sheet.Content
    class={cn(
      'bg-gray-50 dark:bg-primary-foreground sm:max-w-[calc(100%-60px)] h-full p-0 ',
      $appStore.isMobile ? 'w-full z-50' : 'w-[calc(100%-360px)]',
    )}
  >
    <Sheet.Header class="flex space-y-0 w-full h-full">
      <Sheet.Title
        class="px-6 pt-4 pb-2 border-b border-solid border-b-primary-200/20"
      >
        {#if $appStore.selectedStack?.length > 0}
          {#each $appStore.selectedStack.filter(e => e.module !== 'dashboard') as stack}
            <Button variant="link" on:click={onBack}>
              <ArrowBigLeft />
              Back to {stack.module}</Button
            >
          {/each}
        {:else}
          <div
            class="flex flex-row items-baseline space-x-4 h-full font-normal"
          >
            {#if $appStore.isMobile}
              <Button
                variant="secondary"
                on:click={onOpenChangeFn}
                class="rounded-full"
              >
                <ArrowLeft class="size-4" />
              </Button>
            {/if}
            <h2 class="font-bold capitalize">{$appStore.selected.module}</h2>

            <CrmObjectActions
              id={$appStore.selected.id}
              {module}
              item={$query.data}
            />
            {#if $$slots.actionBar}
              <slot name="actionBar" />
            {/if}
          </div>
        {/if}
      </Sheet.Title>

      {#if $query.isSuccess}
        {@const item = $query.data}
        <SheetHeader>
          <slot name="header" {item} />
        </SheetHeader>
        {#key item.id}
          <slot {item} />
        {/key}
      {/if}
    </Sheet.Header>
  </Sheet.Content>
</Sheet.Root>
