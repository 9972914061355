<script lang="ts">
  import { toast } from 'svelte-sonner';
  import HantaInputMultiSelect from './hanta-input-multi-select.svelte';
  import { onMount } from 'svelte';
  import { getItems } from '$lib/api/queries';
  import { updateLov } from '$lib/api/mutations';
  import { insertLovsSchema, type Lovs } from '../../../../drizzle/schema';
  import { type LovType } from '$lib/widgets/input/lov-types';

  type $$Props = {
    form: any;
    type: LovType;
    name: string;
    label: string;
    placeholder: string;
    multiple?: boolean;
    addable?: boolean;
    readonly?: boolean;
  };

  export let form: any;
  export let type: LovType;
  export let name: string;
  export let label: string;
  export let placeholder: string;
  export let multiple: boolean = true;
  export let addable: boolean = false;
  export let readonly: boolean = false;

  let options: any[];
  let lov: Lovs;

  function updateOptions(lov: Lovs) {
    options = (lov?.values || []).flat().map(el => el.name);
  }

  onMount(async () => {
    const result = await getItems({
      collection: 'lovs',
      from: 0,
      to: 1,
      search: '',
      select: 'id,name,values',
      filters: [
        {
          field: 'name',
          operator: 'eq',
          value: type,
          active: true,
        },
      ],
    });

    if (result.data.length === 0) {
      return;
    }
    if (result.data.length === 0) {
      updateOptions({ values: [] });
      return;
    }
    lov = result.data[0] as Lovs;
    updateOptions(lov);
  });

  async function onNewLovAdded(newValue: string) {
    console.log('New LOV Added', { lov });

    lov.values.push({ name: newValue });

    const lovToUpdate = insertLovsSchema.parse(lov) as Lovs;
    updateOptions(lovToUpdate);

    const UNDO_TIMEOUT = 5000;
    const timeoutId = setTimeout(async () => {
      const newLov = await updateLov(lovToUpdate);
      updateOptions(newLov);
    }, UNDO_TIMEOUT);

    toast(`New ${label ?? 'tag'} entry created: "${newValue}"`, {
      duration: UNDO_TIMEOUT,
      action: {
        label: 'Undo',
        onClick: e => {
          clearTimeout(timeoutId);
          lovToUpdate.values = lov.values.filter(el => el.name !== newValue);
          updateOptions(lovToUpdate);
        },
      },
    });
  }
</script>

<HantaInputMultiSelect
  readOnly={readonly}
  {form}
  {label}
  {placeholder}
  {name}
  onItemAdded={lov => {
    onNewLovAdded(lov);
  }}
  {options}
  {multiple}
  {addable}
  simpleMode={true}
/>
<!--
   <div class="w-full ml-2 text-sm truncate" let:selection slot="selection">
    {#each selection || [] as item, index}
      <Badge class="mr-1" color="primary" size="sm">
        <span>
          {item}
        </span>
      </Badge>
    {/each}
  </div>
-->
