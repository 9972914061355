<script lang="ts">
  import { Accordion as AccordionPrimitive } from 'bits-ui';
  import ChevronDown from 'lucide-svelte/icons/chevron-down';
  import { cn } from '$lib/utils';

  type $$Props = AccordionPrimitive.TriggerProps;
  type $$Events = AccordionPrimitive.TriggerEvents;

  let className: $$Props['class'] = undefined;
  export let level: AccordionPrimitive.HeaderProps['level'] = 3;
  export { className as class };
</script>

<AccordionPrimitive.Header {level} class="flex">
  <AccordionPrimitive.Trigger
    class={cn(
      'flex flex-1 justify-start items-center py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
      className,
    )}
    {...$$restProps}
    on:click
  >
    <ChevronDown class="w-4 h-4 mr-4 transition-transform duration-200" />
    <slot />
  </AccordionPrimitive.Trigger>
</AccordionPrimitive.Header>
