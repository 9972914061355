<script>
  import { downloadFile } from '$lib/api/storage';
  import AdobePreview from './adobe-preview.svelte';
  export let folder;
  export let filename;
  export let outputfilename = undefined;
  export let mode = 'IN_LINE';
  export let onClose = () => {};

  let blob;

  $: if (folder && filename) {
    blob = null;
    downloadFile(folder, filename)
      .then(result => {
        console.log('result', result);
        blob = URL.createObjectURL(result);
      })
      .catch(() => {
        blob = null;
      });
  }
</script>

{#if blob}
  <AdobePreview
    file={blob}
    name={outputfilename ?? filename}
    {mode}
    {onClose}
  />
{/if}
