import { authStore } from '$lib/auth-store';
import { supabase } from './supabase';
import { get } from 'svelte/store';
import { findMessageByMessageId, findLiContactByName } from './queries';

import {
  type Account,
  type Activity,
  type Deal,
  type Invoice,
  type Lovs,
  insertAccountsSchema,
  ActivityWithRefs,
} from './../../../drizzle/schema';
import { getItemById } from '$lib/api/queries';

type MessageType = {
  messageId: string;
  message: string;
  sender: string;
  time: string;
};

export type ChatUser = {
  userId: string;
  profileLink: string;
  name: string;
};

export async function saveLiMails(
  chatWithUser: ChatUser,
  messages: MessageType[],
) {
  if (messages && messages.length > 0) {
    console.log('saveLiMails', messages);

    const found = await findLiContactByName(chatWithUser?.name);
    if (found.length === 0) {
      // TODO: import contact from linkedin
      return null;
    }

    if (found.length > 2) {
      // need to handle this
      // idea is to fetch profile url
      // then extract public identifier from content
      // and search via linkedin url
      throw new Error('More than one contact found');
    }

    const chatUser = found[0];

    const candidate =
      chatUser.role === 'Candidate' || chatUser.role === 'ContactAndCandidate'
        ? chatUser
        : null;
    const contact =
      chatUser.role === 'Contact' || chatUser.role === 'ContactAndCandidate'
        ? chatUser
        : null;

    const consultant = null; // = await findConsultantByName() .find(contact => contact.role === 'Employee');

    console.log('consultant', consultant);
    console.log('candidate', candidate);
    console.log('contact', contact);

    const messagesToSave = await Promise.all(
      messages.map(async message => {
        const found = await findMessageByMessageId(message.messageId);
        if (found.length > 0) {
          return null;
        }

        return {
          type: 'LinkedIn',
          incommingMessage: chatWithUser.name === message.sender,
          messageId: message.messageId,
          name: message.sender,
          description: message.message,
          // consultant: consultant?.id,
          contacts: contact?.id
            ? [
                {
                  id: contact.id,
                  name: contact.name,
                },
              ]
            : [],
          candidates: candidate?.id
            ? [
                {
                  id: candidate.id,
                  name: candidate.name,
                },
              ]
            : [],
        };
      }),
    );

    console.log('messagesToSave', messagesToSave);
    const { data, error } = await supabase(get(authStore)?.token)
      .from('activities')
      .insert(messagesToSave.filter(Boolean))
      .select();

    if (error) {
      console.log('error', error);
      throw new Error(error.message);
    }
    return data;
  }
  return [];
}

export async function generateRefId(module: string) {
  const { data: refId, error } = await supabase(get(authStore)?.token).rpc(
    'get_seq_id',
    {
      p_table_name: module,
    },
  );
  if (error) {
    console.log('error', error);
    // ignore
  } else {
    return refId;
  }
}

export async function createItem(module, item) {
  if (!item.id && module !== 'invoices') {
    const refId = await generateRefId(module);
    if (refId) {
      item.refId = refId;
    }
  }
  const { data, error } = await supabase(get(authStore)?.token)
    .from(module)
    .insert([item])
    .select();

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function deleteItem(module, id) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from(module)
    .delete()
    .eq('id', id);

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function updateLov(lov: Lovs): Promise<Lovs> {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('lovs')
    .update({ values: lov.values })
    .eq('id', lov.id)
    .select();

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }

  return data[0] as Lovs;
}
export async function addContactToDeal({ dealId, contactId }) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('dealsToContacts')
    .insert({ dealId, contactId })
    .select();

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data[0];
}

export async function saveContact(contactToSave) {
  delete contactToSave.fts;
  delete contactToSave.accounts;
  delete contactToSave.deals;
  if (contactToSave.customer && contactToSave.customer.id) {
    contactToSave.customer = contactToSave.customer.id;
  }
  contactToSave.name =
    (contactToSave.firstname ? contactToSave.firstname + ' ' : '') +
    (contactToSave.lastname ?? '');

  const refIds = [
    ...(contactToSave.refIds || []),
    contactToSave.email ?? '',
    contactToSave.communicationPrivate?.email ?? '',
    contactToSave.communicationPrivate?.secondaryEmail ?? '',
    contactToSave.communicationWork?.email ?? '',
    contactToSave.communicationWork?.secondaryEmail ?? '',
  ].map(id => id?.toLowerCase());

  contactToSave.refIds = Array.from(new Set(refIds.filter(Boolean)));

  if (!contactToSave.refId) {
    const refId = await generateRefId('contacts');
    contactToSave.refId = refId;
  }

  const { data, error } = await supabase(get(authStore)?.token)
    .from('contacts')
    .upsert(contactToSave)
    .select();

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data[0];
}

export async function activitiesToAccount(activitiesToAccount) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('activitiesToAccounts')
    .upsert(activitiesToAccount)
    .select();

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data[0];
}

export const updateContactRole = async (role, id) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('contacts')
    .update({ role })
    .eq('id', id);

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data && data.length > 0 && data[0];
};

export const updateAccountContact = async (contactId, account) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('contacts')
    .update({ customer: account.id })
    .eq('id', contactId);

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data && data.length > 0 && data[0];
};

export const updateAccount = async id => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('accounts')
    .update({ modifiedAt: new Date().toISOString() })
    .eq('id', id);

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data && data.length > 0 && data[0];
};

export const updateStatus = async (status, id) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('activities')
    .update({ status })
    .eq('id', id);

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data && data.length > 0 && data[0];
};

export const saveTimelog = async timelog => {
  const { duration, ...timelogToSave } = timelog;
  const { data, error } = await supabase(get(authStore)?.token)
    .from('timelogs')
    .upsert(timelogToSave)
    .select();

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }

  return data[0];
};

export const saveFilter = async filter => {
  const { filters, ...filterToSave } = filter;
  const { data, error } = await supabase(get(authStore)?.token)
    .from('filters')
    .upsert(filterToSave)
    .select();

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }

  return data[0];
};

export const saveActivity = async (
  activity: ActivityWithRefs,
): Promise<Activity> => {
  const activityToSave = {
    ...activity,
  };

  delete activityToSave.allContacts;

  delete activityToSave.consultant;
  if (activity?.consultantObj?.id) {
    activityToSave.consultant = activity.consultantObj.id;
  }

  if (activity.type === 'Assignment') {
    activityToSave.account = activity.accountObj?.id;
    activityToSave.deal = activity.dealObj?.id;
    activityToSave.candidate = activity.candidateObj?.id;

    if (!activityToSave.accounts) {
      activityToSave.accounts = [];
    }

    if (!activityToSave.deals) {
      activityToSave.deals = [];
    }

    if (!activityToSave.candidates) {
      activityToSave.candidates = [];
    }

    activityToSave.accounts.push(activity.accountObj);
    activityToSave.deals.push(activityToSave.dealObj);
    activityToSave.candidates.push(activityToSave.candidateObj);
  }

  activityToSave.refs = [
    ...(activity.accounts?.map(account => account?.id) || []),
    ...(activity.deals?.map(deal => deal?.id) || []),
    ...(activity.contacts?.map(contact => contact?.id) || []),
    ...(activity.candidates?.map(contact => contact?.id) || []),
  ];

  delete activityToSave.consultantObj;
  delete activityToSave.accountObj;
  delete activityToSave.dealObj;
  delete activityToSave.candidateObj;
  delete activityToSave.tsv_description;

  const { data, error } = await supabase(get(authStore)?.token)
    .from('activities')
    .upsert(activityToSave)
    .select();

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};

export const finalizeInvoice = async (id: string) => {
  const { data, error } = await supabase(
    get(authStore)?.token,
  ).functions.invoke('finalize-invoice', {
    body: {
      id,
    },
  });

  if (error) {
    throw Error(error.message);
  }
  return data;
};

export const updateInvoiceStatus = async (id: string, status: string) => {
  const { data, error } = await supabase(
    get(authStore)?.token,
  ).functions.invoke('update-status-invoice', {
    body: {
      id,
      status,
    },
  });

  if (error) {
    throw Error(error.message);
  }
};

export const saveInvoice = async (invoice: Invoice): Promise<Invoice> => {
  invoice.customer = invoice.customer?.id;
  invoice.recipient = invoice.recipient?.id;

  let response = await supabase(get(authStore)?.token)
    .from('invoices')
    .upsert(invoice)
    .select('id');
  const { data, error } = response;

  if (error) {
    throw Error(error.message);
  } else {
    return await getItemById('invoices', data[0].id);
  }
};

export const saveAccount = async (account: Account): Promise<Account> => {
  if (!account.refId) {
    const refId = await generateRefId('accounts');
    account.refId = refId;
  }
  let response = await supabase(get(authStore)?.token)
    .from('accounts')
    .upsert(account)
    .select();
  const { data, error } = response;

  if (error) {
    throw Error(error.message);
  } else {
    return insertAccountsSchema.parse(data[0]);
  }
};

export const saveDeal = async (deal: Deal): Promise<Deal> => {
  const dealToSave = { ...deal };
  if (deal.customerObj && deal.customerObj.id) {
    dealToSave.customer = deal.customerObj.id;
    delete dealToSave.customerObj;
  }
  let response = await supabase(get(authStore)?.token)
    .from('deals')
    .upsert(dealToSave)
    .select();

  console.log('response', response);
  const { data, error } = response;

  if (error) {
    throw Error(error.message);
  } else {
    return data[0];
  }
};
