<script lang="ts">
  import { Building } from 'lucide-svelte';
  import { toast } from 'svelte-sonner';
  import * as Card from '$lib/components/ui/card';
  import { appStore } from '$lib/app-store';
  import { Button } from '$lib/components/ui/button';

  import { saveAccount } from '$lib/api/mutations';
  import AlreadyThere from '../already-there.svelte';

  $: officialName =
    $appStore.linkedInAccount?.officialName ?? $appStore.linkedInAccount?.name;
  $: logo = $appStore.linkedInAccount?.logo ?? '';

  let disabled = false;

  let account = null;

  const onLinkAccount = async e => {
    const accountToSave = {
      ...e.detail,
      linkedin: $appStore.linkedInAccount.linkedin,
      websiteUrl: $appStore.linkedInAccount.websiteUrl,
      logo: $appStore.linkedInAccount.logo,
      officialName: $appStore.linkedInAccount.officialName,
      description: $appStore.linkedInAccount.description,
      tagline: $appStore.linkedInAccount.tagline,
      employeeCount: $appStore.linkedInAccount.employeeCount,
      foundedOn: $appStore.linkedInAccount.foundedOn,
      employeeCountRange: $appStore.linkedInAccount.employeeCountRange,
      specialties: $appStore.linkedInAccount.specialties,
      company: $appStore.linkedInAccount.company,
      universalName: $appStore.linkedInAccount.universalName,
      crunchbaseFundingData: $appStore.linkedInAccount.crunchbaseFundingData,
    };

    await saveAccountFn(accountToSave);
  };

  const onSaveAccount = async () => {
    await saveAccountFn($appStore.linkedInAccount);
  };

  const saveAccountFn = async accountToSave => {
    disabled = true;
    try {
      const newAccount = await saveAccount(accountToSave);
      appStore.setCrmAccount(newAccount);
      toast.info('Account created successfully!');
    } catch (e) {
      toast.error(
        'Error occurred: ' + e?.message ||
          'Unknown error occurred. Please try again later.',
      );
    } finally {
      disabled = false;
    }
  };
</script>

{#if $appStore.linkedInAccount && officialName}
  <Card.Root class="w-full">
    <Card.Header>
      <Card.Title>Add to hanta</Card.Title>
    </Card.Header>
    <Card.Content>
      <div class="w-full">
        <div class="flex items-center space-x-2">
          <img class="size-12" src={logo} alt="Company Logo" />
          <div>
            <h2 class="text-xl font-bold">{officialName}</h2>
            <div class="text-muted-foreground">is not in your CRM yet</div>
          </div>
        </div>
      </div>
    </Card.Content>
    <Card.Footer>
      <div class="flex justify-between w-full">
        <AlreadyThere bind:item={account} on:link={onLinkAccount} {disabled} />

        <Button variant="default" on:click={onSaveAccount} {disabled}>
          <Building />
          <div class="ml-2">Add as Account</div>
        </Button>
      </div>
    </Card.Footer>
  </Card.Root>
{/if}
