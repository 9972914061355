<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import { numberProxy } from 'sveltekit-superforms';

  import CurrencyInput from '@canutin/svelte-currency-input';
  import { currencyFormatter } from '$lib/app/utils';

  export let form;
  export let name;
  export let label = undefined;
  export let description = undefined;
  export let readOnly = undefined;

  const { form: formData } = form;

  $: fieldProxyTarget = numberProxy(formData, name, {
    empty: 'zero',
    initiallyEmptyIfZero: true,
    delimiter: '.',
  });

  const format: (value: number) => string = currencyFormatter().format;
</script>

{#key name}
  <Form.Field class="space-y-1" {form} {name}>
    <Form.Control let:attrs>
      {#if label}
        <Form.Label>{label}</Form.Label>
      {/if}

      {#if !readOnly}
        <CurrencyInput
          bind:value={$fieldProxyTarget}
          currency="EUR"
          inputClasses={{
            wrapper: 'inline-flex',
            formatted:
              'text-right inline-flex items-center justify-center rounded-md text-sm font-medium whitespace-nowrap' +
              ' ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2' +
              ' focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50' +
              ' w-full border-none h-7 p-0 rounded-md outline-none ring-0 hover:bg-muted hover:outline-none' +
              ' hover:ring-0 active:ring-0 active:border-none focus:ring-0 hover:border-b bg-transparent focus:bg-secondary' +
              ' w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
            formattedPositive: 'text-primary',
            formattedNegative: 'text-red-500',
          }}
          locale={navigator.language || navigator.languages[0]}
          name="total"
        />
      {:else}
        <span class="text-sm font-semibold">{format($fieldProxyTarget)}</span>
      {/if}
    </Form.Control>
    {#if description}
      <Form.Description>{description ?? ''}</Form.Description>
    {/if}
    <Form.FieldErrors />
  </Form.Field>
{/key}
