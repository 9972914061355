<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import { Input } from '$lib/components/ui/input';
  import { stringProxy } from 'sveltekit-superforms';

  export let form;
  export let name;
  export let label = undefined;
  export let description = undefined;
  export let readonly = false;

  const { form: formData } = form;

  $: fieldProxyTarget = stringProxy(formData, name, { empty: 'undefined' });
</script>

<Form.Field class="space-y-1" {form} {name}>
  <Form.Control let:attrs>
    {#if label}
      <Form.Label>{label}</Form.Label>
    {/if}
    {#if readonly}
      <span
        class="text-sm font-bold w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        >{$fieldProxyTarget}</span
      >
    {:else}
      <Input
        {...attrs}
        bind:value={$fieldProxyTarget}
        variant="hanta"
        type="time"
        class="w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      />
    {/if}
  </Form.Control>
  {#if description}
    <Form.Description>{description ?? ''}</Form.Description>
  {/if}
  <Form.FieldErrors />
</Form.Field>
