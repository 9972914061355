<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';

  const dispatch = createEventDispatcher();

  export let item = undefined;
  export let id = undefined;

  const openItem = payload => {
    dispatch('open', payload);
  };
</script>

<div
  class="gap-1.5 flex flex-col items-start w-[calc(100%-22px)] overflow-hidden"
>
  <div class="flex gap-1.5 flex-wrap">
    {#each item?.accounts ?? [] as el}
      {#if el.id && el.id !== id}
        <div
          class="flex items-center px-2 py-0.5 text-base text-primary/80 border border-solid rounded"
        >
          <CrmAvatar
            module="accounts"
            id={el.id}
            name={el.name}
            on:open={e => openItem(e.detail)}
          />
        </div>
      {/if}
    {/each}

    {#each item?.deals ?? [] as el}
      {#if el.id && el.id !== id}
        <div
          class="flex items-center px-2 py-0.5 text-base text-primary/80 border border-solid rounded"
        >
          <CrmAvatar
            module="deals"
            id={el.id}
            name={el.name}
            on:open={e => openItem(e.detail)}
          />
        </div>
      {/if}
    {/each}

    {#each item?.contacts ?? [] as el}
      {#if el.id && el.id !== id}
        {#if item?.type === 'LinkedIn' && !item.incommingMessage}
          <span class="mt-1.5 ml-2 text-xs">to</span>
        {/if}
        <div
          class="flex items-center px-2 py-0.5 text-base text-primary/80 border border-solid rounded"
        >
          <CrmAvatar
            module="contacts"
            id={el.id}
            name={el.name}
            on:open={e => openItem(e.detail)}
          />
        </div>
      {/if}
    {/each}
  </div>
  {#each item?.candidates ?? [] as el}
    {#if el.id !== id}
      {#if item?.type === 'LinkedIn' && !item.incommingMessage}
        <span class="mt-1.5 ml-2 text-xs">to</span>
      {/if}
      <div
        class="inline-flex items-center px-2 py-0.5 text-base text-primary/80 border border-solid rounded"
      >
        <CrmAvatar
          module="contacts"
          id={el.id}
          name={el.name}
          on:open={e => openItem(e.detail)}
        />
      </div>
    {/if}
  {/each}
</div>
