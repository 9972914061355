<script lang="ts">
  import HantaInputMultiSelectLean from './../input/hanta-input-multi-select-lean.svelte';

  import * as Avatar from '$lib/components/ui/avatar';
  import { getItems } from '$lib/api/queries';

  export let hideLabel = false;
  export let value = undefined;
  export let onChange = undefined;

  const loadOptionsAccounts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'accounts',
      from: 0,
      to: 25,
      search: keyword,
      select: 'id,name,logo',
    });

    return result.data;
  };
</script>

<HantaInputMultiSelectLean
  class="w-full"
  bind:value
  label={hideLabel ? '' : 'Account'}
  loadOptions={loadOptionsAccounts}
  multiple={false}
  onSelectChanged={value => onChange && onChange(value)}
  placeholder="Select account..."
>
  <div class="flex items-center w-full h-full" let:index let:item slot="item">
    <Avatar.Root class="size-7">
      <Avatar.Image class="object-scale-down rounded-none" src={item?.logo} />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="ml-2 text-sm truncate">{item?.name}</div>
  </div>
  <div
    class="flex items-center w-full h-full overflow-hidden"
    let:selection
    slot="selection"
  >
    <Avatar.Root class="size-7">
      <Avatar.Image
        class="object-scale-down rounded-none"
        src={selection?.logo}
      />
      <Avatar.Fallback>-</Avatar.Fallback>
    </Avatar.Root>
    <div class="w-full ml-2 text-sm truncate">
      {selection?.name}
    </div>
  </div>
</HantaInputMultiSelectLean>
