<script lang="ts">
  import { appStore } from '$lib/app-store';

  import { Button } from '$lib/components/ui/button';
  import { LinkedinIcon } from 'lucide-svelte';
  export let linkedin: string;

  $: shouldOpenInNewTab =
    import.meta.env.MODE === 'plugin' || $appStore.isMobile;
</script>

{#if linkedin}
  <Button
    variant="ghost"
    size="icon"
    href={linkedin}
    class="ml-1"
    target={shouldOpenInNewTab ? '_blank' : ''}
  >
    <LinkedinIcon class="size-4 text-[#0077B5] dark:text-[#e6f6ff]" />
  </Button>
{/if}
