<script lang="ts">
  import { mode } from 'mode-watcher';
  import { Skeleton } from '$lib/components/ui/skeleton';

  import { cubicOut } from 'svelte/easing';
  import { tweened } from 'svelte/motion';
  import type { Deal } from 'drizzle/schema';
  import { findAssignments } from '$lib/api/queries';
  import { onMount } from 'svelte';

  export let deal: Deal;
  let totalAssignments = tweened(0, { duration: 500, easing: cubicOut });
  let rejected = tweened(0, { duration: 500, easing: cubicOut });
  let rejectedRate = tweened(0, { duration: 500, easing: cubicOut });

  let loaded = false;
  onMount(async () => {
    const { count } = await findAssignments({
      id: deal.id,
      module: 'deals',
      page: 0,
      itemsPerPage: 1,
    });

    const { count: rejectedCount } = await findAssignments({
      id: deal.id,
      module: 'deals',
      page: 0,
      itemsPerPage: 1,
      filters: [
        {
          field: 'status',
          operator: 'in',
          active: true,
          value: ['Refused', 'Absage', 'Rejected'],
        },
      ],
    });

    totalAssignments.set(count);
    rejected.set(rejectedCount);
    rejectedRate.set(count !== 0 ? (count - rejectedCount) / count : 0);
    loaded = true;
  });
</script>

<div>
  <h3 class="text-base font-semibold leading-6 text-primary">
    Deal statistics
  </h3>

  <dl class="grid-cols-1 gap-5 mt-5 tn-grid sm:grid-cols-3">
    <div
      class="px-4 py-5 overflow-hidden rounded-lg shadow bg-primary-800 sm:p-6"
    >
      <dt class="text-sm font-medium text-gray-500 truncate">
        Total Candidates
      </dt>
      <dd class="mt-1 text-3xl font-semibold tracking-tight text-primary">
        {#if loaded}
          {Math.ceil($totalAssignments)}
        {:else}
          <Skeleton class="h-[40px] w-[100px] rounded-full" />
        {/if}
      </dd>
    </div>
    <div
      class="px-4 py-5 overflow-hidden rounded-lg shadow bg-primary-800 sm:p-6"
    >
      <dt class="text-sm font-medium text-gray-500 truncate">Rejected</dt>
      <dd class="mt-1 text-3xl font-semibold tracking-tight text-primary">
        {#if loaded}
          {Math.ceil($rejected)}
        {:else}
          <Skeleton class="h-[40px] w-[100px] rounded-full" />
        {/if}
      </dd>
    </div>
    <div
      class="px-4 py-5 overflow-hidden rounded-lg shadow bg-primary-800 sm:p-6"
    >
      <dt class="text-sm font-medium text-gray-500 truncate">
        Engagement Rate
      </dt>
      <dd class="mt-1 text-3xl font-semibold tracking-tight text-primary">
        {#if loaded}
          {Math.ceil($rejectedRate * 10000) / 100}%
        {:else}
          <Skeleton class="h-[40px] w-[100px] rounded-full" />
        {/if}
      </dd>
    </div>
  </dl>
</div>
