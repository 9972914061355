<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { createEventDispatcher, onMount } from 'svelte';
  import Filters from '$lib/app/list/filters.svelte';
  import ItemsList from '$lib/app/list/items-list.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import * as Tabs from '$lib/components/ui/tabs';
  import { saveActivity, saveFilter } from '$lib/api/mutations';
  import { ActivityType } from '../../../../drizzle/schema';
  import { getItems } from '$lib/api/queries';
  import { Loader } from 'lucide-svelte';
  import { cn } from '$lib/utils';

  const dispatch = createEventDispatcher();

  export let deal;
  let filter;

  let selectedItems = [];
  let filters = [];
  let search = '';
  let fulltextsearch = false;

  let itemsPerPage = 50;
  let from = 0;
  let to = itemsPerPage;

  let alreadyAdded = [];

  onMount(() => {
    loadFilter();

    if (deal && deal.id) {
      getItems({
        collection: 'activities',
        from: 0,
        to: 1000,
        filters: [
          {
            field: 'deal',
            operator: 'eq',
            value: deal.id,
            active: true,
          },
        ],
      }).then(result => {
        alreadyAdded = result.data.map(a => a.candidate).filter(c => c);
      });
    }
  });

  $: if (deal) {
    loadFilter();
  }

  const loadFilter = async () => {
    if (deal && deal.id) {
      const result = await getItems({
        collection: 'filters',
        from: 0,
        to: 1,
        filters: [
          {
            field: 'dealId',
            operator: 'eq',
            value: deal?.id,
            active: true,
          },
        ],
      });

      if (result.data.length > 0) {
        filter = result.data[0];
        filters = filter.filter;
        search = filter.search;
        fulltextsearch = filter.fulltextsearch;
      } else {
        search = '';
        filters = [];
        fulltextsearch = false;
        filter = {
          dealId: deal?.id,
          name: `Flow Filter ${deal?.name}`,
          module: 'contacts',
          type: 'system',
          search,
          filters,
          fulltextsearch,
        };
      }
    }
  };

  const onAddToLongList = () => {
    selectedItems.forEach(async item => {
      console.log('Adding to long list', item);

      const activity = {
        name: item.name,
        accountObj: deal.customerObj,
        dealObj: {
          id: deal.id,
          name: deal.name,
        },
        candidateObj: {
          id: item.id,
          name: item.name,
        },

        status: 'Longlist',
        type: ActivityType.Enum.Assignment,
      };

      await saveActivity(activity);
    });
    selectedItems = [];
  };

  let savingFilter = false;

  const onSaveFilter = async () => {
    if (!deal || !deal.id) {
      return;
    }
    savingFilter = true;
    try {
      const filterToSave = {
        ...filter,
        search,
        filter: filters,
        fulltextsearch,
        name: `Flow Filter ${deal?.name}`,
      };
      console.log('Save Filter: ', filterToSave);
      const result = await saveFilter(filterToSave);

      if (result) {
        filter = result;
        console.log('Filter saved', filter);
      }
    } catch (error) {
      console.error('Error saving filter', error);
    } finally {
      savingFilter = false;
    }
  };
</script>

<Tabs.Root value="targeting">
  <Tabs.List>
    <Tabs.Trigger
      value="targeting"
      on:click={() => dispatch('current', undefined)}>Targeting</Tabs.Trigger
    >
    <Tabs.Trigger
      value="selection"
      on:click={() => dispatch('current', undefined)}
    >
      Selection
      <Badge variant="secondary" size="sm" class="ml-2"
        >{selectedItems.length}</Badge
      >
    </Tabs.Trigger>
    <!--Tabs.Trigger value="long-list">Long List</Tabs.Trigger>
    <Tabs.Trigger value="short-list">Short List</Tabs.Trigger>
    <Tabs.Trigger value="rejected">Rejected</Tabs.Trigger-->
  </Tabs.List>
  <Tabs.Content value="targeting">
    <div class="flex justify-between">
      <div class="max-w-[250px] min-w-[250px]">
        <ItemsList
          disabledIds={alreadyAdded}
          module={'contacts'}
          selected={selectedItems}
          searchTerm={search}
          bind:fulltextsearch
          sort={undefined}
          bind:filters
          count={0}
          withFilters={false}
          avatarAsCheckbox={true}
          on:goto={e => {
            const page = e.detail;
            from = (page - 1) * itemsPerPage;
            to = page * itemsPerPage;
          }}
          on:next={() => {
            from += itemsPerPage;
            to += itemsPerPage;
          }}
          on:previous={() => {
            from -= itemsPerPage;
            to -= itemsPerPage;
          }}
          on:search={e => (search = e.detail)}
          on:sort={e => console.log(e.detail)}
          on:select={e => {
            selectedItems.find(i => i.id === e.detail.id)
              ? (selectedItems = selectedItems.filter(
                  i => i.id !== e.detail.id,
                ))
              : (selectedItems = [...selectedItems, e.detail]);
          }}
          on:current={e => {
            dispatch('current', e.detail);
          }}
        />
      </div>

      <div class="w-full h-full max-w-xl overflow-scroll -mt-14 min-w-xl">
        <Button
          variant="default"
          size="sm"
          class="ml-2 "
          on:click={onSaveFilter}
        >
          Save Filter
          <Loader
            class={cn(
              `ml-2 size-4 animate-spin hidden`,
              savingFilter && 'block',
            )}
          />
        </Button>

        <div class={cn('mt-2', filter?.id && 'bg-primary-100')}>
          <Filters
            {search}
            {filters}
            {fulltextsearch}
            autoapply={true}
            withGraphs={false}
            collection={'contacts'}
            on:filters={event => {
              filters = event.detail;
              onSaveFilter();
            }}
          />
        </div>
      </div>
      <div class="flex-1"></div>
    </div>
  </Tabs.Content>
  <Tabs.Content value="selection">
    <div class="flex">
      <div class="max-w-[250px] min-w-[250px]">
        <ItemsList
          count={0}
          module={'contacts'}
          selected={selectedItems}
          sort={undefined}
          avatarAsCheckbox={true}
          filters={[
            {
              field: 'id',
              operator: 'in',
              active: true,
              value: (selectedItems || []).map(i => i.id),
            },
          ]}
          from={0}
          to={10}
          withFilters={false}
          on:goto={e => console.log(e.detail)}
          on:next={() => console.log('next')}
          on:previous={() => console.log('previous')}
          on:search={e => console.log(e.detail)}
          on:sort={e => console.log(e.detail)}
          on:select={e => {
            selectedItems.find(i => i.id === e.detail.id)
              ? (selectedItems = selectedItems.filter(
                  i => i.id !== e.detail.id,
                ))
              : (selectedItems = [...selectedItems, e.detail]);
          }}
          on:current={e => {
            dispatch('current', e.detail);
          }}
        />
      </div>
      <div class="m-2 -mt-14">
        <Button
          variant="default"
          size="sm"
          disabled={!selectedItems?.length}
          on:click={() => onAddToLongList()}>Add to Long List</Button
        >
      </div>
    </div>
  </Tabs.Content>
</Tabs.Root>
