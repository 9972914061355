<script lang="ts">
  import { Button as ButtonPrimitive } from 'bits-ui';
  import { cn } from '$lib/utils';
  import { buttonVariants, type Props, type Events } from '.';
  import * as Tooltip from '$lib/components/ui/tooltip/index.js';

  type $$Props = Props & {
    disabled?: boolean;
    tooltip?: string;
  };
  type $$Events = Events;

  let className: $$Props['class'] = undefined;
  export let variant: $$Props['variant'] = 'default';
  export let size: $$Props['size'] = 'default';
  export let builders: $$Props['builders'] = [];
  export let tooltip: $$Props['tooltip'] = undefined;
  export let disabled: $$Props['disabled'] = false;
  export { className as class };
</script>

{#if !tooltip}
  <ButtonPrimitive.Root
    {builders}
    {disabled}
    class={cn(buttonVariants({ variant, size, className }))}
    on:click
    on:keydown
    type="button"
    {...$$restProps}
  >
    <slot />
  </ButtonPrimitive.Root>
{:else}
  <Tooltip.Root openDelay={100}>
    <Tooltip.Trigger asChild let:builder={tooltipBuilder}>
      <ButtonPrimitive.Root
        builders={[tooltipBuilder, ...builders]}
        class={cn(buttonVariants({ variant, size, className }))}
        on:click
        on:keydown
        type="button"
        {...$$restProps}
      >
        <slot />
      </ButtonPrimitive.Root>
    </Tooltip.Trigger>
    <Tooltip.Content class="text-nowrap">
      {tooltip}
    </Tooltip.Content>
  </Tooltip.Root>
{/if}
