<script lang="ts">
  import { generateUUID } from '$lib/utils/browser-utils';
  import PageWithTitle from '$lib/ui/page-with-title.svelte';
  import { type DndEvent, dndzone, SOURCES, TRIGGERS } from 'svelte-dnd-action';
  import { flip } from 'svelte/animate';
  import { slide } from 'svelte/transition';
  import {
    insertInvoiceSchema,
    type Invoice,
  } from '../../../../drizzle/schema';
  import { tick } from 'svelte';
  import { Copy, Lock, PlusIcon, Trash } from 'lucide-svelte';
  import * as Card from '$lib/components/ui/card';
  import { Badge } from '$lib/components/ui/badge/index';
  import { Button } from '$lib/components/ui/button/index';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { createItem, finalizeInvoice, saveInvoice } from '$lib/api/mutations';
  import { appStore } from '$lib/app-store';
  import { currencyFormatter } from '$lib/app/utils';
  import HantaInputSelect from '$lib/widgets/input/hanta-input-select.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import HantaInputCurrency from '$lib/widgets/input/hanta-input-currency.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputTextArea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import * as Avatar from '$lib/components/ui/avatar/index.js';
  import { getItems } from '$lib/api/queries';
  import * as Form from '$lib/components/ui/form';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import Files from '$lib/components/hanta/files/files.svelte';
  import DocxTemplates from '$lib/widgets/docx-template/docx-templates.svelte';
  import * as AlertDialog from '$lib/components/ui/alert-dialog/index.js';
  import { get } from 'svelte/store';
  import InvoiceRecipientPopover from './invoice-recipient-popover.svelte';
  import {
    calculatePosition,
    calculateSum,
    createPrintDataFroInvoice,
    updateValuesAndPositions,
  } from '$lib/pages/invoices/invoice-utils';
  import InvoiceChangeStatus from './invoice-change-status.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import AdobeSupabasePreview from '$lib/components/hanta/files/adobe-supabase-preview.svelte';
  import { cn } from '$lib/utils';
  import { authStore } from '$lib/auth-store';
  import { toast } from 'svelte-sonner';
  import { docx2pdf, generateDocx } from '$lib/api/aggregations';
  import PdfIcon from '$lib/components/icons/pdf-icon.svelte';

  type RowType = {
    id?: string;
    text?: string;
    unit?: 'Stunden' | 'pauschal' | 'Stück';
    quantity?: number;
    price?: number;
    tax?: number;
    sum?: number;
  };

  const format: (value: number) => string = currencyFormatter().format;
  const invoiceSchema = insertInvoiceSchema.partial();

  export let flipDurationMs = 300;
  export let item: Invoice = {};
  export let isDragging = false;

  let formId = 0;
  let form = createNewForm(item);
  let { form: formData, enhance, errors } = form;
  let total = tweened(0, { duration: 500, easing: cubicOut });

  function transformDraggedElement(el: HTMLElement, index) {
    const idx = el?.querySelector('#hanta-index');
    if (idx) {
      idx.innerHTML = index + 1 + '.';
    }
  }

  function handleConsider(e) {
    const {
      items: newItems,
      info: { trigger, source },
    } = e.detail;

    if (source === SOURCES.KEYBOARD && trigger === TRIGGERS.DRAG_STOPPED) {
      isDragging = false;
    }

    positions = newItems;
    $formData.positions = positions;
  }

  async function handleFinalize(e: CustomEvent<DndEvent<RowType>>) {
    let { items: newItems } = e.detail;
    isDragging = false;

    positions = newItems;
    $formData.positions = newItems;
  }

  function handleMaybeSelect(id, e) {
    isDragging = true;
  }

  const addPosition = async () => {
    formData.update((data: any) => {
      if (!data.positions) data.positions = [];
      data.positions.push({
        id: generateUUID(),
        text: '',
        unit: 'Stunden',
        quantity: 1,
        price: 0,
        tax: 19,
        sum: 0,
      });
      return data;
    });

    isDragging = false;
    positions = $formData.positions;
  };

  function createNewForm(newItem) {
    return superForm<Invoice>(newItem, {
      resetForm: false,
      SPA: true,
      validators: zodClient(invoiceSchema),
      validationMethod: 'oninput',
      dataType: 'json',
      onSubmit: async () => {
        updateValuesAndPositions($formData);
        try {
          const newInvoice = insertInvoiceSchema.parse($formData);
          await saveInvoice(newInvoice);
          console.log('Saved invoice', newInvoice);
        } catch (e) {
          $errors = e.format();
          console.error('Error saving invoice', e);
        }
      },
    });
  }

  $: if (item) {
    form = createNewForm(JSON.parse(JSON.stringify(item)));
    formData = form.form;
    enhance = form.enhance;
    errors = form.errors;
    formId = (formId + 1) % 10;

    tick().then(() => {
      form.reset({ data: item, keepMessage: false });
      positions = get(formData).positions || [];
    });
  }

  const removePositionAtIdx = idx => {
    positions = positions.filter((_, i) => i !== idx);
    $formData.positions = positions;
  };

  const clonePositionFromIdx = async idx => {
    const clone = JSON.parse(JSON.stringify(positions[idx]));
    clone.id = generateUUID();

    positions = [
      ...positions.slice(0, idx + 1),
      clone,
      ...positions.slice(idx + 1),
    ];

    $formData.positions = positions;
  };

  const loadOptionsAccounts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'accounts',
      from: 0,
      to: 5,
      search: keyword,
      select: 'id,name,logo,address',
    });

    return result.data;
  };

  let positions = [];

  $: hasChanges = $formData && form.isTainted() && $formData.status === 'Draft';
  $: isLocked = $formData.status !== 'Draft';
  $: printData = createPrintDataFroInvoice(item);
  $: sum = calculateSum($formData);
  $: total.set(sum.sum);

  const duplicateInvoice = async () => {
    const duplicatedInvoice = JSON.parse(JSON.stringify($formData));
    delete duplicatedInvoice.id;
    delete duplicatedInvoice.invoiceId;
    delete duplicatedInvoice.fixedOn;
    delete duplicatedInvoice.createdAt;
    delete duplicatedInvoice.paymentDeadline;
    delete duplicatedInvoice.invoiceDate;
    delete duplicatedInvoice.subject;

    duplicatedInvoice.status = 'Draft';
    duplicatedInvoice.customer = duplicatedInvoice.customer?.id;
    duplicatedInvoice.recipient = duplicatedInvoice.recipient?.id;
    duplicatedInvoice.name = `Draft`;

    const data = await createItem('invoices', duplicatedInvoice);
    appStore.select({ id: data[0].id, module: 'invoices' });
    appStore.openPopup(true);
  };

  const templateName = 'invoice.docx';

  const onFinalize = async () => {
    const id = $formData?.id;
    const finalInvoice = await finalizeInvoice(id);
    const finalInvoicePrintData = createPrintDataFroInvoice(finalInvoice);

    const orgId = $authStore?.clerk?.session?.lastActiveOrganizationId;
    const filename = `orgs/${orgId}/invoices/${id}/invoice/invoice`;

    const renderedDoc = {
      ...finalInvoicePrintData,
      meta: {
        orgId,
        templateName,
        templateUrl: '/templates/',
        filename: finalInvoicePrintData?.name,
        destination: filename + '.docx',
      },
    };

    try {
      const { signedUrl } = await generateDocx(renderedDoc);

      console.log({ signedUrl });
      debugger;
      const result = await docx2pdf(signedUrl, filename + '.pdf');
    } catch (error) {
      console.error(error);
      toast.error('Failed to generate document');
      return;
    }
  };

  let openPdf = false;
</script>

{#key formId}
  <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
  <form
    class="w-full h-full overflow-hidden"
    method="POST"
    on:change={() => {
      positions = JSON.parse(JSON.stringify($formData.positions));
    }}
    use:enhance
    on:keydown={e => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    }}
  >
    <PageWithTitle>
      <div
        class="flex flex-row items-center w-full gap-4 text-lg"
        slot="short-card"
      >
        <div class="flex items-center w-full">
          <span class="mr-1 font-normal">Invoice #</span>
          {$formData.invoiceId}
          <Badge
            variant={$formData.status === 'Cancelled'
              ? 'destructive'
              : 'default'}
            class="ml-2 {$formData.status === 'Paid' ? 'bg-green-600' : ''}"
            >{$formData.status}</Badge
          >
        </div>

        <div class="flex flex-row text-xs">
          <div class="flex flex-col w-full text-right">
            <span class="text-lg font-bold">{format($total)}</span>
            <div class="flex flex-row gap-1 text-nowrap text-muted-foreground">
              Net {format(sum.sum - sum.tax)} / VAT {format(sum.tax)}
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full gap-4" slot="card">
        <div
          class="flex flex-col items-start w-full gap-4 p-2 md:p-0 md:items-center md:flex-row"
        >
          <div class="flex items-center w-full">
            <span class="mr-1 font-normal">Invoice #</span>
            {$formData.invoiceId}

            <Badge
              variant="outline"
              class={cn(
                'ml-2',
                $formData.status === 'Overdue' && 'bg-yellow-600 text-white',
                $formData.status === 'Paid' && 'bg-green-600 text-white',
                $formData.status === 'Cancellation invoice' &&
                  'bg-red-600 text-white',
                $formData.status === 'Cancelled' && 'bg-red-600 text-white',
              )}
            >
              {$formData.status ?? ''}</Badge
            >

            {#if $formData.status === 'Cancellation invoice'}
              <Badge variant="destructive" class="ml-2"
                >Cancelled invoice #{$formData.invoiceCancellation}</Badge
              >
            {/if}
          </div>

          <div class="flex flex-row gap-4">
            {#if isLocked && !hasChanges}
              {#if $formData.status === 'Open' || $formData.status === 'Overdue'}
                <InvoiceChangeStatus id={$formData.id} sum={$formData.gross} />
              {/if}

              <HantaInputDate
                {form}
                label="Fixed On"
                name="fixedOn"
                readOnly={isLocked}
              >
                <svelte:fragment slot="icon">
                  <Lock class="size-3" />
                </svelte:fragment>
              </HantaInputDate>
            {/if}

            {#if !isLocked && !hasChanges && $formData.status === 'Draft'}
              <div>
                <AlertDialog.Root>
                  <AlertDialog.Trigger asChild let:builder>
                    <Button builders={[builder]}>
                      <Lock class="mr-2 size-4" />
                      Finalize invoice
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content>
                    <AlertDialog.Header>
                      <AlertDialog.Title
                        >Finalize this invoice?
                      </AlertDialog.Title>
                      <AlertDialog.Description>
                        This will finalize the invoice, ensuring no
                        modifications are possible afterward except for
                        cancellation, thus maintaining the integrity of the
                        invoicing process.
                      </AlertDialog.Description>
                    </AlertDialog.Header>
                    <AlertDialog.Footer>
                      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
                      <AlertDialog.Action on:click={onFinalize}
                        >Finalize now
                      </AlertDialog.Action>
                    </AlertDialog.Footer>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </div>
            {/if}

            {#if hasChanges}
              <div class="flex justify-start w-full gap-2">
                <Form.Button variant="default">Save</Form.Button>
                <Button
                  variant="outline"
                  on:click={() => {
                    form.reset();
                    positions = $formData.positions;
                  }}
                  >Cancel
                </Button>
              </div>
            {/if}

            {#if !hasChanges}
              <div>
                <AlertDialog.Root>
                  <AlertDialog.Trigger asChild let:builder>
                    <Button
                      builders={[builder]}
                      variant="outline"
                      tooltip="Duplicate invoice"
                    >
                      <Copy class="size-4" />
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content>
                    <AlertDialog.Header>
                      <AlertDialog.Title class="flex items-center gap-2">
                        <Copy class="size-4" />
                        Duplicate invoice?
                      </AlertDialog.Title>
                      <AlertDialog.Description>
                        This will create a new invoice based on the current one,
                        allowing you to make modifications to the new invoice
                        without affecting the original.
                      </AlertDialog.Description>
                    </AlertDialog.Header>
                    <AlertDialog.Footer>
                      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
                      <AlertDialog.Action on:click={() => duplicateInvoice()}
                        >Continue
                      </AlertDialog.Action>
                    </AlertDialog.Footer>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </div>
            {/if}
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full gap-4 mt-8" slot="content">
        <div class="flex flex-col gap-4 md:flex-row">
          <Card.Root class="w-full">
            <Card.Header>
              <Card.Title class="relative">
                <div class="w-full">Recipient</div>
                {#if !isLocked}
                  <div class="absolute -top-[50%] -right-2 min-w-0">
                    <InvoiceRecipientPopover {form} />
                  </div>
                {/if}
              </Card.Title>
            </Card.Header>
            <Card.Content class="flex flex-row items-center gap-4">
              {#if $formData.customer}
                <div class="w-full">
                  <div class="text-sm font-bold">
                    {$formData?.customer?.name}
                  </div>
                  {#if $formData.recipient}
                    <div>{$formData?.recipient?.name}</div>
                  {/if}
                  <div>{$formData?.customer?.address?.street}</div>
                  <div>
                    {$formData?.customer?.address?.postalCode}
                    {$formData?.customer?.address?.city}
                  </div>
                  <div>{$formData?.customer?.address?.country}</div>
                </div>
                <Avatar.Root class="rounded-none size-16">
                  <Avatar.Image
                    class="object-scale-down"
                    src={$formData?.customer?.logo}
                  />
                  <Avatar.Fallback>-</Avatar.Fallback>
                </Avatar.Root>
              {:else}
                <HantaInputMultiSelect
                  {form}
                  label="Account"
                  loadOptions={loadOptionsAccounts}
                  multiple={false}
                  name="customer"
                  placeholder="Select account..."
                  readOnly={isLocked}
                >
                  <div
                    class="flex items-center w-full h-full"
                    let:item
                    slot="item"
                  >
                    <Avatar.Root class="size-7">
                      <Avatar.Image
                        class="object-scale-down rounded-none"
                        src={item?.logo}
                      />
                      <Avatar.Fallback>SK</Avatar.Fallback>
                    </Avatar.Root>
                    <div class="ml-2 text-sm truncate">{item?.name}</div>
                  </div>
                  <div
                    class="flex items-center w-full overflow-hidden"
                    let:selection
                    slot="selection"
                  >
                    <Avatar.Root class="size-7">
                      <Avatar.Image
                        class="object-scale-down rounded-none"
                        src={selection?.logo}
                      />
                      <Avatar.Fallback>-</Avatar.Fallback>
                    </Avatar.Root>
                    <div class="w-full ml-2 text-sm truncate">
                      {selection?.name}
                    </div>
                  </div>
                </HantaInputMultiSelect>
              {/if}
            </Card.Content>
          </Card.Root>
          <Card.Root class="w-full">
            <Card.Header>
              <Card.Title class="flex flex-row">
                <div class="w-full">
                  Positions
                  <Badge class="ml-2">{$formData.positions?.length}</Badge>
                </div>
                <div>{format($total)}</div>
              </Card.Title>
            </Card.Header>
            <Card.Content class="flex flex-row gap-4 content-baseline">
              <HantaInputDate
                {form}
                label={isLocked ? 'Date' : undefined}
                name="invoiceDate"
                readOnly={isLocked}
              />

              <div class="w-full text-right">
                <div>Net {format(sum.sum - sum.tax)}</div>
                <span class="font-normal text-nowrap"
                  >VAT {format(sum.tax)}</span
                >
              </div>
            </Card.Content>
          </Card.Root>
        </div>

        <div
          class="flex flex-col gap-4 p-6 mb-6 text-left bg-opacity-50 border border-solid rounded shadow-xs bg-primary-800"
        >
          <div class="flex flex-row items-baseline gap-4">
            {#if $formData.delivery === 'E-Mail'}
              <div class="flex flex-row items-baseline w-full gap-2">
                <span class="text-muted-foreground">Subject: </span>
                <HantaInputText
                  class="w-full p-0"
                  {form}
                  name="subject"
                  placeholder="Enter subject..."
                  variant="hanta"
                />
              </div>
            {:else}
              <div
                class="w-full text-lg font-semibold leading-none tracking-tight"
              >
                Introduction
              </div>
            {/if}

            <HantaInputSelect
              {form}
              readOnly={isLocked}
              name="delivery"
              options={[
                { label: 'Letter', value: 'Letter' },
                { label: 'E-Mail', value: 'E-Mail' },
              ]}
            />
          </div>
          <HantaInputTextArea
            {form}
            name="introduction"
            placeholder="Please formulate the cover letter here..."
            readOnly={isLocked}
          />
        </div>

        <div class="flex flex-col gap-2 overflow-scroll text-left">
          <div class="flex flex-row w-full gap-2 px-3 py-2 font-bold">
            <div class="border-r border-solid min-w-4"></div>
            <div class="w-full border-r">Description</div>
            <div class="flex flex-row gap-2">
              <div class="border-r border-solid min-w-28 shrink-0 grow-0">
                Unit
              </div>
              <div class="border-r border-solid min-w-12 shrink-0 grow-0">
                Qty.
              </div>
              <div class="border-r border-solid min-w-16 shrink-0 grow-0">
                Price
              </div>
              <div class="border-r border-solid min-w-20 shrink-0 grow-0">
                Tax
              </div>
              <div
                class="font-bold text-right border-r min-w-20 shrink-0 grow-0"
              >
                Sum
              </div>
            </div>
          </div>

          <section
            class="w-full section flex flex-col gap-1.5"
            on:consider={handleConsider}
            on:finalize={handleFinalize}
            use:dndzone={{
              dragDisabled: positions?.length === 0 || isLocked,
              items: positions,
              flipDurationMs,
              transformDraggedElement,
            }}
          >
            {#if positions?.length === 0}
              <div
                class="flex flex-row w-full p-4 gap-2 bg-primary-800 rounded border border-solid border-primary/20 shadow content-stretch !leading-normal"
              >
                <div class="w-full text-center">
                  {#if $errors?.positions?._errors?.length > 0}
                    <span class="text-red-500"
                      >{$errors?.positions?._errors?.join(', ')}</span
                    >
                  {:else}
                    No positions added yet
                  {/if}
                </div>
              </div>
            {/if}
            {#each positions || [] as position, idx (position.id || position.text)}
              <!-- svelte-ignore a11y-no-static-element-interactions -->
              <div
                on:mousedown={e => handleMaybeSelect(position.id, e)}
                on:keydown={e => handleMaybeSelect(position.id, e)}
                animate:flip={{ duration: flipDurationMs }}
                class="hover:bg-muted group w-full p-0 bg-primary-800 rounded border border-solid border-primary/20 shadow !leading-normal"
              >
                <div
                  class="relative flex flex-row w-full gap-2 px-3 py-2 content-stretch"
                >
                  {#key idx}
                    <div id="hanta-index" class="font-bold min-w-4">
                      {idx + 1}.
                    </div>
                    <div class="w-full !leading-1">
                      <HantaInputTextArea
                        readOnly={isLocked}
                        {form}
                        rows={6}
                        name="positions[{idx}].text"
                        placeholder="Enter position description..."
                      />
                      {#if $errors?.positions && $errors?.positions[idx]?.text}
                        <span class="text-red-500"
                          >{$errors?.positions &&
                            $errors?.positions[idx]?.text}</span
                        >
                      {/if}
                    </div>
                    <div class="flex flex-row items-baseline self-start gap-2">
                      <div class="w-28 shrink-0 grow-0">
                        <HantaSelect
                          readOnly={isLocked}
                          searchable={false}
                          bind:value={$formData.positions[idx].unit}
                          options={[
                            { label: 'Stunden', value: 'Stunden' },
                            { label: 'pauschal', value: 'pauschal' },
                            { label: 'Stück', value: 'Stück' },
                          ]}
                        />
                      </div>
                      <div class="w-12 shrink-0 grow-0">
                        {#if $formData.positions[idx].unit !== 'pauschal'}
                          <HantaInputNumber
                            readonly={isLocked}
                            asFloat={true}
                            {form}
                            name="positions[{idx}].quantity"
                          />
                        {/if}
                      </div>
                      <div class="w-16 shrink-0 grow-0">
                        <HantaInputCurrency
                          {form}
                          name="positions[{idx}].price"
                          readOnly={isLocked}
                        />
                      </div>
                      <div class="w-20 shrink-0 grow-0">
                        <HantaInputSelect
                          readOnly={isLocked}
                          asInt={true}
                          {form}
                          name="positions[{idx}].tax"
                          options={[
                            { label: '0%', value: '0' },
                            { label: '7%', value: '7' },
                            { label: '19%', value: '19' },
                          ]}
                        />
                      </div>
                      <div
                        class="w-20 text-sm font-medium text-right shrink-0 grow-0"
                      >
                        {format(
                          calculatePosition($formData.positions[idx]).net,
                        )}
                      </div>
                    </div>
                    <div
                      class="absolute top-0 right-0 flex flex-col items-center w-4 h-full gap-4 p-4 font-bold transition-opacity bg-primary-800 shadow opacity-0 shrink-0 grow-0 {!isLocked
                        ? 'group-hover:opacity-100'
                        : ''} bg-opacity-90"
                    >
                      <button
                        on:click={e => {
                          e.preventDefault();
                          clonePositionFromIdx(idx);
                        }}
                        class="text-gray-400 hover:text-muted-foreground"
                      >
                        <Copy class="size-4" />
                      </button>
                      <button
                        on:click={e => {
                          e.preventDefault();
                          removePositionAtIdx(idx);
                        }}
                        class="text-red-400 hover:text-muted-foreground"
                      >
                        <Trash class="size-4" />
                      </button>
                    </div>
                  {/key}
                </div>
              </div>
            {/each}
          </section>
          {#if !isLocked}
            <div class="flex flex-row mt-2">
              <Button on:click={() => addPosition()}>
                <PlusIcon class="mr-2 size-4" />
                Add
              </Button>
              <div class="flex flex-col items-end justify-center w-full">
                <div>Net {format(sum.sum - sum.tax)}</div>
                <span class="font-normal text-nowrap"
                  >VAT {format(sum.tax)}</span
                >
                <div class="text-lg font-bold underline">
                  {format(sum.sum)}
                </div>
                <!-- {df.format(new Date($formData.createdAt))} -->
              </div>
            </div>
          {/if}
        </div>

        <div
          class="p-6 mt-6 text-left bg-opacity-50 border border-solid rounded shadow-xs bg-primary-800"
        >
          <HantaInputTextArea
            {form}
            name="conclusion"
            placeholder="Enter position description..."
            readOnly={isLocked}
          />
        </div>
      </div>

      <svelte:fragment slot="sidebar">
        <div class="flex flex-col w-full gap-4 p-6 mb-20">
          {#if isLocked}
            <div class="flex items-center w-full text-xl">
              {$formData.invoiceId}

              <Badge
                variant={$formData.status === 'Cancelled'
                  ? 'destructive'
                  : 'default'}
                class="ml-2 {$formData.status === 'Paid' ? 'bg-green-600' : ''}"
                >{$formData.status}</Badge
              >
              <div class="ml-2 text-xs">
                {$formData?.fixedOn?.substring(0, 10)}
              </div>
            </div>
            {#if $formData.status !== 'Draft' && isLocked}
              <div>
                <Button
                  variant="outline"
                  class="px-6 py-8"
                  on:click={() => (openPdf = true)}
                >
                  <PdfIcon />
                  <div class="ml-2">
                    {$formData.name}
                  </div>
                </Button>
                {#if openPdf}
                  <div class="absolute">
                    <AdobeSupabasePreview
                      folder={`invoices/${item?.id}/invoice`}
                      filename={'invoice.pdf'}
                      outputfilename={`${$formData.name}.pdf`}
                      mode={'LIGHT_BOX'}
                      onClose={() => (openPdf = false)}
                    />
                  </div>
                {/if}
              </div>
            {/if}
            <div class="flex items-center px-2 space-x-2 border">
              <Avatar.Root class="rounded-none size-16">
                <Avatar.Image
                  class="object-scale-down"
                  src={$formData?.customer?.logo}
                />
                <Avatar.Fallback>-</Avatar.Fallback>
              </Avatar.Root>
              <h2 class="text-lg">{$formData?.customer?.name}</h2>
            </div>

            <div class="space-y-2">
              <div class="text-muted-foreground">Net</div>
              <div class="font-sans text-xl">
                {new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(item.net)}
              </div>

              <div class="text-muted-foreground">Gross</div>
              <div class="font-sans text-xl">
                {new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(item.gross)}
              </div>
            </div>
          {/if}

          <DocxTemplates
            class="px-4 py-2"
            qrCodeData={printData.qrCode}
            data={printData}
            label="Invoice"
            {templateName}
            customFilename={printData.name}
          />

          <HantaInputDate
            {form}
            label="Payment deadline"
            name="paymentDeadline"
            readOnly={isLocked}
          />

          <HantaInputLovSelect
            readonly={isLocked}
            {form}
            placeholder="Select tags..."
            name="tags"
            type="Rechnung - Tags"
            label="Tags"
            addable={true}
            multiple={true}
          />

          <Files compact={true} folder={`invoices/${item?.id}`} />
          <!--
          <pre>{JSON.stringify($formData, null, 4)}</pre>
          <pre>{JSON.stringify($errors, null, 4)}</pre>
          -->
        </div>

        {#if hasChanges}
          <div
            class="absolute bottom-0 z-20 w-full pb-0 mb-0 border-t border-solid shadow border-t-primary-200/30 bg-muted"
            out:slide={{ duration: 200 }}
            in:slide={{ duration: 200 }}
          >
            <!--
            <div class="flex flex-col gap-2 px-4">
              {#if $errors}
                {#each Object.keys($errors) as error}
                  <div class="text-sm text-red-500">
                    {error}: {JSON.stringify($errors[error])}
                  </div>
                {/each}
              {/if}
            </div>
          -->
            <div class="flex items-center justify-start w-full h-20 gap-2 px-4">
              <Form.Button variant="default">Save</Form.Button>
              <Button
                variant="outline"
                on:click={() => {
                  form.reset();
                  positions = $formData.positions;
                }}
                >Cancel
              </Button>
            </div>
          </div>
        {/if}
      </svelte:fragment>
    </PageWithTitle>
  </form>
{/key}
