<script lang="ts">
  import Upload from './upload.svelte';
  import ListFiles from './list-files.svelte';
  import { cn } from '$lib/utils';

  export let title = 'Files';
  export let noFilesFoundLabel = 'No files found';

  export let folder;
  export let compact = false;

  export let singleFileMode = false;
  export let singleFileName = '';

  export let onUpload = () => {};

  let refresh = 0;
</script>

<div>
  <div class="text-base">{title ?? ''}</div>

  <div class={cn(singleFileMode && 'flex items-center space-x-2')}>
    <div class="flex-1">
      <Upload bind:refresh {folder} {compact} {singleFileName} {onUpload} />
    </div>

    <ListFiles
      {folder}
      bind:refresh
      {noFilesFoundLabel}
      {singleFileMode}
      {onUpload}
    />
  </div>
</div>
