<script lang="ts">
  import { confetti } from '@neoconfetti/svelte';
  import { dragscroll } from '@svelte-put/dragscroll';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { cn } from '$lib/utils';
  import { CheckIcon } from 'lucide-svelte';
  import { tick } from 'svelte';
  import { Slider } from '$lib/components/ui/slider/index.js';
  import { fly } from 'svelte/transition';
  import { Button } from '$lib/components/ui/button/index';
  import X from 'lucide-svelte/icons/x';

  export let phases = [];

  $: notFinalPhases = phases.length > 2 ? phases.slice(0, -2) : [];
  $: finalPhases = phases.length > 2 ? phases.slice(-2) : [];

  export let currentPhase;

  let localPhase;
  let showConfetti = false;
  let showPreview = false;
  let currentPhaseIndex = -1;

  $: previewPhaseIndex = phases.indexOf(localPhase || currentPhase);
  $: currentPhaseIndex = phases.indexOf(currentPhase);

  let container: HTMLElement[] = [];

  $: container?.length > 0 &&
    container[
      Math.min(Math.max(currentPhaseIndex, 0), container.length - 1)
    ].scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
    });

  let valueChangeTimeout = 0;
  let previewChangeTimeout = 0;

  const onSliderValueChanged = (value: number) => {
    showPreview = true;

    localPhase = phases[value];
    if (valueChangeTimeout) clearTimeout(valueChangeTimeout);
    valueChangeTimeout = setTimeout(() => {
      tick().then(() => {
        currentPhase = phases[value];
        if (currentPhase === 'Contract!') {
          showConfetti = true;
        }
      });
    }, 300);

    if (previewChangeTimeout) clearTimeout(previewChangeTimeout);
    previewChangeTimeout = setTimeout(() => {
      showPreview = false;
    }, 500);
  };

  const addZero = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };
</script>

<div class="relative flex flex-col w-full h-full gap-1">
  <span
    class="flex flex-row items-center gap-2 text-xs font-medium leading-none"
    ><span class="text-gray-500">Select Phase</span>
    {#key localPhase}
      <span
        class:opacity-0={!showPreview}
        in:fly|local={{ x: 20, duration: 250 }}
        class="text-sm font-bold transition-opacity">{localPhase}</span
      >
    {/key}
  </span>
  <div class="relative flex flex-row w-full gap-2 overflow-hidden">
    <nav
      aria-label="Progress"
      class="relative w-full overflow-hidden transition-opacity"
      class:opacity-50={notFinalPhases.length - 1 <
        phases.indexOf(currentPhase)}
    >
      <ol
        class="w-full overflow-scroll border divide-y divide-gray-300 rounded-md focus:scroll-auto border-primary-300 md:flex md:divide-y-0 viewport"
        role="list"
        use:dragscroll={{ axis: 'x' }}
      >
        {#each notFinalPhases as phase, idx (phase)}
          <li
            bind:this={container[idx]}
            class="relative md:flex md:flex-1"
            class:sticky={idx === notFinalPhases.length - 1}
          >
            <button
              class="border-none padding-0 margin-0"
              on:click|preventDefault={e => {
                if (phase === 'Contract!' && currentPhase !== 'Contract!') {
                  showConfetti = true;
                } else {
                  showConfetti = false;
                }
                currentPhase = phase;
              }}
              on:mouseover={() => (localPhase = phase)}
              on:mouseleave={() => (localPhase = currentPhase)}
              on:focus={() => (localPhase = phase)}
            >
              {#if previewPhaseIndex > idx}
                <span class="flex items-center w-full group">
                  <span class="flex items-center px-6 py-4 text-sm font-medium">
                    <span
                      class="flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-full bg-muted-foreground group-hover:bg-indigo-800"
                    >
                      <CheckIcon
                        class="w-4 h-4 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span
                      class="ml-4 font-medium text-primary text-ms text-nowrap"
                      >{phase}</span
                    >
                  </span>
                </span>
              {:else if previewPhaseIndex === idx}
                <span
                  class="flex items-center px-6 py-4 text-sm font-medium"
                  aria-current="step"
                >
                  <span
                    class="flex items-center justify-center flex-shrink-0 w-8 h-8 border-2 border-indigo-600 rounded-full"
                  >
                    <span class="text-indigo-600">{addZero(idx + 1)}</span>
                  </span>
                  <span
                    class="ml-4 font-medium text-indigo-600 text-ms text-nowrap"
                    >{phase}</span
                  >
                </span>
              {:else}
                <span class="flex items-center group">
                  <span class="flex items-center px-6 py-4 text-sm font-medium">
                    <span
                      class="flex items-center justify-center flex-shrink-0 w-8 h-8 border-2 rounded-full border-muted-foreground group-hover:border-primary-400"
                    >
                      <span class="text-gray-500 group-hover:text-primary"
                        >{addZero(idx + 1)}</span
                      >
                    </span>
                    <span
                      class="ml-4 font-medium text-gray-500 text-ms group-hover:text-primary text-nowrap"
                      >{phase}</span
                    >
                  </span>
                </span>
              {/if}

              {#if idx !== notFinalPhases.length - 1}
                <div
                  class="absolute top-0 right-0 hidden w-5 h-full md:block"
                  aria-hidden="true"
                >
                  <svg
                    class="w-full h-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vector-effect="non-scaling-stroke"
                      stroke="currentcolor"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              {/if}
            </button>
          </li>
        {/each}
      </ol>
    </nav>

    <span
      class="flex flex-row justify-center border divide-y rounded border-primary-300"
    >
      {#each finalPhases as phase, idx (phase)}
        {@const selected = idx === finalPhases.indexOf(currentPhase)}

        <button
          class="px-6 py-4 border-none group"
          class:opacity-100={idx === finalPhases.indexOf(currentPhase)}
          class:opacity-50={finalPhases.indexOf(currentPhase) > -1 &&
            idx !== finalPhases.indexOf(currentPhase)}
          class:bg-green-50={phase === finalPhases[0] &&
            idx === finalPhases.indexOf(currentPhase)}
          class:bg-red-50={phase === finalPhases[1] &&
            idx === finalPhases.indexOf(currentPhase)}
          class:hover:bg-green-50={idx === 0}
          class:hover:bg-red-50={idx === 1}
          on:click|preventDefault={() => {
            currentPhase = localPhase = phase;
            showConfetti = phases.indexOf(phase) === phases.length - 2;
          }}
        >
          <span class="flex items-center w-full">
            <span class="flex items-center text-sm">
              {#if idx === 0}
                <span
                  class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full transition-all {selected
                    ? 'bg-green-600 text-white'
                    : finalPhases.indexOf(currentPhase) === -1
                      ? 'border-2 border-green-600 text-green-600'
                      : 'border-2 border-muted-foreground text-muted-foreground'}"
                >
                  <CheckIcon aria-hidden="true" class="w-4 h-4" />
                </span>
              {:else}
                <span
                  class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full {selected
                    ? 'bg-red-600 text-white'
                    : finalPhases.indexOf(currentPhase) === -1
                      ? 'border-2 border-red-600 text-red-600'
                      : 'border-2 border-muted-foreground text-muted-foreground'}"
                >
                  <X aria-hidden="true" class="w-4 h-4" />
                </span>
              {/if}
              <span class="ml-4 font-medium text-primary text-ms text-nowrap"
                >{phase}</span
              >
            </span>
          </span>
        </button>

        {#if idx < finalPhases.length - 1}
          <span class="h-full border-l border-l-primary/30"></span>
        {/if}
      {/each}
    </span>
  </div>

  {#if notFinalPhases.length > 0}
    <div class="flex flex-col items-center justify-center w-full gap-2 mx-1">
      <!--
      {#key localPhase}
        <span
          class:opacity-0={!showPreview}
          in:fly={{ x: -20, duration: 250 }}
          class="text-sm font-thin transition-opacity"
          ><span class="mr-2 text-sm font-medium"
            >{addZero(previewPhaseIndex + 1)}.</span
          >
          {localPhase}</span
        >
      {/key}
-->
      <Slider
        value={[currentPhaseIndex]}
        min={0}
        max={phases.length - 1}
        step={1}
        class="w-full my-6"
        onValueChange={([value]) => onSliderValueChanged(value)}
      />

      <!--
      <div class="flex flex-row justify-center mt-4">
        <button class="px-6 py-4 border rounded-l rounded-r-none border-primary/20 ">
          <span class="flex items-center w-full group">
            <span class="flex items-center text-sm font-medium">
              <span
                class="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-green-600 rounded-full group-hover:bg-green-800"
              >
                <CheckIcon class="w-4 h-4 text-white" aria-hidden="true" />
              </span>
              <span class="ml-4 font-medium text-primary text-ms text-nowrap"
              >Contract!</span
              >
            </span>
          </span>
        </button>


        <button class="px-6 py-4 border border-l-0 rounded-r border-primary/20">
          <span class="flex items-center w-full group">
            <span class="flex items-center text-sm font-medium">
              <span
                class="flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-full bg-primary-600 group-hover:bg-primary-800"
              >
                <X class="w-4 h-4 text-white" aria-hidden="true" />
              </span>
              <span class="ml-4 font-medium text-primary text-ms text-nowrap"
              >Refused</span
              >
            </span>
          </span>
        </button>

      </div>
      -->
    </div>
  {/if}
</div>
<div class="fixed w-0 h-0 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
  {#if showConfetti}
    <div use:confetti={{ particleCount: 100, force: 0.3 }} />
  {/if}
</div>

<style>
  .viewport {
    position: relative;
    box-sizing: border-box;

    /* hide scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
</style>
