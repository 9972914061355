<script lang="ts">
  import * as AlertDialog from '$lib/components/ui/alert-dialog';
  import { Button } from '$lib/components/ui/button';
  import { X } from 'lucide-svelte';
  import AdobePreview from './adobe-preview.svelte';

  export let file;
  export let open;
  export let previewUrl;
  export let officeDocument = false;
  const officeRootUrl =
    'https://view.officeapps.live.com/op/view.aspx?&wdAccPdf=1&wdEmbedFS=1&src=';
</script>

<AlertDialog.Root bind:open>
  <AlertDialog.Content class="h-screen max-w-5xl ">
    <AlertDialog.Header>
      <AlertDialog.Title>
        <div class="flex items-center justify-between w-full space-x-1">
          <div>{file.name}</div>
          <Button variant="ghost" on:click={() => (open = false)}>
            <X />
          </Button>
        </div>
      </AlertDialog.Title>
    </AlertDialog.Header>
    {#if open}
      {#if officeDocument}
        <!-- svelte-ignore a11y-missing-attribute 
      -->
        <iframe
          src={`${officeRootUrl}${previewUrl}`}
          frameborder="0"
          class="w-full h-[calc(100svh-100px)]"
          >This is an embedded <a target="_blank" href="http://office.com"
            >Microsoft Office</a
          >
          document, powered by
          <a target="_blank" href="http://office.com/webapps">Office Online</a
          >.</iframe
        >
      {:else if import.meta.env.MODE === 'plugin'}
        <iframe
          src={previewUrl}
          title="File Preview"
          frameborder="0"
          class="w-full h-[calc(100svh-100px)]"
        >
        </iframe>
      {:else if file.metadata.mimetype === 'TODO_application/pdf'}
        <AdobePreview file={previewUrl} name={'invoice.pdf'} />
      {:else}
        <embed
          src={previewUrl}
          type={file.metadata.mimetype}
          class="w-full h-[calc(100svh-100px)]"
        />
      {/if}
    {/if}

    <AlertDialog.Footer></AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
