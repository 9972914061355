<script lang="ts">
  import { scaleBand, scaleLinear } from 'd3-scale';
  import { afterUpdate } from 'svelte';

  let chart;
  export let data;
  export let chartAsSvg;

  const width = 800;
  const height = 600;

  const margin = { top: 20, right: 20, bottom: 20, left: 260 };
  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  $: xDomain = data.map(d => d.name);
  $: yDomain = data.map(d => +d.count);

  $: yScale = scaleBand().domain(xDomain).range([0, innerHeight]).padding(0.1);
  $: xScale = scaleLinear()
    .domain([0, Math.max.apply(null, yDomain)])
    .range([0, innerWidth]);

  afterUpdate(() => {
    chartAsSvg = chart.outerHTML;
  });
</script>

<svg {width} {height} bind:this={chart}>
  <g transform={`translate(${margin.left},${margin.top})`}>
    {#each xScale.ticks() as tickValue}
      <g transform={`translate(${xScale(tickValue)},0)`}>
        <line y2={innerHeight} stroke="gray" />
        <text text-anchor="middle" dy=".71em" y={innerHeight + 3}>
          {tickValue}
        </text>
      </g>
    {/each}
    {#each data as d}
      <text
        text-anchor="end"
        x="-10"
        dy=".32em"
        y={yScale(d.name) + yScale.bandwidth() / 2}
      >
        {d.name ?? 'n/a'}
      </text>
      <rect
        x="0"
        y={yScale(d.name)}
        width={xScale(d.count)}
        height={yScale.bandwidth()}
      />
    {/each}
  </g>
</svg>
