<script lang="ts">
  import Report from '../report/report.svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import { EllipsisVertical } from 'lucide-svelte';
  import { findAssignments, getLov } from '$lib/api/queries';
  import { appStore } from '$lib/app-store';
  import * as Accordion from '$lib/components/ui/accordion';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { Skeleton } from '$lib/components/ui/skeleton';
  import * as Table from '$lib/components/ui/table/index.js';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';
  import { Button } from '$lib/components/ui/button/index.js';

  import { createQuery } from '@tanstack/svelte-query';
  import { onMount } from 'svelte';
  import { derived, writable } from 'svelte/store';
  export let deal;

  const idStore = writable(deal?.id);
  $: idStore.set(deal?.id);

  const query = createQuery(
    derived([idStore], ([$id]) => ({
      enabled: !!$id,
      queryKey: ['assignments', $id],
      queryFn: async () => {
        const result = await findAssignments({
          id: $id,
          module: 'deals',
          search: '',
          page: 1,
          itemsPerPage: 1000,
        });

        return result.data.reduce((acc, item) => {
          if (!acc[item.status]) {
            acc[item.status] = [];
          }
          acc[item.status].push(item);
          return acc;
        }, {});
      },
    })),
  );
  let selectedPhase = '';
  let phases;

  onMount(async () => {
    const phasesResult = await getLov('Phases');
    phases = phasesResult?.values?.map(el => el.name);
  });

  function openInfo(assignment) {
    console.log('openAssignment', assignment);
    appStore.openActivity(assignment);
  }
  function openCandidate(assignment) {
    console.log('openCandidate', assignment);
    appStore.setSelectedStack([
      {
        id: assignment?.deal,
        module: 'deals',
        tab: 'candidates',
      },
    ]);
    appStore.select({
      id: assignment?.candidate,
      module: 'contacts',
    });
    appStore.openPopup(true);
  }
</script>

<Report {deal} />

<!--Tabs.Root bind:value={selectedPhase} class="w-[400px]">
  <Tabs.List>
    {#each phases || [] as phase}
      <Tabs.Trigger value={phase}>{phase}</Tabs.Trigger>
    {/each}
  </Tabs.List>
</Tabs.Root-->
{#if $query.isLoading}
  <Skeleton class="h-[20px] w-full rounded-full" />
{:else if $query.isError}
  <div>Error: {$query.error.message}</div>
{:else if $query.isSuccess}
  {#each phases || [] as phase, idx}
    <Accordion.Root class="w-full">
      <Accordion.Item value="item-1">
        <Accordion.Trigger>
          {@const phaseCount = ($query.data[phase] || []).length}
          <Badge class="ml-2" variant={phaseCount ? 'default' : 'outline'}
            >{phaseCount}</Badge
          >
          <div class="ml-2">{phase}</div>
        </Accordion.Trigger>
        <Accordion.Content>
          <Table.Root>
            <Table.Caption>A list of your recent invoices.</Table.Caption>
            <Table.Header>
              <Table.Row>
                <Table.Head class="w-[50px]">#</Table.Head>
                <Table.Head>Name</Table.Head>
                <Table.Head>Date</Table.Head>
                <Table.Head>Description</Table.Head>
                {#if idx === phases.length - 1}
                  <Table.Head>Rejected By</Table.Head>
                  <Table.Head>Reason</Table.Head>
                {/if}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {#each ($query.data || [])[phase] || [] as assignment, i (i)}
                <Table.Row>
                  <Table.Cell class="font-medium">
                    <div class="flex items-center">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild let:builder>
                          <Button builders={[builder]} variant="ghost">
                            <EllipsisVertical class="size-4" />
                          </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content class="w-56">
                          <DropdownMenu.Item
                            on:click={() => openInfo(assignment)}
                            >Edit</DropdownMenu.Item
                          >
                          <DropdownMenu.Item
                            on:click={() => openCandidate(assignment)}
                            >Info</DropdownMenu.Item
                          >
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>

                      <div>
                        {i + 1}
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell class="flex items-center space-x-2">
                    <Avatar.Root>
                      <Avatar.Image src={assignment?.candidateObj?.photo} />
                      <Avatar.Fallback
                        >{assignment?.candidateObj?.name?.substring(
                          0,
                          1,
                        )}.</Avatar.Fallback
                      >
                    </Avatar.Root>
                    <div class="text-xl">
                      {assignment.candidateObj?.name}
                    </div></Table.Cell
                  >
                  <Table.Cell>{assignment.date ?? 'n/a'}</Table.Cell>
                  <Table.Cell>{assignment.description}</Table.Cell>
                  {#if idx === phases.length - 1}
                    <Table.Cell>{assignment.rejectedBy}</Table.Cell>
                    <Table.Cell>{assignment.rejectedReason}</Table.Cell>
                  {/if}
                </Table.Row>
              {/each}
            </Table.Body>
          </Table.Root>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  {/each}
{/if}
