import { writable } from 'svelte/store';
type AuthStoreType = {
  clerk?: any;
  user?: any;
  token?: string;
  organisation?: any;
};
function createAuthStore() {
  const { subscribe, set, update } = writable<AuthStoreType>({
    clerk: undefined,
    user: undefined,
    token: undefined,
    organisation: undefined,
  });
  return {
    subscribe,
    set,
    update,
  };
}
export const authStore = createAuthStore();
