<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';

  import * as Popover from '$lib/components/ui/popover/index.js';
  import { type Invoice } from '../../../../drizzle/schema';
  import { getItems } from '$lib/api/queries';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import { type SuperForm } from 'sveltekit-superforms';
  import * as Avatar from '$lib/components/ui/avatar';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { UserPlus, Users } from 'lucide-svelte';

  export let form: SuperForm<Invoice>;

  let { form: formData, enhance, errors } = form;

  $: isLocked = !!$formData.fixedOn;

  const loadOptionsAccounts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'accounts',
      from: 0,
      to: 5,
      search: keyword,
      select: 'id,name,logo,address',
    });

    return result.data;
  };

  const loadOptionsRecipient = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 5,
      filters: [
        {
          field: 'customer',
          operator: 'eq',
          value: $formData?.customer?.id,
          active: true,
        },
      ],
      select: 'id,name,photo',
    });

    return result.data;
  };

  $: open = false;
</script>

<Popover.Root bind:open portal={null}>
  <Popover.Trigger asChild let:builder>
    <Button builders={[builder]} class="gap-2 font-semibold" variant="outline">
      Change
      <UserPlus class="size-4" />
    </Button>
  </Popover.Trigger>
  <Popover.Content class="w-[350px] overflow-visible p-0 gap-0">
    <div class="flex flex-row gap-4">
      <div class="flex flex-col w-full gap-1 p-4">
        <HantaInputMultiSelect
          {form}
          label="Account"
          loadOptions={loadOptionsAccounts}
          multiple={false}
          name="customer"
          onSelectChanged={value => ($formData.recipient = undefined)}
          placeholder="Select account..."
          readOnly={isLocked}
        >
          <div
            class="flex items-center w-full h-full"
            let:index
            let:item
            slot="item"
          >
            <Avatar.Root class="size-7">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={item?.logo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="ml-2 text-sm truncate">{item?.name}</div>
          </div>
          <div
            class="flex items-center w-full overflow-hidden"
            let:selection
            slot="selection"
          >
            <Avatar.Root class="size-7">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={selection?.logo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="w-full ml-2 text-sm truncate">
              {selection?.name}
            </div>
          </div>
        </HantaInputMultiSelect>

        {#if $formData?.customer?.id}
          {#key $formData?.customer?.id}
            <HantaInputMultiSelect
              {form}
              label="Recipient"
              loadOptions={loadOptionsRecipient}
              multiple={false}
              name="recipient"
              placeholder="Select recipient..."
              readOnly={isLocked}
            >
              <div
                class="flex items-center w-full h-full"
                let:index
                let:item
                slot="item"
              >
                <Avatar.Root class="size-8">
                  <Avatar.Image
                    class="object-scale-down rounded-none"
                    src={item?.photo}
                  />
                  <Avatar.Fallback>-</Avatar.Fallback>
                </Avatar.Root>
                <div class="ml-2 text-sm truncate">{item?.name}</div>
              </div>
              <div
                class="flex items-center w-full h-full overflow-hidden"
                let:selection
                slot="selection"
              >
                <Avatar.Root class="size-8">
                  <Avatar.Image
                    class="object-scale-down rounded-none"
                    src={selection?.photo}
                  />
                  <Avatar.Fallback>-</Avatar.Fallback>
                </Avatar.Root>
                <div class="w-full ml-2 text-sm truncate">
                  {selection?.name}
                </div>
              </div>
            </HantaInputMultiSelect>
          {/key}
        {/if}
      </div>
    </div>
    <div class="px-4 pb-4 w-full flex justify-end">
      <Button on:click={() => (open = false)}>Ok</Button>
    </div>
  </Popover.Content>
</Popover.Root>
