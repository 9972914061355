<script lang="ts">
  import { CalendarIcon } from 'lucide-svelte';
  import {
    type DateValue,
    DateFormatter,
    getLocalTimeZone,
    today,
    fromDate,
    toCalendarDate,
  } from '@internationalized/date';
  import { cn } from '$lib/utils.js';
  import { buttonVariants } from '$lib/components/ui/button/index.js';
  import { Calendar } from '$lib/components/ui/calendar/index.js';
  import * as Popover from '$lib/components/ui/popover/index.js';
  import * as Form from '$lib/components/ui/form/index.js';
  import { stringProxy } from 'sveltekit-superforms';
  import HantaSelect from '$lib/components/hanta/select.svelte';

  export let form;
  export let name;
  export let label;
  export let description = '';
  export let withTime = false;
  export let readOnly = false;

  const timeOptions = [];
  const { form: formData, enhance } = form;
  const df = new DateFormatter('en-US', {
    dateStyle: 'long',
  });

  let value: DateValue | undefined;
  let timeValue: string;
  let placeholder: DateValue = today(getLocalTimeZone());

  $: proxy = stringProxy(formData, name, {
    empty: 'undefined',
  });

  $: console.log('value', { proxy: $proxy, formData: $formData, name });

  $: if ($proxy) {
    const localDateTime = new Date($proxy);
    value = toCalendarDate(fromDate(localDateTime, getLocalTimeZone()));

    if (withTime) {
      const split = localDateTime.toLocaleTimeString().split(':');
      let timeString = split[0] + ':' + split[1];
      timeValue = timeOptions.find(time => time === timeString);
    }
  } else {
    value = undefined;
  }

  function convertToIsoString(dateVal: DateValue) {
    const localDateTime = new Date($proxy);
    const date = dateVal.toDate(getLocalTimeZone());

    localDateTime.setFullYear(date.getFullYear());
    localDateTime.setMonth(date.getMonth());
    localDateTime.setDate(date.getDate());

    return localDateTime.toISOString();
  }

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      let hours = i < 10 ? '0' + i : i;
      let minutes = j < 10 ? '0' + j : j;
      timeOptions.push(`${hours}:${minutes}`);
    }
  }

  const changeTime = timeString => {
    const localDateTime = new Date($proxy);
    const [hours, minutes] = timeString.split(':').map(Number);
    localDateTime.setHours(hours);
    localDateTime.setMinutes(minutes);
    $proxy = localDateTime.toISOString();
  };
</script>

<Form.Field class="flex flex-col" {form} {name}>
  <Form.Control let:attrs>
    <div class="flex flex-row w-full gap-2">
      <div class="flex flex-col w-full gap-2">
        {#if label}
          <Form.Label class="flex flex-row items-center gap-2">
            <slot name="icon" />
            {label}
          </Form.Label>
        {/if}

        {#if !readOnly}
          <Popover.Root>
            <Popover.Trigger
              {...attrs}
              class={cn(
                buttonVariants({ variant: 'outline' }),
                'min-w-[180px] justify-start pl-4 text-left font-normal',
                !value && 'text-muted-foreground',
              )}
            >
              {value
                ? df.format(value.toDate(getLocalTimeZone()))
                : 'Pick a date'}
              <CalendarIcon class="w-4 h-4 ml-auto opacity-50" />
            </Popover.Trigger>
            <Popover.Content class="w-auto p-0" side="top">
              <Calendar
                bind:placeholder
                calendarLabel={label}
                initialFocus
                onValueChange={v => {
                  if (v) {
                    $proxy = convertToIsoString(v);
                  } else {
                    $proxy = '';
                  }
                }}
                {value}
              />
            </Popover.Content>
          </Popover.Root>
        {:else if value}
          <span class="text-sm font-semibold text-foreground text-nowrap"
            >{df.format(value.toDate(getLocalTimeZone()))}</span
          >
        {/if}
      </div>
      {#if withTime}
        {#if !readOnly}
          <div class="flex flex-col gap-2">
            <Form.Label>Time</Form.Label>
            <div class="w-28 shrink-0 grow-0">
              <HantaSelect
                {readOnly}
                searchable={false}
                onSelect={val => {
                  changeTime(val);
                }}
                value={timeValue}
                options={timeOptions.map(time => ({
                  value: time,
                  label: time,
                }))}
              />
            </div>
          </div>
        {:else}
          {timeValue}
        {/if}
      {/if}
    </div>
    <Form.Description>
      {description ?? ''}
    </Form.Description>
    <Form.FieldErrors />
    <input hidden name={attrs.name} value={$formData.dob} />
  </Form.Control>
</Form.Field>
