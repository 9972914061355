<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';
  import { InfoIcon, Pencil } from 'lucide-svelte';
  import { appStore } from '$lib/app-store';

  export let assignment;

  function openInfo() {
    console.log('openAssignment', assignment);
    appStore.openActivity(assignment);
  }
  function openCandidate() {
    console.log('openCandidate', assignment);
    appStore.setSelectedStack([
      {
        id: assignment?.deal,
        module: 'deals',
        tab: 'kanban',
      },
    ]);
    appStore.select({
      id: assignment?.candidate,
      module: 'contacts',
    });
    appStore.openPopup(true);
  }
</script>

<div
  class="relative min-h-[50px] flex flex-col justify-between w-full my-3 group"
>
  <div class="relative flex pr-10 overflow-hidden">
    <Avatar.Root class="rounded-none size-12">
      <Avatar.Image src={assignment?.candidateObj?.photo} />
      <Avatar.Fallback
        >{assignment?.candidateObj?.name?.substring(0, 1)}.</Avatar.Fallback
      >
    </Avatar.Root>

    <div
      class="absolute top-0 right-0 flex-col hidden space-y-2 transition-all duration-300 transform -translate-y-4 opacity-0 group-hover:flex group-hover:opacity-100 group-hover:translate-y-0"
    >
      <button on:click={openInfo}>
        <Pencil class="size-4 text-muted-foreground hover:text-foreground" />
      </button>
      <button on:click={openCandidate}>
        <InfoIcon class="text-muted-foreground size-4 hover:text-foreground" />
      </button>
    </div>
  </div>

  <div class="flex mt-2 text-xs text-left">
    <div class="text-base font-bold text-md">
      {assignment?.candidateObj?.name}
    </div>
  </div>
  {#if assignment?.description}
    <div class="mt-2 text-base overflow-clip font-extralight">
      {assignment?.description}
    </div>
  {/if}

  <!--div class="mt-2 text-muted-foreground">
    added <Time relative timestamp={assignment?.startDate} />
  </div-->
</div>
