<script lang="ts">
  import { Map } from 'lucide-svelte';
  export let address;

  $: addressLink = [address?.street, address?.city, address?.country]
    .filter(el => el)
    .join(', ');
</script>

{#if addressLink}
  <div class="flex space-x-2">
    <a
      class="flex text-primary/80 no-underline hover:text-primary/80 visited:text-primary/80"
      href={`https://www.google.de/maps/search/${addressLink}`}
      target="_blank"
    >
      <Map size="16" class="mr-2" />
      {addressLink}
    </a>
  </div>
{/if}
