<script lang="ts">
  import { cn } from '$lib/utils';

  import * as Sheet from '$lib/components/ui/sheet';

  import type { Route } from './config';
  import Button from '$lib/components/ui/button/button.svelte';
  import * as Tooltip from '$lib/components/ui/tooltip';

  export let isCollapsed: boolean;
  export let routes: Route[];

  import { appStore } from '$lib/app-store';
  import { HamburgerMenu } from 'radix-icons-svelte';
</script>

{#if isCollapsed}
  <div
    data-collapsed={isCollapsed}
    class="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2 rounded border border-solid border-primary-50 z-10 shadow-border shadow-md bg-background"
  >
    <nav
      class="tn-grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2"
    >
      {#each routes || [] as route}
        {#if isCollapsed}
          <Tooltip.Root openDelay={100}>
            <Tooltip.Trigger asChild let:builder>
              <Button
                builders={[builder]}
                variant={route.module === $appStore.currentModule
                  ? 'default'
                  : 'ghost'}
                on:click={() =>
                  $appStore.currentModule === route.module
                    ? appStore.setCurrentModule(null)
                    : appStore.setCurrentModule(route.module)}
                size="icon"
                class={cn(
                  'size-9 my-0.5 hover:bg-primary/30',
                  route.module === $appStore.currentModule &&
                    'bg-primary/20 text-primary/90',
                )}
              >
                <svelte:component
                  this={route.icon}
                  class="size-4"
                  aria-hidden="true"
                />
                <span class="sr-only">{route.title}</span>
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content side="right" class="flex items-center gap-4">
              {route.title}
              {#if route.label}
                <span class="ml-auto text-muted-foreground">
                  {route.label}
                </span>
              {/if}
            </Tooltip.Content>
          </Tooltip.Root>
        {:else}
          <Sheet.Root>
            <Sheet.Trigger asChild let:builder>
              <Button builders={[builder]} variant="outline">
                <HamburgerMenu class="size-3" />
              </Button>
            </Sheet.Trigger>
            <Sheet.Content side="left">
              <Button
                on:click={() =>
                  $appStore.currentModule === route.module
                    ? appStore.setCurrentModule(null)
                    : appStore.setCurrentModule(route.module)}
                variant={route.module === $appStore.currentModule
                  ? 'default'
                  : 'ghost'}
                size="sm"
                class={cn(
                  'justify-start my-0.5 hover:bg-primary/30',
                  route.module === $appStore.currentModule &&
                    'bg-primary/20 text-primary/90',
                )}
              >
                <svelte:component
                  this={route.icon}
                  class="mr-2 size-4"
                  aria-hidden="true"
                />
                {route.title}
                {#if route.label}
                  <span
                    class={cn('ml-auto', {
                      'text-background dark:text-white':
                        route.variant === 'default',
                    })}
                  >
                    {route.label}
                  </span>
                {/if}
              </Button>
              <Sheet.Header>
                <Sheet.Title>Edit profile</Sheet.Title>
                <Sheet.Description>
                  Make changes to your profile here. Click save when you're
                  done.
                </Sheet.Description>
              </Sheet.Header>
            </Sheet.Content>
          </Sheet.Root>
        {/if}
      {/each}
    </nav>
  </div>
{:else}
  <div class="flex flex-col">
    {#each routes || [] as route}
      <Button
        on:click={() =>
          $appStore.currentModule === route.module
            ? appStore.setCurrentModule(null)
            : appStore.setCurrentModule(route.module)}
        variant={route.module === $appStore.currentModule ? 'default' : 'ghost'}
        size="sm"
        class={cn(
          'justify-start my-0.5 hover:bg-primary/30',
          route.module === $appStore.currentModule &&
            'bg-primary/20 text-primary/90',
        )}
      >
        <svelte:component
          this={route.icon}
          class="mr-2 size-4"
          aria-hidden="true"
        />
        {route.title}
        {#if route.label}
          <span
            class={cn('ml-auto', {
              'text-background dark:text-white': route.variant === 'default',
            })}
          >
            {route.label}
          </span>
        {/if}
      </Button>
    {/each}
  </div>
{/if}
