<script lang="ts">
  import { uploadFile } from '$lib/api/storage';
  import { Button } from '$lib/components/ui/button';
  import * as Dialog from '$lib/components/ui/dialog';
  import Input from '$lib/components/ui/input/input.svelte';
  import Label from '$lib/components/ui/label/label.svelte';

  let localFile = null;
  export async function uploadTemplate(e) {
    localFile = e.target.files[0];
  }

  export async function upload() {
    templateFile = localFile;
    await uploadFile(
      templateFile,
      templateUrl + (revision ? templateName + '__revisions/' + revision : ''),
      templateName,
      true,
    );
    open = false;
  }

  export let open = false;
  export let templateUrl;
  export let templateName;
  export let templateFile;
  export let revision = '';
</script>

<Dialog.Root bind:open>
  <Dialog.Content class="sm:max-w-[425px]">
    <Dialog.Header>
      <Dialog.Title>Upload new template</Dialog.Title>
      <Dialog.Description>Upload a .docx template</Dialog.Description>
    </Dialog.Header>
    <div class="gap-4 py-4 tn-grid">
      <input
        class="my-4 text-xs"
        type="file"
        on:change={e => uploadTemplate(e)}
        accept=".docx"
      />

      <div class="grid w-full max-w-sm items-center gap-1.5">
        <Label for="revision">Revision</Label>
        <Input
          type="revision"
          id="revision"
          placeholder=""
          bind:value={revision}
        />
      </div>
    </div>
    <Dialog.Footer>
      <Button disabled={!localFile} on:click={upload}>Save changes</Button>
    </Dialog.Footer>
  </Dialog.Content>
</Dialog.Root>
