<script lang="ts">
  import { Command as CommandPrimitive } from 'cmdk-sv';

  import { appStore } from '$lib/app-store';
  import * as Command from '$lib/components/ui/command';
  import { cn } from '$lib/utils';
  import { Search } from 'lucide-svelte';
  import { onMount } from 'svelte';
  import { Button } from '$lib/components/ui/button';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { globalSearch, getItemById } from '$lib/api/queries';
  import * as Avatar from '$lib/components/ui/avatar';
  import Address from '$lib/components/hanta/address.svelte';
  import Communication from '$lib/components/hanta/communication.svelte';

  export let open = false;
  let searchTerm = '';
  let currentSelected = 0;

  let lastShiftPressTime = 0;
  const doubleShiftInterval = 300; // Maximum time in milliseconds between double presses
  let shiftPressed = false;

  onMount(() => {
    function handleKeydown(e: KeyboardEvent) {
      if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        open = !open;
      }
      if (e.key === 'Shift' && import.meta.env.MODE !== 'plugin') {
        const currentTime = new Date().getTime();
        if (
          currentTime - lastShiftPressTime <= doubleShiftInterval &&
          !shiftPressed
        ) {
          e.preventDefault();
          console.log('Double Shift pressed!');
          open = !open;
        }

        shiftPressed = true;
        lastShiftPressTime = currentTime;
      }
    }

    function handleKeyup(e) {
      if (e.key === 'Shift') {
        shiftPressed = false;
      }
    }

    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyup);
    };
  });

  let results = [];
  let list;

  let ac = new AbortController();

  $: if (searchTerm) {
    console.log('searchTerm', searchTerm);
    if (ac) {
      ac.abort();
    }

    ac = new AbortController();

    globalSearch(searchTerm, ac.signal).then(response => {
      results = response || [];
      console.log(response);
      currentSelected = 0;
    });
  } else {
    results = [];
    if (ac) {
      ac.abort();
    }
  }

  function scrollTo() {
    const itemHeight = 56;
    // if item is not in the visible area
    console.log(
      currentSelected,
      list.scrollTop,
      list.scrollHeight,
      list.clientHeight,
      list.scrollHeight - list.clientHeight,
    );

    const currentItemPosition = currentSelected * itemHeight;
    // if currentItem is not visible
    console.log('UU: ', currentItemPosition, list.scrollTop, list.clientHeight);
    if (
      currentItemPosition > list.scrollTop &&
      currentItemPosition < list.clientHeight + list.scrollTop - itemHeight
    ) {
      return;
    }

    if (currentItemPosition < list.scrollTop) {
      console.log('scrolling to top');
      list.scrollTo(0, currentItemPosition);
    } else if (currentItemPosition > list.clientHeight) {
      console.log('scrolling to down ');
      list.scrollTo(0, (currentSelected + 1) * itemHeight - list.clientHeight);
    }
  }

  function onKeyDown(e) {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      currentSelected = Math.min(currentSelected + 1, results.length - 1);
      scrollTo();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      currentSelected = Math.max(currentSelected - 1, 0);
      scrollTo();
    } else if (e.key === 'Enter') {
      openItem(results[currentSelected]);
    }
  }

  function openItem({ module, id }) {
    appStore.select({
      module,
      id,
    });
    appStore.openPopup(true);
    open = false;
  }
</script>

<div
  class="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2 bg-primary-100 md:rounded-t md:border md:border-b-0 md:border-solid md:border-primary/20 z-10 md:shadow-border md:shadow-md"
>
  <nav
    class="tn-grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2"
  >
    <Tooltip.Root openDelay={0}>
      <Tooltip.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          variant="ghost"
          size="icon"
          class={cn('md:size-9 ')}
          on:click={() => (open = !open)}
        >
          <Search class="size-5 md:size-4" />
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content side="right">
        <div class="flex z-10 items-center space-x-2 w-1/2">
          <span>Search</span>
          <span>
            <kbd
              class="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100"
            >
              <span class="text-xs">⌘</span>J
            </kbd>
          </span>
          {#if import.meta.env.MODE !== 'plugin'}
            <span class="mx-2">or</span>
            <span>
              <kbd
                class="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100"
              >
                <kbd>⇧</kbd> twice
              </kbd>
            </span>
          {/if}
        </div>
      </Tooltip.Content>
    </Tooltip.Root>

    <Command.Dialog bind:open>
      <div class="pt-2 pr-12 pb-2 pl-6 bg-secondary">
        <CommandPrimitive.Input
          class={cn(
            'focus-visible:ring-0 flex h-10 w-full rounded-md bg-transparent py-3 border-none text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
          )}
          placeholder="Search in accounts, deals and contacts"
          bind:value={searchTerm}
          on:keydown={onKeyDown}
        />
        {#if searchTerm}
          <div class="hidden ml-2 text-xs">Top Results: {results?.length}</div>
        {/if}
      </div>

      {#if searchTerm}
        <div class="grid-cols-2 tn-grid">
          <div class="p-2">
            <div
              class="overflow-scroll min-h-[30vh] max-h-[50svh] space-y-2"
              bind:this={list}
            >
              {#each results || [] as result, idx}
                <button
                  on:click={() => openItem(result)}
                  on:keydown={e => {
                    if (e.key === 'Enter') {
                      openItem(result);
                    }
                  }}
                  class={cn(
                    idx === currentSelected && 'bg-secondary',
                    'w-full text-left text-base p-2 h-12 overflow-hidden whitespace-nowrap rounded cursor-pointer hover:bg-primary/20 transition-colors duration-100 ease-in-out',
                  )}
                >
                  {result.name}
                </button>
              {/each}
            </div>
          </div>

          <div class="p-4 bg-primary-foreground">
            {#if results.length > 0 && currentSelected >= 0 && currentSelected < results.length}
              {@const item = results[currentSelected]}
              {#await getItemById(item.module, item.id, false)}
                <div></div>
              {:then item}
                <Avatar.Root class="size-36">
                  <Avatar.Image
                    src={item?.photo || item?.logo}
                    alt="Contact Photo"
                    class="object-scale-down"
                  />
                  <Avatar.Fallback>{item?.name ?? ''}</Avatar.Fallback>
                </Avatar.Root>

                <div class="text-xl">{item['name']}</div>

                {@const address =
                  item['addressPrivate'] ||
                  item['addressWork'] ||
                  item['address']}
                {@const communication =
                  item['communicationPrivate'] ||
                  item['communicationWork'] ||
                  item['communication']}
                {#if address}
                  <div class="mt-2">
                    <Address {address} />
                  </div>
                {/if}
                {#if communication}
                  <div class="mt-2">
                    <Communication {communication} />
                  </div>
                {/if}
              {:catch error}
                <div>Error: {error.message}</div>
              {/await}
            {/if}
          </div>
        </div>
      {/if}
    </Command.Dialog>
  </nav>
</div>
