export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export function formatNumberWithSuffix(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  }
  const suffixes = ['', 'K', 'M', 'B']; // Suffixes for thousand, million, and billion
  const index = Math.floor(Math.log(number) / Math.log(1000));
  const value = parseFloat((number / Math.pow(1000, index)).toFixed(1));
  return `${value}${suffixes[index]}`;
}

export function extractName(result, name) {
  let split = name.trim().split(' ');
  if (split.length > 0) {
    result['vorname'] = split.slice(0, split.length - 1).join(' ');
    split.length > 1 && (result['name'] = split[split.length - 1]);
  }
}
