<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { toast } from 'svelte-sonner';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import {
    ArrowRight,
    ArrowLeft,
    PlusIcon,
    Clock,
    Hourglass,
    EllipsisVertical,
  } from 'lucide-svelte';
  import Timelog from './timelog.svelte';

  import { createQuery } from '@tanstack/svelte-query';
  import { derived, writable } from 'svelte/store';
  import { getItems } from '$lib/api/queries';
  import { cn } from '$lib/utils';
  import { deleteItem, saveTimelog } from '$lib/api/mutations';
  import { appStore } from '$lib/app-store';
  import TimelogsTotal from './timelogs-total.svelte';

  const now = new Date();
  const from = writable(new Date(now.getFullYear(), now.getMonth(), 1));
  const to = writable(new Date(now.getFullYear(), now.getMonth() + 1, 0));
  const search = writable('');

  const query = createQuery(
    derived([from, to, search], ([$from, $to]) => ({
      enabled: true,
      queryKey: ['timelogs', $from, $to, $search],
      queryFn: ({ signal }) =>
        getItems(
          {
            collection: 'timelogs',
            from: 0,
            to: 1000,
            search: $search,
            filters: [
              {
                field: 'date',
                operator: 'gte',
                value: $from.toLocaleDateString('sv').substring(0, 10),
                active: true,
              },
              {
                field: 'date',
                operator: 'lte',
                value: $to.toLocaleDateString('sv').substring(0, 10),
                active: true,
              },
            ],
            sort: {
              column: 'date',
              order: 'asc',
            },
            select: '*',
          },
          signal,
        ),
    })),
  );

  const defaultTimeEntry = {
    date: new Date().toISOString(),
    starttime: '09:00',
    endtime: '17:00',
    pause: '30',
  };

  let timeEntry = { ...defaultTimeEntry };

  const onPreviuosMonthFn = () => {
    from.set(new Date($from.getFullYear(), $from.getMonth() - 1, 1));
    to.set(new Date($to.getFullYear(), $to.getMonth(), 0));
  };

  const onNextMonthFn = () => {
    from.set(new Date($from.getFullYear(), $from.getMonth() + 1, 1));
    to.set(new Date($to.getFullYear(), $to.getMonth() + 2, 0));
  };

  $: days = Array.from(
    {
      length: new Date($from.getFullYear(), $from.getMonth() + 1, 0).getDate(),
    },
    (_, i) => i + 1,
  );

  $: totalMinutes = ($query.data?.data || []).reduce((acc, item) => {
    const minutes = item.duration.split(':').reduce((acc, time, i) => {
      return acc + parseInt(time) * Math.pow(60, 1 - i);
    }, 0);
    return acc + minutes;
  }, 0);

  $: totalHours = Math.floor(totalMinutes / 60);
  $: totalMinutesRest = (totalMinutes % 60) / 60;

  const UNDO_TIMEOUT = 5000;

  const invalidateCache = () => {
    $appStore.queryClient.invalidateQueries(['timelogs'], {
      exact: true,
      refetchActive: true,
    });
  };
  const onDeleteItem = async item => {
    await deleteItem('timelogs', item.id);
    invalidateCache();

    toast(`Item deleted successfully`, {
      duration: UNDO_TIMEOUT,
      action: {
        label: 'Undo',
        onClick: async e => {
          await saveTimelog(item);
          invalidateCache();
        },
      },
    });
  };
  let open = false;
</script>

<div class="">
  <h1 class="text-xl">Timelogs</h1>
  <div class="m-1 md:m-4">
    <div class="flex items-center space-x-2">
      <Button variant="outline" size="sm" on:click={onPreviuosMonthFn}>
        <ArrowLeft class="size-5" />
      </Button>

      <div class="w-44">
        <div class="text-muted-foreground">Month</div>
        <div class="text-xl">
          {$from.toLocaleDateString('de', {
            month: 'long',
            year: 'numeric',
          })}
        </div>
      </div>
      <Button variant="outline" size="sm" on:click={onNextMonthFn}>
        <ArrowRight class="size-5" />
      </Button>

      <Timelog {timeEntry} bind:open />
    </div>

    <div class="w-full my-12 overflow-scroll h-[calc(100svh-200px)]">
      {#if $query.isLoading}
        <div
          class="w-full h-[2px] bg-primary-800 duration-400 animate-pulse"
        ></div>
      {:else if $query.isError}
        <p>Error: {$query.error.message}</p>
      {:else if $query.isSuccess}
        {@const data = $query.data?.data || []}
        {@const projects = data.reduce((acc, item) => {
          const { project } = item;
          if (!acc[project]) {
            acc[project] = [];
          }
          acc[project].push(item);
          return acc;
        }, {})}

        <div class="flex items-center space-x-4 md:w-1/3">
          {#if Object.entries(projects)?.length > 1}
            <div
              class="w-48 p-4 my-4 border border-solid shadow-md bg-secondary"
            >
              <div class="text-muted-foreground">Total</div>
              <div class="text-xl">
                {(totalHours + totalMinutesRest).toFixed(2)} hours
              </div>
            </div>
          {/if}

          {#each Object.entries(projects) as [projectName, projectItems]}
            <TimelogsTotal
              project={projectName}
              data={projectItems}
              from={$from}
              to={$to}
            />
          {/each}
        </div>

        {#each days as day}
          {@const dayData = data.filter(
            el => new Date(el.date).getDate() === day,
          )}
          {@const dayDate = new Date(
            $from.getFullYear(),
            $from.getMonth(),
            day,
          )}
          {@const dayName = dayDate.toLocaleDateString('de', {
            weekday: 'short',
          })}
          {@const dayOfTheWeek = dayDate.getDay()}
          <div class="relative flex p-2 my-4 border">
            <div
              class="relative flex items-center justify-center mb-6 text-xl border p-7 size-12"
              class:text-orange-500={(dayOfTheWeek === 0 ||
                dayOfTheWeek === 6) &&
                'text-orange-500'}
            >
              <div class="text-2xl">
                {day}
              </div>
              <span
                class={cn(
                  'absolute top-0 text-xs left-1 text-muted-foreground',
                )}>{dayName}</span
              >
              <Button
                variant="ghost"
                size="icon"
                class="absolute top-0 left-12 md:left-16 "
                on:click={() => {
                  timeEntry = {
                    ...defaultTimeEntry,
                    date: new Date(
                      $from.getFullYear(),
                      $from.getMonth(),
                      day,
                      9,
                      0,
                    ),
                  };
                  open = true;
                }}
              >
                <PlusIcon class="size-4" />
              </Button>
            </div>

            <div class="w-full ml-2 md:ml-12">
              {#if dayData.length > 0}
                <div class="w-full">
                  {#each dayData as item}
                    <div class="flex items-center w-full">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild let:builder>
                          <Button builders={[builder]} variant="ghost">
                            <EllipsisVertical class="size-4" />
                          </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content class="w-56">
                          <DropdownMenu.Group>
                            <DropdownMenu.Item
                              on:click={() => {
                                timeEntry = item;
                                open = true;
                              }}>Edit</DropdownMenu.Item
                            >
                            <DropdownMenu.Item
                              on:click={() => {
                                timeEntry = {
                                  ...item,
                                };
                                delete timeEntry.id;
                                open = true;
                              }}>Duplicate</DropdownMenu.Item
                            >
                            <DropdownMenu.Separator />
                            <DropdownMenu.Item
                              on:click={async () => {
                                onDeleteItem(item);
                              }}>Delete</DropdownMenu.Item
                            >
                          </DropdownMenu.Group>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>

                      <Button
                        variant="ghost"
                        class="w-full h-full max-w-5xl grid-cols-1 my-2 text-base text-left border border-solid md:my-0 md:border-none md:grid-cols-7 tn-grid"
                        on:click={() => {
                          timeEntry = item;
                          open = true;
                        }}
                      >
                        <div
                          class="flex items-center my-2 font-mono font-bold md:my-0"
                        >
                          {item.duration.substring(0, 5)}
                        </div>

                        <div
                          class="flex flex-col font-mono md:flex-row md:items-center"
                        >
                          <div class="flex items-center">
                            <Clock class="mr-2 text-muted-foreground size-4" />

                            {item.starttime.substring(0, 5)} - {item.endtime.substring(
                              0,
                              5,
                            )}
                          </div>

                          <div class="flex items-center md:ml-4">
                            <Hourglass
                              class="mr-2 text-muted-foreground size-4"
                            />
                            <div>{item.pause} min</div>
                          </div>

                          <div class="ml-4 text-muted-foreground">
                            {item.task ?? ''}
                          </div>
                          <div class="overflow-hidden truncate">
                            {item.description ?? ''}
                          </div>
                        </div></Button
                      >
                    </div>
                  {/each}
                </div>
              {/if}
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </div>
</div>
