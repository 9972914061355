<script lang="ts">
  import * as Command from '$lib/components/ui/command';
  import { cn } from '$lib/utils';
  import * as Icons from '$lib/app/icons';
  import { Plus } from 'lucide-svelte';
  import { onMount } from 'svelte';
  import { Button } from '$lib/components/ui/button';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { appStore } from '$lib/app-store';
  import { Input } from '$lib/components/ui/input';
  import { Label } from '$lib/components/ui/label';
  import { createItem } from '$lib/api/mutations';
  import { ContactRoleType } from '../../../drizzle/schema';
  import { superForm } from 'sveltekit-superforms';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';

  let open = false;

  onMount(() => {
    function handleKeydown(e: KeyboardEvent) {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        open = !open;
      }
    }

    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  });

  let insertMode = false;
  let item = {
    name: '',
  };
  let module = '';

  $: if (!open) {
    insertMode = false;
    item = {
      name: '',
    };
  }

  function create(moduleName: string) {
    insertMode = true;
    module = moduleName;
  }

  type NewCrmObject = {
    name: string;
    customer?: {
      id: string;
      name: string;
      logo: string;
    };
  };
  const form = superForm<NewCrmObject>({} as NewCrmObject, {
    resetForm: false,
    SPA: true,
    // validators: zodClient(formSchema),
    validationMethod: 'oninput',
    dataType: 'json',
    onSubmit: async ({ formData }) => {
      /*if (Object.keys($errors).length > 0) {
          console.error('Validation errors', $errors, { form: $formData });
          $errors = {};
          return;
        }*/
      // let parse = activityWithRefsInsertSchema.parse($formData);
    },
  });

  let { form: formData, enhance, errors } = form;

  async function createNewItem() {
    try {
      let crmModule = module;
      if (module === 'contacts' || module === 'candidates') {
        item = {
          firstname: item.firstname,
          lastname: item.lastname,
          name: `${item.firstname} ${item.lastname}`,
          role:
            module === 'candidates'
              ? ContactRoleType.enum.Candidate
              : ContactRoleType.enum.Contact,
        };
        crmModule = 'contacts';
      } else if (module === 'invoices') {
        item = {
          name: 'New Invoice',
          customer: item.customer?.id,
          status: 'Draft',
          positions: [],
        };
      }

      const data = await createItem(crmModule, item);
      appStore.select({ id: data[0].id, module: crmModule });
      appStore.openPopup(true);
      open = false;
    } catch (error) {
      console.error(error);
    }
  }
</script>

<div
  class="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2 md:bg-primary-100 md:rounded-b md:border md:border-solid md:border-primary/20 z-10 md:shadow-border md:shadow-md"
>
  <nav
    class="tn-grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2"
  >
    <Tooltip.Root openDelay={0}>
      <Tooltip.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          variant={'ghost'}
          size="icon"
          class={cn('size-9')}
          on:click={() => (open = !open)}
        >
          <Plus class="size-5" />
        </Button>
      </Tooltip.Trigger>

      <Tooltip.Content side="right">
        <div class="flex items-center ml-auto space-x-2">
          <span>Create item</span>

          <kbd
            class="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100"
          >
            <span class="text-xs">⌘</span>K
          </kbd>
        </div>
      </Tooltip.Content>
    </Tooltip.Root>

    <Command.Dialog bind:open>
      {#if insertMode}
        <div class="p-6">
          <h1 class="text-xl capitalize">{module}</h1>
          <form class="gap-2 p-6 tn-grid" on:submit={createNewItem}>
            <div class="items-center gap-4 tn-grid">
              {#if module === 'accounts' || module === 'deals'}
                <Label for="name">Name</Label>
                <Input
                  id="name"
                  bind:value={item.name}
                  class="h-8 col-span-2"
                  autofocus
                />
              {:else if module === 'invoices'}
                <div class="h-[300px]">
                  <AccountSelector bind:value={item.customer} />
                </div>
              {:else if module === 'candidates' || module === 'contacts'}
                <Label for="name">First name</Label>
                <Input
                  id="firstName"
                  bind:value={item.firstname}
                  class="h-8 col-span-2"
                  autofocus
                />
                <Label for="name">Last name</Label>
                <Input
                  id="lastName"
                  bind:value={item.lastname}
                  class="h-8 col-span-2"
                />
              {/if}

              <!-- TODO: account , contact firstName lastName -->
            </div>
            <Button
              variant="default"
              size="sm"
              class="mt-6"
              on:click={createNewItem}>Create</Button
            >
          </form>
        </div>
      {:else}
        <Command.Input
          placeholder="Select from list or type here for search..."
        />
        <Command.List>
          <Command.Empty>No results found.</Command.Empty>
          <Command.Group heading="CRM Objects">
            <Command.Item onSelect={() => create('accounts')}>
              <Icons.Building class="w-4 h-4 mr-2" />
              <span>Account</span>
            </Command.Item>

            <Command.Item onSelect={() => create('invoices')}>
              <Icons.Euro class="w-4 h-4 mr-2" />
              <span>Invoice</span>
            </Command.Item>

            <Command.Item onSelect={() => create('contacts')}>
              <Icons.Users class="w-4 h-4 mr-2" />
              <span>Contact</span>
            </Command.Item>
            <Command.Item onSelect={() => create('candidates')}>
              <Icons.Users class="w-4 h-4 mr-2" />
              <span>Candidate</span>
            </Command.Item>
            <Command.Item onSelect={() => create('deals')}>
              <Icons.Rocket class="w-4 h-4 mr-2" />
              <span>Deal</span>
            </Command.Item>
            <Command.Separator />
            <Command.Item onSelect={() => create('assignments')}>
              <Icons.Send class="w-4 h-4 mr-2" />
              <span>Assignment</span>
            </Command.Item>
            <Command.Item onSelect={() => create('activities')}>
              <Icons.Send class="w-4 h-4 mr-2" />
              <span>Activity</span>
            </Command.Item>
          </Command.Group>
          <Command.Separator />
        </Command.List>
      {/if}
    </Command.Dialog>
  </nav>
</div>
