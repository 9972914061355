<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { BadgeCheck } from 'lucide-svelte';
  export let communcation;
</script>

{#if communcation?.values}
  <Button
    variant="ghost"
    size="icon"
    href={communcation.values}
    class=" text-primary"
    target={'_blank'}
  >
    <BadgeCheck class="text-teal-600 dark:text-teal-300 size-5" />
  </Button>
{/if}
