<script lang="ts">
  import { pie, arc } from 'd3-shape';
  import { scaleOrdinal } from 'd3-scale';
  import { schemeCategory10 } from 'd3-scale-chromatic';
  import { afterUpdate } from 'svelte';

  let chart;
  export let data;
  export let chartAsSvg;

  const width = 800;
  const height = 600;
  const radius = Math.min(width, height) / 2;

  const color = scaleOrdinal(schemeCategory10);

  $: pieData = pie().value(d => d.count)(data);

  $: arcGenerator = arc()
    .innerRadius(0)
    .outerRadius(radius - 40);

  $: outerArcGenerator = arc().innerRadius(radius).outerRadius(radius);

  afterUpdate(() => {
    chartAsSvg = chart.outerHTML;
  });

  function midAngle(d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }
</script>

<svg {width} {height} bind:this={chart}>
  <g transform={`translate(${width / 2},${height / 2})`}>
    {#each pieData as d}
      <path d={arcGenerator(d)} fill={color(d.data.name)} />
      <polyline
        stroke="black"
        fill="none"
        points={`${
          arcGenerator.centroid(d)[0]
        },${arcGenerator.centroid(d)[1]} ${outerArcGenerator.centroid(d)[0]},${
          outerArcGenerator.centroid(d)[1]
        } ${
          outerArcGenerator.centroid(d)[0] + (midAngle(d) < Math.PI ? 30 : -30)
        },${outerArcGenerator.centroid(d)[1]}`}
      />
      <text
        x={outerArcGenerator.centroid(d)[0] + (midAngle(d) < Math.PI ? 5 : -5)}
        y={outerArcGenerator.centroid(d)[1] - 12}
        dy=".35em"
        text-anchor={midAngle(d) < Math.PI ? 'start' : 'end'}
        fill="black"
        style="font-size: 12px;"
      >
        {d.data.name}
      </text>
    {/each}
  </g>
</svg>
