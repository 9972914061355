<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';
  import { ChevronDown } from 'lucide-svelte';

  import CrmDeleteObject from '$lib/ui/crm-delete-object.svelte';

  export let module;
  export let id;
  export let item;

  let showDeleteDialog = false;
  const onDelete = () => {
    showDeleteDialog = true;
  };
</script>

<DropdownMenu.Root>
  <DropdownMenu.Trigger asChild let:builder>
    <Button builders={[builder]} variant="link" size="sm">
      <span>Actions</span>
      <ChevronDown class="w-4 h-4 ml-4 text-muted-foreground" />
    </Button>
  </DropdownMenu.Trigger>
  <DropdownMenu.Content class="w-56">
    <!--DropdownMenu.Item>View history</DropdownMenu.Item>
    <DropdownMenu.Item>Export Data</DropdownMenu.Item>
    <DropdownMenu.Item>Search in Google</DropdownMenu.Item>
    <DropdownMenu.Separator />
    <DropdownMenu.Item>Merge</DropdownMenu.Item>
    <DropdownMenu.Item>Restore</DropdownMenu.Item>
    <DropdownMenu.Separator /-->
    <DropdownMenu.Item
      disabled={module === 'invoices' && item?.status !== 'Draft'}
      on:click={onDelete}>Delete</DropdownMenu.Item
    >
  </DropdownMenu.Content>
</DropdownMenu.Root>

<CrmDeleteObject bind:open={showDeleteDialog} {module} {id} />
