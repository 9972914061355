<script lang="ts">
  import HantaInputDate from './../../widgets/input/hanta-input-date.svelte';
  import HantaInputSelect from './../../widgets/input/hanta-input-select.svelte';
  import HantaInputMultiSelect from './../../widgets/input/hanta-input-multi-select.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputTextarea from './../../widgets/input/hanta-input-textarea.svelte';
  import { slide } from 'svelte/transition';
  import * as Form from '$lib/components/ui/form';
  import { type Deal, insertDealsSchema } from '../../../../drizzle/schema';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveDeal, updateAccount } from '$lib/api/mutations';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { superForm } from 'sveltekit-superforms';
  import { Button } from '$lib/components/ui/button';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import { getItems } from '$lib/api/queries';
  import * as Avatar from '$lib/components/ui/avatar';
  import { tick } from 'svelte';

  export let deal: Deal;

  const status = ['Active', 'Inactive', 'Pending', 'Closed', 'Won', 'Lost'];
  const prio = ['Low', 'Medium', 'High'];
  const forms = ['Contract', 'Freelance', 'Permanent', 'Temporary'];
  const roles = [
    'Abteilungsleitung',
    'Geschäftsführung',
    'Personalabteilung',
    'Recruiter',
    'Teamleitung',
    'Vertrieb',
    'Sonstiges',
  ];

  type Deals = {
    data: readonly Deal[];
  };

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<Deal> =>
      Promise.resolve(saveDeal(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: ['deals'] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: ['deals', deal.id] });
      const previousItems = client.getQueryData<Deals>(['deals', deal.id]);
      client.setQueryData(['deals', deal.id], { ...newItem });
      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData(['deals'], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: ['deals', deal.id] });
    },
  });

  const formSchema = insertDealsSchema
    .pick({ id: true, name: true, accounts: true })
    .partial();

  function createNewForm(newDeal) {
    return superForm<Deal>(newDeal, {
      resetForm: false,
      SPA: true,
      dataType: 'json',
      validators: zodClient(formSchema),
      onSubmit: async () => {
        if (Object.keys($errors).length > 0) {
          console.error('Validation errors', $errors, { form: $formData });
          $errors = {};
          return;
        }

        console.log('Form data', $formData);

        let parse = insertDealsSchema.parse($formData);
        $addMutation.mutate(parse, {
          onError(error) {
            console.error('Error', error);
          },
          onSuccess: async newDeal => {
            console.log('Success', { newDeal });
            if (newDeal && newDeal.customer) {
              await updateAccount(newDeal.customer);
            }
          },
        });
      },
    });
  }

  let form = createNewForm(deal);

  export let hasChanges: boolean = false;

  let { form: formData, enhance, errors } = form;
  let formId = 0;

  $: hasChanges = $formData && form.isTainted();
  $: formErrors = $errors;

  $: if (deal) {
    form = createNewForm(deal);

    formData = form.form;
    enhance = form.enhance;
    errors = form.errors;

    formId = (formId + 1) % 10;
    tick().then(() => {
      form.reset();
    });
  }

  const loadOptions = async keyword => {
    console.log('loadOptions', keyword);

    const result = await getItems({
      collection: 'accounts',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name,logo',
    });

    return result.data;
  };
</script>

{#key formId}
  <form class="relative w-full" method="POST" use:enhance>
    <div>
      <h2
        class="sticky top-0 z-10 px-6 py-1 font-semibold border-b border-solid border-b-primary-200/20 bg-primary-50"
      >
        Deal
      </h2>

      <div class="flex flex-col px-4 py-12 m-2 space-y-4 rounded">
        <HantaInputText {form} name="refId" label="Id" readOnly={true} />
        <HantaInputText {form} label="Name" name="name" />
        <HantaInputText {form} label="Position" name="position" />
        <HantaInputText {form} label="Url" name="url" isLink={true} />
        <HantaInputMultiSelect
          {form}
          label="Account"
          {loadOptions}
          name="customerObj"
          multiple={false}
        >
          <div class="flex items-center h-full" let:item slot="item">
            <Avatar.Root class="size-6">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={item?.logo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="ml-2 text-sm">{item?.name}</div>
          </div>
          <div class="flex items-center h-full" let:selection slot="selection">
            <Avatar.Root class="size-5">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={selection?.logo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="ml-2 text-sm">{selection?.name}</div>
          </div>
        </HantaInputMultiSelect>
        <HantaInputSelect
          {form}
          label="Role"
          name="role"
          options={roles.map(v => ({ label: v, value: v }))}
        />

        <HantaInputSelect
          {form}
          label="Form"
          name="form"
          options={forms.map(v => ({ label: v, value: v }))}
        />

        <HantaInputSelect
          {form}
          label="Status"
          name="state"
          options={status.map(v => ({ label: v, value: v }))}
        />

        <!--
        <HantaInputSelect
          {form}
          label="Priority"
          name="priority"
          options={prio.map(v => ({ label: v, value: v }))}
        /> -->

        <HantaInputDate {form} label="Start" name="start" />
        <HantaInputDate {form} label="End" name="end" />

        <HantaInputNumber {form} label="Fee in %" name="fee" />
        <HantaInputNumber {form} label="Fee total" name="totalFee" />
        <HantaInputNumber
          {form}
          label="Fee installments"
          name="feeInstallments"
        />
        <HantaInputNumber
          {form}
          label="Annual salary"
          name="annualGrossSalary"
        />
        <HantaInputNumber {form} label="Hourly rate" name="hourlyRate" />
        <HantaInputNumber {form} label="Daily rate" name="dailyRate" />

        <HantaInputTextarea {form} label="Salary notes" name="salaryNotes" />
        <HantaInputTextarea {form} label="Tasks" name="tasks" />
        <HantaInputTextarea {form} label="Requirements" name="requirements" />
        <!--HantaInputTextarea {form} name="benefits" label="Benefits" />
        <HantaInputTextarea {form} name="notes" label="Notes" /-->

        <HantaInputTextarea {form} label="Comment" name="comment" />
      </div>
    </div>

    {#if hasChanges || (formErrors && Object.keys(formErrors).length > 0)}
      <div
        class="sticky bottom-0 w-full pb-0 mb-0 border-t border-solid shadow border-t-primary-200/30 bg-muted"
        out:slide={{ duration: 1000 }}
        in:slide={{ duration: 1000 }}
      >
        <div class="flex flex-col gap-2 px-4">
          {#if formErrors}
            {#each Object.keys(formErrors) as error}
              <div class="text-sm text-red-500">
                {error}: {formErrors[error]}
              </div>
            {/each}
          {/if}
        </div>

        <div class="flex items-center justify-start w-full h-20 gap-2 px-4">
          <Form.Button variant="default">Save</Form.Button>
          <Button variant="outline" on:click={() => form.reset()}>Cancel</Button
          >
        </div>
      </div>
    {/if}
  </form>
{/key}
