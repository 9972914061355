<script lang="ts">
  import { findAssignments, getItems } from '$lib/api/queries';
  import { Button } from '$lib/components/ui/button/index';
  import Badge from '../../components/ui/badge/badge.svelte';
  import { ActivityType } from '../../../../drizzle/schema';
  import * as Avatar from '$lib/components/ui/avatar';
  import * as HoverCard from '$lib/components/ui/hover-card';
  import { ScrollArea } from '$lib/components/ui/scroll-area/index.js';

  import { cn } from '$lib/utils';

  export let dealId: string;

  const loadItems = async (dealId: string) => {
    const result = await findAssignments({
      id: dealId,
      module: 'deals',
      search: '',
      page: 1,
      itemsPerPage: 100,
    });

    const { count: rejectedCount } = await findAssignments({
      id: dealId,
      module: 'deals',
      page: 0,
      itemsPerPage: 1,
      filters: [{ field: 'status', operator: 'eq', value: 'Refused' }],
    });

    result['rejectedCount'] = rejectedCount;
    return result;
  };
</script>

{#await loadItems(dealId) then data}
  <HoverCard.Root openDelay={50}>
    <HoverCard.Trigger let:builder>
      <Button
        builders={[builder]}
        class="p-0 hover:bg-transparent group"
        variant="ghost"
      >
        <span
          class="transition-colors group-hover:text-foreground text-muted-foreground"
        >
          <Badge class="mx-1">{data.count} / {data.rejectedCount}</Badge> Candidates
          / Refused
        </span>
      </Button>
    </HoverCard.Trigger>
    <HoverCard.Content class="p-0 h-[400px] w-[350px]">
      <ScrollArea class="w-full h-full p-2">
        <div class="flex flex-col w-full gap-2">
          {#each data.data as assignment}
            <div class="flex items-center w-full h-full">
              <Avatar.Root class="size-8">
                <Avatar.Image
                  class="object-scale-down rounded-none"
                  src={assignment?.candidateObj?.photo}
                />
                <Avatar.Fallback>-</Avatar.Fallback>
              </Avatar.Root>
              <div class="w-full ml-2 text-sm truncate">
                {assignment?.candidateObj?.name}
              </div>
              {#if assignment.status}
                <Badge
                  class={cn(
                    'text-nowrap',
                    assignment.status === 'Contract!' &&
                      'text-white bg-green-600',
                  )}
                  variant="secondary">{assignment.status}</Badge
                >
              {/if}
            </div>
          {/each}
        </div>
      </ScrollArea>
    </HoverCard.Content>
  </HoverCard.Root>
{/await}
