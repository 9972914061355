<script lang="ts">
  import { generateUUID } from '$lib/utils/browser-utils';
  import { Check, ChevronDown } from 'lucide-svelte';

  import * as Command from '$lib/components/ui/command';
  import * as Popover from '$lib/components/ui/popover';
  import { Button } from '$lib/components/ui/button';
  import { cn } from '$lib/utils';
  import { afterUpdate, tick } from 'svelte';
  import { getLocalTimeZone } from '@internationalized/date';

  export let options: any[] = [];
  export let value = undefined;
  export let searchable = true;
  export let readOnly: boolean = false;

  type $$Props = {
    label?: string;
    class?: string;
    value?: any;
    options?: { label: string; value: any; description?: string }[];
    searchable?: boolean;
    onSelect?: (value: any) => void;
    readOnly?: boolean;
  };

  let className: $$Props['class'] = 'w-full';
  export { className as class };

  let open = false;

  $: selectedValue = options.find(f => f.value === value)?.label ?? 'Empty';

  export let onSelect = (value: any) => {};

  // We want to refocus the trigger button when the user selects
  // an item from the list so users can continue navigating the
  // rest of the form with the keyboard.
  function closeAndFocusTrigger(triggerId: string) {
    onSelect(value);
    open = false;
    tick().then(() => {
      document.getElementById(triggerId)?.focus();
    });
  }

  let popoverId = generateUUID();
  let hostElem: HTMLDivElement;

  afterUpdate(() => {
    if (open) {
      let elementById = document.getElementById(popoverId);
      let boundingClientRect = hostElem?.getBoundingClientRect();
      elementById?.setAttribute(
        'style',
        `width: ${boundingClientRect?.width}px;`,
      );
    }
  });
</script>

{#if !readOnly}
  <div bind:this={hostElem} class={className}>
    <Popover.Root bind:open let:ids>
      <Popover.Trigger asChild let:builder>
        {#if $$slots.trigger}
          <Button
            aria-expanded={open}
            builders={[builder]}
            class={cn(
              'h-auto min-h-0 border-none p-0 m-0 hover:border-none hover:bg-transparent w-full',
            )}
            role="combobox"
            variant="ghost"
          >
            <slot name="trigger" {value} label={selectedValue} />
          </Button>
        {:else}
          <Button
            aria-expanded={open}
            builders={[builder]}
            class={cn('justify-between group h-auto bg-transparent w-full')}
            role="combobox"
            variant="outline"
          >
            {selectedValue}
            <ChevronDown class="w-4 h-4 ml-2 opacity-50 shrink-0" />
          </Button>
        {/if}
      </Popover.Trigger>
      <Popover.Content class={cn('p-0')} id={popoverId}>
        <Command.Root>
          {#if searchable}
            <Command.Input
              class="font-normal h-9"
              placeholder="Search option..."
            />
            <Command.Empty>No option found.</Command.Empty>
          {/if}
          <Command.List>
            <Command.Group>
              {#each options || [] as option}
                <Command.Item
                  value={option.value}
                  onSelect={currentValue => {
                    value = currentValue;
                    closeAndFocusTrigger(ids.trigger);
                  }}
                >
                  <Check
                    class={cn(
                      'mt-0.5 mr-2 h-4 w-4 self-start',
                      value !== option.value && 'text-transparent',
                    )}
                  />
                  <div class="flex flex-col w-full">
                    <div class="font-medium">{option.label}</div>
                    {#if option.description}
                      <div class="font-thin">{option.description}</div>
                    {/if}
                  </div>
                </Command.Item>
              {/each}
            </Command.Group>
          </Command.List>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  </div>
{:else}
  <span class="text-sm font-semibold text-foreground text-nowrap"
    >{value?.label ?? selectedValue}</span
  >
{/if}
