<script lang="ts">
  import { Input } from '$lib/components/ui/input/index.js';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import DealPhase from '$lib/components/hanta/deals/deal-phase.svelte';
  import Pagination from '$lib/app/list/pagination.svelte';
  import { Badge } from '$lib/components/ui/badge';
  import * as Card from '$lib/components/ui/card';
  import type { Deal } from '../../../../drizzle/schema';
  import { getItems, getLov } from '$lib/api/queries';
  import { onMount } from 'svelte';
  import { cn } from '$lib/utils';
  import { appStore } from '$lib/app-store';

  type $$Props = {
    class?: string;
  };

  let deals: Deal[];
  let count = 0;
  let phases = [];
  let filterValue: string = '';
  let page = 0;

  $: filterValue, getDeals();
  $: page, getDeals();

  const openItem = (deal: Deal) => {
    appStore.setCurrentModule('deals');
    appStore.select({
      id: deal.id,
      module: 'deals',
    });
    appStore.openPopup(true);
  };

  const loadDeals = async () => {
    return await getItems({
      collection: 'deals',
      from: page,
      search: filterValue,
      to: page + 10,
      select: '*',
      filters: [
        { field: 'state', operator: 'eq', value: 'Active', active: true },
      ],
    });
  };

  async function getDeals() {
    const res = await loadDeals();
    deals = res.data as Deal[];
    count = res.count;
  }

  onMount(async () => {
    const phasesResult = await getLov('Phases');
    phases = phasesResult?.values?.map(el => el.name);
    await getDeals();
  });

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<Card.Root
  class={cn('h-full overflow-hidden bg-primary-50 flex flex-col', className)}
>
  <Card.Header
    class="border-b border-solid bg-primary-800 border-b-primary-200/20"
  >
    <Card.Title class="flex items-center gap-2"
      >Current Deals
      <Badge>{count}</Badge>
    </Card.Title>
  </Card.Header>
  <Card.Content class="flex flex-col h-full gap-2 p-6 overflow-scroll">
    {#each deals ?? [] as deal}
      <div
        class="flex flex-col p-4 space-y-8 border rounded shadow-sm bg-primary-800 border-muted"
      >
        <div class="overflow-hidden">
          <span class="text-muted-foreground">{deal.refId ?? ''}</span>
          <CrmAvatar
            on:open={() => openItem(deal)}
            id={deal.customer}
            module="accounts"
            name={deal.name}
          />
        </div>

        <div class="overflow-hidden">
          <DealPhase {phases} currentPhase={deal.state} />
        </div>
        <div>
          <CrmAvatar
            id={deal.dealOwner}
            module="users"
            on:open={e => openItem(deal)}
          />
        </div>
      </div>
    {/each}
  </Card.Content>
  <Card.Footer
    class="border-t border-solid border-gray 200 bg-primary-800 shadow-sm pb-1.5 pt-1 gap-4 justify-between"
  >
    <Input
      bind:value={filterValue}
      class="w-full pl-2"
      placeholder="Filter deals"
      type="text"
    />
    <Pagination
      {count}
      from={page}
      on:next={() => (page += 10)}
      on:previous={() => (page -= 10)}
    />
  </Card.Footer>
</Card.Root>
