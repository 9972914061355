<script lang="ts">
  import type { Deal } from 'drizzle/schema';
  import Kanban from './kanban/kanban.svelte';
  import { findAssignments, getLov } from '$lib/api/queries';
  import { onMount } from 'svelte';
  import { derived, writable } from 'svelte/store';
  import { createQuery } from '@tanstack/svelte-query';

  export let deal: Deal;

  let phases = [];

  const idStore = writable(deal?.id);
  $: idStore.set(deal?.id);

  const query = createQuery(
    derived([idStore], ([$id]) => ({
      enabled: !!$id,
      queryKey: ['assignments', $id],
      queryFn: async () => {
        const result = await findAssignments({
          id: $id,
          module: 'deals',
          search: '',
          page: 1,
          itemsPerPage: 1000,
        });

        // group by status
        return result.data.reduce((acc, item) => {
          if (!acc[item.status]) {
            acc[item.status] = [];
          }
          acc[item.status].push(item);
          return acc;
        }, {});
      },
    })),
  );

  onMount(async () => {
    const phasesResult = await getLov('Phases');
    phases = phasesResult?.values?.map(el => el.name);
  });
</script>

<Kanban
  items={(phases || []).map(el => ($query.data && $query.data[el]) || [])}
  {phases}
/>
