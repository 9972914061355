<script lang="ts">
  import type { HTMLAttributes } from 'svelte/elements';
  import { cn } from '$lib/utils';

  type $$Props = HTMLAttributes<HTMLDivElement>;

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<div
  class={cn(
    ' bg-card text-card-foreground shadow rounded ',
    import.meta.env.MODE === 'plugin' ? '' : 'border-l border-r border-solid',
    className,
  )}
  {...$$restProps}
>
  <slot />
</div>
