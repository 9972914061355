<script lang="ts">
  import { appStore } from '$lib/app-store';
  import Navigation from './navigation.svelte';

  import Deals from '../pages/deals/deals.svelte';
  import Accounts from '../pages/accounts/accounts.svelte';
  import Contacts from '../pages/contacts/contacts.svelte';

  import Sheet from '../ui/sheet.svelte';
  import SecondaryNav from './secondary-nav.svelte';

  import Pipelines from '$lib/pages/pipelines/pipelines.svelte';
  import Invoices from '$lib/pages/invoices/invoices.svelte';
  import Dashboard from '$lib/pages/dashboard/dashboard.svelte';
  import { cn } from '$lib/utils';
  import Timelogs from '$lib/pages/timelogs/timelogs.svelte';

  const specialPages = ['dashboard', 'timelogs'];
</script>

<div>
  {#if $appStore.selected}
    {@const module = $appStore.selected.module}
    <Sheet
      open={$appStore.openPopup}
      {module}
      id={$appStore.selected.id}
      let:item
    >
      {#if module === 'deals'}
        <Deals deal={item} currentTab={$appStore.selected.tab} />
      {:else if module === 'accounts'}
        <Accounts crmAccount={item} />
      {:else if module === 'contacts'}
        <Contacts crmContact={item} />
      {:else if module === 'activities'}
        --
      {:else if module === 'invoices'}
        <Invoices {item} />
      {:else if module === 'pipelines'}
        <Pipelines {item} />
      {/if}
    </Sheet>
  {/if}
  {#if $appStore.currentModule === 'timelogs'}
    <div
      class={cn(
        $appStore.isMobile ? 'max-w-md' : 'w-full fixed pl-16 pt-14 h-1/2 ',
      )}
    >
      <Timelogs />
    </div>
  {:else if $appStore.currentModule === 'dashboard'}
    <div
      class={cn($appStore.isMobile ? 'max-w-md' : 'fixed pl-16 pt-14 h-1/2 ')}
    >
      <Dashboard />
    </div>
  {/if}
</div>

<div class="z-30 bg-primary-foreground dark:bg-primary-foreground">
  {#if (!!$appStore.linkedInAccount || !!$appStore.linkedInContact) && import.meta.env.MODE === 'plugin'}
    <div class="flex items-center justify-between w-full m-2">
      <div class="flex items-center justify-center space-x-2">
        <img
          class="h-6"
          src="https://uploads.t-next.io/public/eu-central-1/f992dc07-8f55-4bcd-bbac-871d8b20eb43/AUTOx2400/logo.png"
          alt="Hanta Logo"
        />
        <h5 class="text-md font-extralight">
          makes your LinkedIn better. Instantly.
        </h5>
      </div>
    </div>
  {/if}
  <Navigation />
  {#if !!$appStore.currentModule && specialPages.indexOf($appStore.currentModule) === -1}
    {#key $appStore.currentModule}
      <SecondaryNav />
    {/key}
  {/if}
</div>
