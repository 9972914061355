<script lang="ts">
  import { cn } from '$lib/utils';

  export let phases;
  export let current;
</script>

<div class="flex mb-2 space-x-0.5 justify-around">
  {#each (phases || []).slice(1) as phase, i}
    <div
      class={cn(
        'h-0.5 bg-red-400 flex-1',
        current > i - 1 ? 'bg-red-400' : 'bg-gray-400',
        current === phases.length - 2 && 'bg-green-400',
        current === phases.length - 1 && 'dark:bg-white bg-gray-400',
      )}
    >
      &nbsp;
    </div>
  {/each}
</div>
