<script lang="ts">
  import { Input } from '$lib/components/ui/input';
  import ActivitiesTabs from './activities-tabs.svelte';

  import { findActivities } from '$lib/api/queries';
  import ActivityItem from './activity-item.svelte';
  import { derived, writable } from 'svelte/store';
  import { createInfiniteQuery } from '@tanstack/svelte-query';
  import { Search } from 'lucide-svelte';
  import InfiniteLoading from 'svelte-infinite-loading';
  import { cn } from '$lib/utils';

  export let module = null;
  export let id = null;
  export let type = 'Email';
  export let search = '';
  export let withSearch = true;
  export let withTabs = true;
  let pinned = undefined;
  let scroll;

  const moduleStore = writable(module);
  const idStore = writable(id);
  const typeStore = writable(type);
  const searchStore = writable(search);

  $: moduleStore.set(module);
  $: idStore.set(id);
  $: searchStore.set(search);
  $: typeStore.set(type);

  const itemsPerPage = 25;
  const query = createInfiniteQuery(
    derived(
      [moduleStore, idStore, typeStore, searchStore],
      ([$module, $id, $type, $search]) => ({
        enabled: !!$module,
        initialPageParam: 1,
        getNextPageParam: lastPage => {
          const { page, count } = lastPage;
          if (page * itemsPerPage < count) {
            return page + 1;
          } else {
            return undefined;
          }
        },
        queryKey: ['activities', $module, $id, $type, $search],
        queryFn: async ({ pageParam }) =>
          await findActivities({
            module: $module,
            id: $id,
            type: $type,
            search: $search,
            page: pageParam,
            itemsPerPage,
          }),
      }),
    ),
  );
</script>

<div class="flex relative flex-col md:p-2">
  {#if withSearch}
    <div class="relative mt-4">
      <Search class="absolute top-2 left-2 w-4 h-4 text-muted-foreground" />
      <Input
        bind:value={search}
        class="pl-8 mb-8 w-full shrink-0"
        placeholder="Search activities"
      />
    </div>
  {/if}

  {#if withTabs}
    <ActivitiesTabs bind:type />
  {/if}
  <div>
    {#if $query.isFetching}
      <p class="">Loading...</p>
    {/if}
    {#if $query.isError}
      <p>Error: {$query.error.message}</p>
    {/if}
    {#if $query.isSuccess}
      {@const count = $query.data.pages[0].count}
      {#if count === 0}
        <p>No activities found.</p>
      {:else}
        {count} activities found.

        {#if pinned}
          TEST Pinned
        {/if}

        {#if pinned}
          Upcoming
        {/if}

        <div class="flex flex-col pb-12 space-y-6 w-full" bind:this={scroll}>
          {#each $query.data.pages || [] as { data: items, count }}
            {#each items || [] as item, idx}
              {#if idx === 0 || (items[idx - 1] && new Date(item.startDate).toLocaleDateString( 'de', { year: 'numeric', month: 'long' }, ) !== new Date(items[idx - 1].startDate).toLocaleDateString( 'de', { year: 'numeric', month: 'long' }, ))}
                <div
                  class={cn(
                    'sticky z-[1] py-2 mt-4 -top-6 bg-gradient-to-b from-primary-50 to-transparent',
                    withSearch && 'top-12',
                  )}
                >
                  <div class="relative">
                    <div class="flex absolute inset-0 items-center">
                      <span
                        class="w-full border-t border-solid border-t-primary-200/20"
                      ></span>
                    </div>
                    <div class="flex relative justify-center text-xs uppercase">
                      <span
                        class="px-2 w-32 text-center bg-accent text-muted-foreground"
                      >
                        {#if new Date(item.startDate) > new Date()}
                          Upcoming
                        {:else}
                          {new Date(item.startDate).toLocaleDateString('de', {
                            year: 'numeric',
                            month: 'long',
                          })}
                        {/if}
                      </span>
                    </div>
                  </div>
                </div>
              {/if}

              <ActivityItem {item} {id} {module} />
            {/each}
          {/each}
          <div>
            <!--InfiniteLoading
              on:infinite={async ({ detail: { loaded, complete } }) => {
                const result = await $query.fetchNextPage();
                console.log(result);
                loaded();
                if (!result.hasNextPage) {
                  complete();
                }
              }}
            /-->

            <button
              class={cn(
                'w-full h-12 ',
                $query.hasNextPage &&
                  'hover:bg-primary-800 hover:text-primary-50 border border-solid',
              )}
              on:click={() => $query.fetchNextPage()}
              disabled={!$query.hasNextPage || $query.isFetchingNextPage}
            >
              {#if $query.isFetching}
                Loading more...
              {:else if $query.hasNextPage}
                Load More
              {:else}Nothing more to load
              {/if}
            </button>
          </div>
        </div>
      {/if}
    {/if}
  </div>
</div>
