<script>
  import { onMount, onDestroy } from 'svelte';
  import { writable } from 'svelte/store';
  import * as d3 from 'd3';

  // Sample data structure based on the provided information
  const recruitmentData = writable({
    phases: [
      { name: 'In Vorbereitung', count: 96 },
      { name: 'Kontaktiert', count: 11 },
      { name: 'TI/VC in Vorbereitung', count: 0 },
      { name: 'Telefon Interview 1', count: 1 },
      { name: 'VideoCall 1', count: 2 },
      { name: 'Im Austausch', count: 6 },
      { name: 'Persönliches Interview FULL & BYE', count: 0 },
      { name: 'Präsentation in Vorbereitung', count: 0 },
      { name: 'Präsentiert', count: 1 },
      { name: 'Telefoninterview Kunde', count: 0 },
      { name: 'VideoCall Kunde', count: 0 },
      { name: 'Persönliches Interview 1 Kunde', count: 1 },
      { name: 'Persönliches Interview 2 Kunde', count: 1 },
      { name: 'Finale Interviews Kunde', count: 1 },
      { name: 'Vertragsverhandlung', count: 1 },
      { name: 'Vertrag', count: 1 },
    ],
    candidates: [
      // ... (candidate data would go here)
    ],
    rejections: [
      // ... (rejection data would go here)
    ],
  });

  let svgContainer;
  let width = 800;
  let height = 600;
  let svg;

  onMount(() => {
    svg = d3.select(svgContainer).attr('width', width).attr('height', height);

    const zoom = d3.zoom().scaleExtent([0.5, 5]).on('zoom', handleZoom);

    svg.call(zoom);

    // Initial render
    const unsubscribe = recruitmentData.subscribe(data => {
      updateFunnel(data);
      updateForceGraph(data);
      updateBarChart(data);
      updateTreemap(data);
      updateHeatmap(data);
    });

    // Cleanup
    return () => {
      unsubscribe();
    };
  });

  function updateFunnel(data) {
    const funnelData = data.phases.sort((a, b) => b.count - a.count);
    const totalCandidates = d3.sum(funnelData, d => d.count);

    const x = d3.scaleLinear().domain([0, totalCandidates]).range([0, width]);

    const y = d3
      .scalePoint()
      .domain(funnelData.map(d => d.name))
      .range([0, height])
      .padding(0.5);

    const area = d3
      .area()
      .x0(d => width / 2 - x(d.count) / 2)
      .x1(d => width / 2 + x(d.count) / 2)
      .y(d => y(d.name))
      .curve(d3.curveMonotoneY);

    svg
      .selectAll('path.funnel')
      .data([funnelData])
      .join('path')
      .attr('class', 'funnel')
      .attr('d', area)
      .attr('fill', 'steelblue')
      .attr('opacity', 0.8);

    svg
      .selectAll('text.label')
      .data(funnelData)
      .join('text')
      .attr('class', 'label')
      .attr('x', width / 2)
      .attr('y', d => y(d.name))
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .text(d => `${d.name} (${d.count})`);
  }

  function updateForceGraph(data) {
    const nodes = data.candidates.map(c => ({ id: c.name, group: c.status }));
    const links = [];
    // ... create links based on candidate relationships

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        'link',
        d3.forceLink(links).id(d => d.id),
      )
      .force('charge', d3.forceManyBody())
      .force('center', d3.forceCenter(width / 2, height / 2));

    const link = svg
      .append('g')
      .selectAll('line')
      .data(links)
      .join('line')
      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6);

    const node = svg
      .append('g')
      .selectAll('circle')
      .data(nodes)
      .join('circle')
      .attr('r', 5)
      .attr('fill', d => d3.schemeCategory10[d.group])
      .call(drag(simulation));

    node.append('title').text(d => d.id);

    simulation.on('tick', () => {
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      node.attr('cx', d => d.x).attr('cy', d => d.y);
    });
  }

  function updateBarChart(data) {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const x = d3
      .scaleBand()
      .domain(data.phases.map(d => d.name))
      .range([0, innerWidth])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data.phases, d => d.count)])
      .range([innerHeight, 0]);

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    g.selectAll('.bar')
      .data(data.phases)
      .join('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.name))
      .attr('y', d => y(d.count))
      .attr('width', x.bandwidth())
      .attr('height', d => innerHeight - y(d.count))
      .attr('fill', 'steelblue');

    g.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');

    g.append('g').call(d3.axisLeft(y));
  }

  function updateTreemap(data) {
    const root = d3
      .hierarchy({ name: 'root', children: data.phases })
      .sum(d => d.count);

    const treemap = d3.treemap().size([width, height]).padding(1).round(true);

    treemap(root);

    // Clear previous treemap
    svg.selectAll('g.treemap-cell').remove();

    const cell = svg
      .selectAll('g.treemap-cell')
      .data(root.leaves())
      .enter()
      .append('g')
      .attr('class', 'treemap-cell')
      .attr('transform', d => `translate(${d.x0},${d.y0})`);

    cell
      .append('rect')
      .attr('width', d => d.x1 - d.x0)
      .attr('height', d => d.y1 - d.y0)
      .attr('fill', 'steelblue');

    cell
      .append('text')
      .selectAll('tspan')
      .data(d => d.data.name.split(/(?=[A-Z][a-z])|\s+/g))
      .enter()
      .append('tspan')
      .attr('x', 3)
      .attr('y', (d, i) => 13 + i * 10)
      .text(d => d);

    // Add title for better accessibility
    cell.append('title').text(d => `${d.data.name}: ${d.data.count}`);
  }

  function updateHeatmap(data) {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const x = d3
      .scaleBand()
      .range([0, innerWidth])
      .domain(data.rejections.map(d => d.reason))
      .padding(0.01);

    const y = d3
      .scaleBand()
      .range([innerHeight, 0])
      .domain(data.rejections.map(d => d.phase))
      .padding(0.01);

    const color = d3
      .scaleSequential(d3.interpolateYlOrRd)
      .domain([0, d3.max(data.rejections, d => d.count)]);

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    g.selectAll('rect')
      .data(data.rejections)
      .join('rect')
      .attr('x', d => x(d.reason))
      .attr('y', d => y(d.phase))
      .attr('width', x.bandwidth())
      .attr('height', y.bandwidth())
      .style('fill', d => color(d.count));

    g.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end');

    g.append('g').call(d3.axisLeft(y));
  }

  function drag(simulation) {
    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    return d3
      .drag()
      .on('start', dragstarted)
      .on('drag', dragged)
      .on('end', dragended);
  }

  function handleSearch(event) {
    const searchTerm = event.target.value.toLowerCase();
    recruitmentData.update(data => {
      return {
        ...data,
        candidates: data.candidates.filter(
          c =>
            c.name.toLowerCase().includes(searchTerm) ||
            c.status.toLowerCase().includes(searchTerm),
        ),
      };
    });
  }

  function handleZoom(event) {
    const { transform } = event;
    svg.attr('transform', transform);
  }
</script>

<div class="recruitment-pipeline">
  <h1>Recruitment Pipeline Visualization</h1>
  <input
    type="text"
    on:input={handleSearch}
    placeholder="Search candidates..."
  />
  <svg bind:this={svgContainer}></svg>
</div>

<style>
  .recruitment-pipeline {
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
  }

  h1 {
    text-align: center;
    color: #003366; /* Sparkasse blue */
  }

  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  svg {
    border: 1px solid #ccc;
  }

  .funnel {
    transition: all 0.3s ease;
  }

  .bar {
    transition: all 0.3s ease;
  }

  .label {
    font-size: 12px;
    fill: white;
  }
</style>
