<script lang="ts">
  import { Button, buttonVariants } from '$lib/components/ui/button';
  import * as Dialog from '$lib/components/ui/dialog';
  import AccountSelector from './accounts/account-selector.svelte';
  import { createEventDispatcher } from 'svelte';
  import ContactsSelector from './contacts/contacts-selector.svelte';

  const dispatch = createEventDispatcher();

  export let item = undefined;
  export let disabled;
  export let module = 'accounts';

  const linkRecord = () => {
    dispatch('link', item);
  };
</script>

<Dialog.Root>
  <Dialog.Trigger class={buttonVariants({ variant: 'outline' })} {disabled}>
    Already in your CRM? Link Record
  </Dialog.Trigger>
  <Dialog.Content class="sm:max-w-[425px]">
    <Dialog.Header>
      <Dialog.Title>Link Record</Dialog.Title>
      <Dialog.Description>
        Link this record to an existing account in your CRM.
      </Dialog.Description>
    </Dialog.Header>
    <div>
      {#if module === 'accounts'}
        <AccountSelector bind:value={item} />
      {:else}
        <ContactsSelector bind:value={item} />
      {/if}
    </div>
    <Dialog.Footer>
      <Button type="submit" on:click={linkRecord}>Link Record</Button>
    </Dialog.Footer>
  </Dialog.Content>
</Dialog.Root>
