export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

function copyToClipboard(calback) {
  var resultString = localStorage.getItem('result');
  var result = JSON.parse(resultString);
  if (result && result.vorname && result.name) {
    calback(result);
  }
}

export function fallbackUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// Check if window.crypto.randomUUID is available
export function generateUUID() {
  if (window.crypto && typeof window.crypto.randomUUID === 'function') {
    return window.crypto.randomUUID();
  } else {
    return fallbackUUID();
  }
}

export function scrollToSmoothly(pos, time) {
  /*Time is exact amount of time the scrolling will take (in milliseconds)*/
  /*Pos is the y-position to scroll to (in pixels)*/
  /*Code written by hev1*/
  if (typeof pos !== 'number') {
    pos = parseFloat(pos);
  }
  if (isNaN(pos)) {
    console.warn('Position must be a number or a numeric String.');
    throw 'Position must be a number';
  }
  if (pos < 0 || time < 0) {
    return;
  }
  var currentPos = window.scrollY || window.screenTop;
  var start = null;
  time = time || 500;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    if (currentPos < pos) {
      var progress = currentTime - start;
      window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    } else {
      var progress = currentTime - start;
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    }
  });
}
