<script lang="ts">
  import { dndzone } from 'svelte-dnd-action';
  import { flip } from 'svelte/animate';
  export let itemsData;
  export let itemComponent;
  export let onDrop;
  export let idPropertyName = 'id';
  export let flipDurationMs = 300;

  function handleConsider(e) {
    itemsData = e.detail.items;
  }
  function handleFinalize(e) {
    onDrop(e.detail.items);
  }
</script>

<section
  class="my-2"
  use:dndzone={{ items: itemsData, flipDurationMs }}
  on:consider={handleConsider}
  on:finalize={handleFinalize}
>
  {#each itemsData as item (item[idPropertyName])}
    <div
      animate:flip={{ duration: flipDurationMs }}
      class="my-1 bg-primary-800"
    >
      <svelte:component this={itemComponent} {item} />
    </div>
  {/each}
</section>

<style>
  section {
    padding: 2em;
  }
</style>
