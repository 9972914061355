<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import { Input } from '$lib/components/ui/input';

  export let form;
  export let name;
  export let label;
  export let description = '';

  export let types = [
    'email',
    'secondaryEmail',
    'mobile',
    'phone',
    'fax',
    'web',
    'xing',
    'whatsApp',
    'skype',
    'twitter',
    'facebook',
    'values',
  ];

  const { form: formData } = form;

  const getLabel = type => {
    if (type === 'secondaryEmail') {
      return 'Secondary email';
    } else {
      return type;
    }
  };
</script>

{#if $formData[name]}
  {#each types || [] as type}
    <Form.Field class="space-y-1" {form} name={`${name}.{type}`}>
      <Form.Control let:attrs>
        <Form.Label class="capitalize">{getLabel(type)}</Form.Label>
        <Input
          {...attrs}
          bind:value={$formData[name][type]}
          class="w-full"
          variant="hanta"
        />
      </Form.Control>
      <Form.FieldErrors />
    </Form.Field>
  {/each}
{/if}
