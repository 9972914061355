import App from './lib/auth/app-with-auth.svelte';

let app;
if (import.meta.env.MODE === 'spa') {
  app = new App({
    target: document.body,
  });
}

export default app;
