<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import { fly } from 'svelte/transition';
  import { appStore } from '$lib/app-store';

  import ItemsList from './list/items-list.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { cn } from '$lib/utils';

  let countInPrimaryTab = 0;
  let countInSeondaryItemsTab = 0;
</script>

<div
  in:fly={{ x: -200, duration: 250 }}
  class={cn(
    'fixed overflow-auto bg-background',
    $appStore.isMobile
      ? 'h-full w-full'
      : 'top-[50px] left-[58px]  w-[240px] h-[calc(100svh-50px)] ',
  )}
>
  <Tabs.Root value="all" class="flex flex-col ">
    <div class="flex items-center p-2 space-x-2">
      <h1 class="ml-1 text-xl font-bold capitalize md:flex-1">
        {$appStore.currentModule}
      </h1>

      <Tabs.List class="">
        <Tabs.Trigger value="all" class="text-zinc-600 dark:text-zinc-200">
          <span>All</span>
          <Badge variant="secondary" class="ml-2" data-testid="result-count"
            >{countInPrimaryTab}</Badge
          >
        </Tabs.Trigger>
        <Tabs.Trigger
          value="unread"
          class="text-zinc-600 dark:text-zinc-200 md:hidden"
        >
          <span>New</span>
          <Badge variant="secondary" class="ml-2"
            >{countInSeondaryItemsTab}</Badge
          >
        </Tabs.Trigger>
      </Tabs.List>
    </div>

    <Tabs.Content value="all" class="-mt-2">
      <ItemsList
        bind:count={countInPrimaryTab}
        module={$appStore.currentModule}
        current={$appStore.selected}
        selected={[]}
        sort={$appStore.sort}
        filters={$appStore.filters}
        on:goto={e => appStore.goToPage(e.detail)}
        on:next={appStore.nextPage}
        on:previous={appStore.prevPage}
        on:search={e => appStore.setSearch(e.detail)}
        on:sort={e => appStore.setSort(e.detail)}
        on:filters={e => appStore.setFilters(e.detail)}
        on:current={e => {
          appStore.select(e.detail);
          appStore.openPopup(true);
        }}
      />
    </Tabs.Content>
    <Tabs.Content value="unread" class="-mt-2">TODO</Tabs.Content>
  </Tabs.Root>
</div>
