import { supabase } from './supabase';
import { SupabaseClient, createClient } from '@supabase/supabase-js';

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;
const AUTH_PROVIDER = import.meta.env.VITE_AUTH_PROVIDER;

let supabaseClientPool: Record<string, SupabaseClient> = {};

export const supabase = (accessToken: string) => {
  if (supabaseClientPool[accessToken]) {
    return supabaseClientPool[accessToken];
  }
  purgePoolOnBrowser();
  const client = createClient(
    SUPABASE_URL,
    SUPABASE_ANON_KEY,
    AUTH_PROVIDER !== 'supabase' && {
      global: { headers: { Authorization: `Bearer ${accessToken}` } },
    },
  );
  client.realtime.setAuth(accessToken);
  supabaseClientPool[accessToken] = client;
  return client;
};

const purgePoolOnBrowser = () => {
  if (typeof window !== 'undefined') {
    supabaseClientPool = {};
  }
};
