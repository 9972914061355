<script lang="ts">
  import { cn } from '$lib/utils';
  import { type Props, textareaVariants } from '.';

  type $$Props = Props;

  let className: $$Props['class'] = undefined;
  export let variant: $$Props['variant'] = 'default';
  export let value: $$Props['value'] = undefined;
  export { className as class };
</script>

<textarea
  class={cn(textareaVariants({ variant, className }))}
  bind:value
  on:blur
  on:change
  on:click
  on:focus
  on:keydown
  on:keypress
  on:keyup
  on:mouseover
  on:mouseenter
  on:mouseleave
  on:paste
  on:input
  {...$$restProps}
/>
